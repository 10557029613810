// This component is used to display the header of the application page.

// Import Dependencies
import { React, useRef, useState } from 'react';
import {TextField} from '@fluentui/react';
import { Link } from 'react-router-dom';

const AdminCenterPrompTextField = ({ task, fieldUpdateHandler }) => {


    const inputRef = useRef(null);

    const handleButtonClick = (custom_field_value) => {
        
        var inputElement = inputRef.current;
        const startPosition = inputElement.selectionStart;
        const endPosition = inputElement.selectionEnd;
        const newValue = inputElement.value.substring(0, startPosition) + '$' + custom_field_value + " " + inputElement.value.substring(endPosition);
        const event = { target: { value: newValue } };



        fieldUpdateHandler(event, "prompt");

    };



        return (
            <>
                <p className="fw-semibold">Enter your prompt instruction (<Link to="/faq" target="_blank">see FAQ for help</Link>):</p>
                <div className='d-flex align-items-center'>
                    <p className='me-2 align-items-center'>Optional fields: </p>
                    <p className='me-2 custom_field_pill align-items-center' onClick={(event) => handleButtonClick("prompt")}>{"prompt"}</p>
                    {task.custom_fields.value.map((custom_field, index) => {
                        return <p className='me-2 custom_field_pill align-items-center' onClick={(event) => handleButtonClick(custom_field.name)}>{custom_field.name}</p>
                    })}
                </div>


                <div className="col-12 mb-4">
                    <TextField ref={inputRef} multiline={true} value={task.prompt.value} onChange={(event) => fieldUpdateHandler(event, "prompt")} placeholder='In this example $language is a optional field dropdown and $prompt is the user prompt: ´Translate to $language the following text: $prompt´'/>
                </div>
            </>)
    }

export default AdminCenterPrompTextField;