import React from 'react';

const Pill = ({ checked, onChange, value }) => {

  const pillStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: '8px',
    marginLeft: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#fff',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    borderRadius: '25px',
    backgroundColor: checked ? '#007bff' : '#6c757d',
  };

  const radioStyle = {
    display: 'none',
  };

  return (
    <label style={pillStyle} role='pill'>
      <input
        style={radioStyle}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        name={value}
      />
      {value}
    </label>
  );
};

export default Pill;
