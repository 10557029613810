// This component is used to display a diaglog box with a close button.

// Import Dependencies
import {React} from 'react';
import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

const CloseButtonDialog = (props) => {
    var dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Important',
        closeButtonAriaLabel: 'Close',
        subText: props.dialogText,
    };

    return (
        <Dialog
            hidden={props.dialogHidden}
            dialogContentProps={dialogContentProps}
            onDismiss={props.hideDialog}
        >
            <DialogFooter>
                <PrimaryButton onClick={props.hideDialog} text="Close" />
            </DialogFooter>
        </Dialog>
    )

};

export default CloseButtonDialog;