// Import Dependencies
import { React, useContext, useEffect, useState } from "react";
import parser from "html-react-parser";
import NetworkGraph from "./NetworkGraph";
import { postRunTask } from "../../Api/Api";
import { convertFilesToBase64 } from "../../Utils/Utils";
import { LoadingContext } from "../../Utils/LoadingManager";

const GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE = {
  APIRESULTS: null,
  APIPARAMETERS: null,
  FETCHDATA: false,
  FIELDSTATE: null,
  INITCOMPONENT: false,
  RESETREQUEST: false,
};

const GptKeywordExtractionOutput = (props) => {
  const { setLoadingResponseState } = useContext(LoadingContext);

  const [apiResults, setApiResults] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.APIRESULTS
  );
  const [apiParameters, setApiParameters] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.APIPARAMETERS
  );
  const [fetchData, setFetchData] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.FETCHDATA
  );
  const [fieldState, setFieldState] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.FIELDSTATE
  );
  const [initComponent, setInitComponent] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.INITCOMPONENT
  );
  const [resetRequest, setResetRequest] = useState(
    GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.RESETREQUEST
  );

  function formatText(text) {
    // Entities
    const namedEntitiesRegex = /\[([^\]]+)\]/g;
    const namedEntitiesMatch = text.match(namedEntitiesRegex);
    const namedEntities = namedEntitiesMatch
      ? namedEntitiesMatch[0]
          .slice(1, -1)
          .split(";")
          .map((entity) => entity.trim())
          .map((entity) => {
            const entityRegex = /(.+) \((.+)\)/;
            const match = entityRegex.exec(entity);
            const name = match ? match[1] : undefined;
            const type = match ? match[2] : undefined;
            if (name && type) return `${name} (${type})`;
            else return [];
          })
          .flat()
      : [];

    // Relations
    var relationsRegex = /\[([^|\]]+)\s*\|\s*([^|\]]+)\s*\|\s*([^|\]]+)\]/g;
    var match;

    var output = "<ul>";
    while ((match = relationsRegex.exec(text)) !== null) {
      output +=
        "<li>" +
        match[1].trim() +
        " > " +
        match[2].trim() +
        " > " +
        match[3].trim() +
        "</li>";
    }
    output += "</ul>";

    return (
      "<h4>Named entities:</h4>" +
      namedEntities.join(", ") +
      ".<br/><br/><h4>Relations:</h4>" +
      output
    );
  }

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);
      setApiResults(GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.APIRESULTS);
      setFieldState(props.fieldStateList);
      setResetRequest(GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.RESETREQUEST);
    }
  }, [initComponent, props, resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (resetRequest) {
      setResetRequest(GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.RESETREQUEST);
      setFetchData(GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.FETCHDATA);
      setApiParameters(
        GPT_KEYWORD_EXTRACTION_OUTPUT_DEFAULT_STATE.APIPARAMETERS
      );
      setLoadingResponseState("GPTOutput", false);
    }
  }, [resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (fieldState) {
      const _apiParameters = {};
      let fileFields = [];
      let errors = [];
      Object.values(fieldState).forEach((value) => {
        const field = value;
        const fieldType = field?.props?.type;
        const userSelectedValue = field?.data?.userSelectedValue;
        if (fieldType === "file") {
          if (userSelectedValue) {
            fileFields.push({
              props: field.props,
              files:
                userSelectedValue instanceof FileList
                  ? Array.from(userSelectedValue)
                  : Array.isArray(userSelectedValue)
                  ? userSelectedValue
                  : [userSelectedValue],
            });
          }
          const fileCount =
            fileFields.find((file) => (file.props.name = field.props.name))
              ?.files?.length || 0;
          const multifileDisallowed =
            field.props.enable_multifile !== "true" && fileCount > 1;
          const filesMissing =
            field.props.required === "yes" && fileCount === 0;
          if (multifileDisallowed || filesMissing) {
            errors.push(field.props.name);
          }
        } else if (userSelectedValue) {
          _apiParameters[field.props.name] = userSelectedValue;
        } else {
          _apiParameters[field.props.name] = "";
          if (field.props.required === "yes") {
            errors.push(field.props.name);
          }
        }
      });
      if (props.leftPanelInputValue) {
        _apiParameters.prompt = props.leftPanelInputValue;
      } else if (fileFields.length === 0) {
        errors.push("prompt");
      }

      if (errors.length > 0) {
        props.setErrors(errors);
        props.showDialog("Please fill all the required fields.");
        setInitComponent(false);
      } else {
        if (fileFields.length > 0) {
          Promise.all(
            fileFields.map(async (field) => {
              const files = field.files;
              return await convertFilesToBase64(files);
            })
          ).then((convertedFiles) => {
            fileFields.forEach(async (field, i) => {
              const props = field.props;
              if (props.enable_multifile === "true") {
                _apiParameters.iterator = {
                  [props.name]: convertedFiles[i],
                };
              } else {
                _apiParameters[props.name] = convertedFiles[i];
              }
            });
            setApiParameters(_apiParameters);
            setFetchData(true);
          });
        } else {
          setApiParameters(_apiParameters);
          setFetchData(true);
        }
      }
    }
  }, [fieldState, props]);

  useEffect(() => {
    const buildResponse = (data) => {
      if (data instanceof Object) {
        const decision_text = String(data.generated_text);
        if (data.generated_text) {
          return {
            text: formatText(decision_text),
            chart_list: data.chart_list,
          };
        }
      }
      return null;
    };

    if (fetchData) {
      setFetchData(false);
      // Create the body of the API call
      const apiCallBody = {
        task: props.application.task,
        parameters: apiParameters,
      };
      setLoadingResponseState("GPTOutput", true);

      // Call the API
      try {
        postRunTask(props.application.endpoint, apiCallBody)
          .then((response) => {
            const data = response.data ?? [response];
            const responses = data.flatMap((d) => buildResponse(d) ?? []);
            if (responses.length > 0) {
              setApiResults(responses);
              props.setHasResult(true);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            } else {
              props.showDialog(
                "Your request did not return any results. Please try again."
              );
            }
          })
          .catch((error) => {
            props.showDialog(
              "A problem has come up. We will investigate it. Please try again in a few minutes"
            );
          })
          .finally(() => {
            setResetRequest(true);
          });
      } catch (error) {
        props.showDialog(
          "A problem has come up. We will investigate it. Please try again in a few minutes"
        );
        setInitComponent(false);
      }
    }
  }, [apiParameters, fetchData, props, setLoadingResponseState]);

  // Component Render
  return (apiResults ?? []).length > 0 ? (
    <>
      {/* Output */}
      <>
        <div className="row m-0 p-0 d-flex p-2 ps-0 pt-0">
          <div className="p-0">
            {apiResults.map((element, index) => {
              return (
                <div key={index}>
                  <p className="mb-5" key={index}>
                    {parser(element.text)}
                  </p>
                  {element.chart_list ? (
                    <NetworkGraph chart_list={element.chart_list} />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </>
    </>
  ) : null;
};

export default GptKeywordExtractionOutput;
