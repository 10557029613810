// Import Dependencies
import { React } from 'react';
import Divider from "../../Components/Divider";
import GptTasks from "../../Pages/GptTasks";

const AppMakerSummary = (props) => {

    const task = props.task;

    return (
        <>
            <div className="row mt-4 mb-3">
                <div className="col-12">
                    <h5>App Preview</h5>
                </div>
                <div className="col-12 mt-2 mb-2">
                    <Divider />
                </div>

                <div className="col-12 mt-5 mb-5">
                    <GptTasks fileMaxSize={1024} loadingState={props.loadingState} setLoadingState={props.setLoadingState} key={"App Preview"} application={props.previewTask} api_url={props.api_url} />
                </div>

                <div className="col-12 mt-2 mb-2">
                    <Divider />
                </div>

            </div>
            <div className="row m-1 bg-white p-4">
                <div className='col-12 col-md-6 mb-4'>
                    <div className="col-12">
                        <h5>App Type and Category</h5>
                    </div>
                    <div className="col-12 mt-2 mb-2 ">
                        <Divider />
                    </div>

                    <div className="col-12">
                        <p className="p-0 m-0"><b>Task Identifier: </b> {task.task.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Task Type: </b> {task.taskType.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Category: </b> {task.category.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <div className="col-12">
                        <h5>Card Information</h5>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Image: </b> {task.image.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Card Title: </b> {task.taskTitle.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Card Description: </b> {task.cardText.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <div className="col-12">
                        <h5>Task Information</h5>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Task Description: </b> {task.taskDescription.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Task Placeholder: </b> {task.example_input_text.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Optional Fields count: </b> {task.custom_fields.value.length}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                </div>

                <div className='col-12 col-md-6'>
                    <div className="col-12">
                        <h5>Model Information</h5>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Max tokens for input: </b> {task.max_prompt.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Max tokens for output: </b> {task.max_response_tokens.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>
                    <div className="col-12">
                        <p className="p-0 m-0"><b>Prompt: </b> {task.prompt.value}</p>
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Divider />
                    </div>

                    <div className="col-12">
                        <p><b>Model: </b> {task.model.value}</p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AppMakerSummary;