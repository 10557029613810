// Import Dependencies
import { React, useRef, useState } from "react";
import { TextField, DefaultButton, ActionButton, IconButton } from '@fluentui/react';

const GptTasks = (props) => {
    const inputTextRef = useRef(null);
    const [exampleInputTextVisibility, setExampleInputTextVisibility] = useState(true);


    function inputSetFocus(ref, boton) {
        ref.current.focus();
    }


    // Component Render
    return (
        <>

            { /* Result Area */}
            <div className="row d-flex flex-grow-1 m-0 p-0">

                { /* Left Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3">
                    <div className="col-12 d-flex flex-grow-1 bg-white p-0 pb-3 mobileMinHeight">
                        {exampleInputTextVisibility && (<IconButton iconProps={{ iconName: 'Edit' }} className='me-1' onClick={() => (inputSetFocus(inputTextRef), setExampleInputTextVisibility(false))} />)}
                        <TextField onBlur={() => (setExampleInputTextVisibility(true))} onFocus={() => (setExampleInputTextVisibility(false))}  ref={inputTextRef} placeholder="<Add text here that tells the user what do to. Example: ''Paste your text here.''>" value={props.task.example_input_text.value} onChange={(event) => props.fieldUpdateHandler(event, "example_input_text")} readOnly={props.loadingState !== ''} className="p-0 transparent flex-grow-1" multiline borderless={true} />
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
                        <DefaultButton disabled={true} text="Submit" ></DefaultButton>
                    </div>
                </div>

                { /* Right Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                    <div className="col-12 flex-grow-1 bgCustomGray p-0 pb-3 mobileMinHeight">
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight">
                        <ActionButton disabled={true} iconProps={{ iconName: 'refresh' }} text="Start over" />
                        <ActionButton disabled={true} iconProps={{ iconName: 'copy' }} text="Copy Text" />
                    </div>
                </div>
            </div>

        </>
    )
};

export default GptTasks;