// Import Dependencies
import { React, useEffect, useState, useContext } from "react";
import { postRunTask } from "../../Api/Api";
import { convertFilesToBase64 } from "../../Utils/Utils";
import { LoadingContext } from "../../Utils/LoadingManager";
import { Shimmer } from "@fluentui/react";

const IHME_OUTPUT_DEFAULT_STATE = {
  APIRESULTS: null,
  APIPARAMETERS: null,
  FETCHDATA: false,
  FIELDDATA: null,
  INITCOMPONENT: false,
  RESETREQUEST: false,
  STATUSRESULTS: null,
};

const IHMEDefaultOutput = (props) => {
  const columnsToSkip = 5;
  const { loadingResponse, setLoadingResponseState } =
    useContext(LoadingContext);
  // API Call
  const [apiResults, setApiResults] = useState(
    IHME_OUTPUT_DEFAULT_STATE.APIRESULTS
  );
  const [apiParameters, setApiParameters] = useState(
    IHME_OUTPUT_DEFAULT_STATE.APIPARAMETERS
  );
  const [fetchData, setFetchData] = useState(
    IHME_OUTPUT_DEFAULT_STATE.FETCHDATA
  );
  const [fieldData, setFieldData] = useState(
    IHME_OUTPUT_DEFAULT_STATE.FIELDDATA
  );
  const [initComponent, setInitComponent] = useState(
    IHME_OUTPUT_DEFAULT_STATE.INITCOMPONENT
  );
  const [resetRequest, setResetRequest] = useState(
    IHME_OUTPUT_DEFAULT_STATE.RESETREQUEST
  );

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);
      setApiResults(IHME_OUTPUT_DEFAULT_STATE.APIRESULTS);
      setFieldData(props.fieldData);
      setResetRequest(IHME_OUTPUT_DEFAULT_STATE.RESETREQUEST);
    }
  }, [initComponent, props, resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (resetRequest) {
      setResetRequest(IHME_OUTPUT_DEFAULT_STATE.RESETREQUEST);
      setFetchData(IHME_OUTPUT_DEFAULT_STATE.FETCHDATA);
      setApiParameters(IHME_OUTPUT_DEFAULT_STATE.APIPARAMETERS);
      setLoadingResponseState("IHMEOutput", false);
    }
  }, [resetRequest, setLoadingResponseState]);

  // IF CUSTOMFIELTYPE IS FILE NOT ADD TO APIPARAMETERS
  useEffect(() => {
    if (fieldData) {
      const _apiParameters = {};
      let fileArray = [];
      let errors = [];
      Object.values(fieldData).forEach((value) => {
        const field = value;
        if (
          !(
            field?.data &&
            (field.data?.userSelectedValue || field.data.userSelectedData)
          )
        ) {
          _apiParameters[field.props.name] = "";
          if (field.props.required === "yes") {
            errors.push(field.props.name);
          }
        } else if (field.props.type === "file") {
          if (field.data.userSelectedValue instanceof FileList) {
            fileArray = Array.from(field.data.userSelectedValue);
          } else {
            fileArray.push(field.data.userSelectedValue);
          }
        } else {
          _apiParameters[field.props.name] =
            field.data.userSelectedValue || field.data.userSelectedData;
        }
      });
      if (errors.length > 0) {
        props.setErrors(errors);
        props.showDialog("Please fill all the required fields.");
        setInitComponent(false);
      } else {
        _apiParameters["sync"] = props.isBatch !== true;

        if (props.leftPanelInputValue)
          _apiParameters.prompt = props.leftPanelInputValue;

        if (fileArray.length > 0) {
          convertFilesToBase64(fileArray).then((convertedFiles) => {
            const iterator = {
              files: convertedFiles,
            };
            _apiParameters.iterator = iterator;
            setApiParameters(_apiParameters);
            setFetchData(true);
          });
        } else {
          setApiParameters(_apiParameters);
          setFetchData(true);
        }
      }
    }
  }, [fieldData, props]);

  const quotedCSVToArray = (csv) => {
    const regex = /(?:^|,)(?:"([^"]*(?:""[^"]*)*)"|([^",]*))/g;
    return csv.match(regex).map((match) => {
      return match
        .replace(/,/g, "")
        .replace(/""/g, '"')
        .replace(/^"/, "")
        .replace(/"$/, "");
    });
  };

  useEffect(() => {
    const generateHash = async (str) =>
      Array.from(
        new Uint8Array(
          await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str))
        )
      )
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

    if (fetchData) {
      setFetchData(false);
      // Create the body of the API call
      const apiCallBody = {
        task: props.application.task.replace("_batch", ""),
        parameters: apiParameters,
      };
      setLoadingResponseState("IHMEOutput", true);
      // Call the API
      try {
        postRunTask(props.application.endpoint, apiCallBody)
          .then(async (response) => {
            if (props.isBatch) {
              props.setStatusResults(false);
            } else {
              let responses = [];
              for (let i = 0; i < response.data.length; i++) {
                const data = response.data[i];

                // Split the CSV string into an array of rows
                const rows = data.split(/\r?\n/).filter((row) => {
                  return row.trim() !== "";
                });

                const header = quotedCSVToArray(rows[0]);
                const body = [];
                for (let i = 1; i < rows.length; i++) {
                  const csvList = quotedCSVToArray(rows[i]);
                  if (csvList.length > 0) {
                    body.push(csvList);
                  }
                }
                // responses = [header, ...body]
                responses.push({ header: header, body: body });
              }

              const groups = {};

              for (const item of responses) {
                const header = item.header.join(",");
                const headerHash = await generateHash(header.toLowerCase());
                if (!groups[headerHash]) {
                  groups[headerHash] = { header: item.header, body: [] };
                }
                groups[headerHash].body.push(...item.body);
              }

              const results = Object.values(groups);
              setApiResults(results);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          })
          .catch((error) => {
            props.showDialog(
              "A problem has come up. We will investigate it. Please try again in a few minutes"
            );
          })
          .finally(() => {
            setResetRequest(true);
          });
      } catch (error) {
        props.showDialog(
          "A problem has come up. We will investigate it. Please try again in a few minutes"
        );
        setInitComponent(false);
      }
    }
  }, [apiParameters, fetchData, props, setLoadingResponseState]);

  // Component Render
  return loadingResponse ? (
    <div className="ctable ctable-striped">
      <div className="cthead-dark">
        <div className="ctr">
          <div className="cth">
            <Shimmer />
          </div>
          <div className="cth">
            <Shimmer />
          </div>
        </div>
      </div>
      <div className="ctbody">
        {Array.from(Array(6).keys()).map((element, index) => {
          return (
            <div className="ctr" key={`${element}${index}`}>
              <div className="ctd">
                <Shimmer />
              </div>
              <div className="ctd">
                <Shimmer />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    (apiResults && apiResults.length > 0 && (
      <>
        {apiResults.map((result, i) => (
          <div className="ctable ctable-striped mb-4 w-100" key={`table${i}`}>
            <div className="cthead-dark">
              <div className="ctr">
                {result["header"].map(
                  (header, j) =>
                    j >= columnsToSkip && (
                      <div className="cth" key={`header${j}`}>
                        {header}
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="ctbody">
              {result["body"].map((row, j) => (
                <div className="ctr" key={`row${j}`}>
                  {row.map(
                    (cell, k) =>
                      k >= columnsToSkip && (
                        <div className="ctd" key={`cell${k}`}>
                          {cell}
                        </div>
                      )
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    )) ||
      (props.isBatch && props.taskResponse)
  );
};

export default IHMEDefaultOutput;
