// This component is used to display the header of the application page.

// Import Dependencies
import { React, useEffect, useState } from 'react';
import parser from 'html-react-parser';

const ApplicationHeader = ({ application }) => {

    const [isPreview, setIsPreview] = useState(false);

    useEffect(() => {
       if(application.previewText !== '' && application.previewText !== null && application.previewText !== undefined) {
           setIsPreview(true);
       }
    }
    , [application]);

    return (
        <div className="row mt-0 p-0 d-flex">
        <div className="col-12 d-flex">
            <img src={`../assets/images/v3/${application.image}`} alt="Task Icon"
                className="taskImage d-none d-sm-block" />
                <div className="d-flex flex-column">
                    <h1 className="d-flex taskTitle ms-0 ms-sm-3 mt-0 mb-0 text-start">{application.taskTitle}{isPreview && <sup style={{fontSize: '14px', marginLeft: '8px'}}>({application.previewText})</sup>} </h1>
                    <p className="ms-0 mt-0  ms-sm-3  align-items-center">{parser(application.taskDescription)}</p>
                </div>
        </div>

        <div className="col-12 d-flex">
            <div className="d-flex flex-column">
                <p>{application.taskInstruction}</p>
            </div>
        </div>
    </div>
    )

};

export default ApplicationHeader;