import { Link, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { FontIcon, Shimmer } from "@fluentui/react";


const Sidebar = ({ current_dict, sidebarState }) => {
  const location = useLocation();
  const [selectedItem, setSelectedDiv] = useState(null);

  const shimmerData = ({
    "": Array.from({ length: 4 }, () => ({
      image: "",
      task: "",
      cardTitle: "",
      cardText: "",
      enabled: "true",
      showInMenu: "true",
    })),
    " ": Array.from({ length: 4 }, () => ({
      image: "",
      task: "",
      cardTitle: "",
      cardText: "",
      enabled: "true",
      showInMenu: "true",
    })),
  });

  const shimmerKeys = Object.keys(shimmerData);
  const [appData, setAppData] = useState(null);
  const [appKeys, setappKeys] = useState(null);

  useEffect(() => {

    if (current_dict && typeof current_dict === "object" && Object.keys(current_dict).length > 0) {
      const new_dict = filterEnabledTasks(current_dict);
      setAppData(new_dict);
      setappKeys(Object.keys(new_dict));
    }

  }, [current_dict]);


  function filterEnabledTasks(data) {
    const filteredData = {};
    Object.keys(data).forEach(appKey => {
      var oneKey = data[appKey].filter(task => task.enabled === "true");
      oneKey = oneKey.filter(task => task.showInMenu !== "false");
      if (oneKey.length > 0) {
        filteredData[appKey] = oneKey;
      }
    });

    return filteredData;
  }


  // To highlight the current page on refresh
  useEffect(() => {
    var currentPath =
      window.location.pathname.split("/").pop().replace(/-/g, "_") || "home";
    setSelectedDiv(currentPath);
  }, [location]);

  return (
    <>
      <div className={`sidebar ${sidebarState} pb-3`} id="sidebar">
        <ul className="nav nav-sidebar">
          <li>
            <Link
              key={"home"}
              to="/"
              className={`sidebarHomeTitle ${selectedItem === "home" ? "active" : ""
                }`}
            >
              <FontIcon iconName="Home" className="pe-2" />
              Home
            </Link>
          </li>
        </ul>

        {appData !== null && appKeys !== null ? (
          appKeys.map((key, i) => (
            <div key={`sidebarK_${i}`}>
              <div className="sidebarDivider"></div>
              <ul className="nav nav-sidebar">
                <li>
                  <div className="sidebarCategoryTitle">{key}</div>
                </li>
                {appData[key].map((card, j) => (
                    <li key={`sidebar_cardK${j}`}>
                      <Link
                        to={card.task.replace(/_/g, "-")}
                        className={`sidebarCategoryItem ${selectedItem === card.task ? "active" : ""
                          }`}
                      >
                        {card.cardTitle}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))
        ) : (
          shimmerKeys.map((key, i) => (
            <div key={`sidebar_${i}`}>
              <div className="sidebarDivider"></div>
              <ul className="nav nav-sidebar">
                <div className="d-flex sidebarCategoryTitle">
                  <Shimmer className="w-50"
                    styles={
                      {
                        shimmerGradient: {
                          background:
                            "linear-gradient(to right, rgb(213, 212, 206) 0%, rgb(205, 203, 196) 50%, rgb(223, 222, 216) 100%) 0px 0px / 90% 100% no-repeat rgb(213, 212, 206)",
                        },
                      }
                    } />
                </div>
                {shimmerData[key].map((item, i) => (
                  <li key={`sidebar_card` + i}>
                    <div className="d-flex sidebarCategoryItem">
                      <Shimmer className="w-100" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        )}

      </div>
    </>
  );
};
export default Sidebar;
