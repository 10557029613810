import React, { useState, createContext, useEffect } from "react";

export const LoadingContext = createContext();

export const LoadingManager = ({ children, props }) => {
  const [loading, setLoading] = useState(props?.loading ?? false);
  const [loadingResponse, setLoadingResponse] = useState(
    props?.loadingResponse ?? false
  );
  const [componentLoading, setComponentLoading] = useState(props?.componentLoading ?? {});
  const [componentLoadingResponse, setComponentLoadingResponse] = useState(props?.componentLoadingResponse ?? {});

  const clearComponentsLoading = () => {
    setComponentLoading({});
    setComponentLoadingResponse({});
  };

  const clearLoading = () => {
    setLoading(false);
    setLoadingResponse(false);
  };

  const clearAll = () => {
    clearComponentsLoading();
    clearLoading();
  };

  const isComponentLoading = (componentName) => {
    return componentLoading[componentName] ? true : false;
  };

  const setLoadingState = (componentName, isLoading) => {
    if (componentLoading[componentName] !== isLoading) {
      setComponentLoading((prevLoadings) => {
        const currentLoadings = { ...prevLoadings, [componentName]: isLoading };
        return currentLoadings;
      });
    }
  };

  const setLoadingResponseState = (componentName, isLoading) => {
    setComponentLoadingResponse((prevLoadings) => {
      const currentLoadings = {
        ...prevLoadings,
        [componentName]: isLoading,
      };
      return currentLoadings;
    });
  };

  useEffect(() => {
    const _loading = () => {
      return Object.values(componentLoading).some((loading) => loading);
    };

    const _setLoading = () => {
      const currentLoading = _loading();
      if (loading !== currentLoading) setLoading(currentLoading);
    };

    _setLoading();
  }, [componentLoading, loading]);

  useEffect(() => {
    const _loadingResponse = () => {
      return Object.values(componentLoadingResponse).some((loading) => loading);
    };

    const _setLoadingResponse = () => {
      const currentLoadingResponse = _loadingResponse();
      if (loadingResponse !== currentLoadingResponse)
        setLoadingResponse(currentLoadingResponse);
    };

    _setLoadingResponse();
  }, [componentLoadingResponse, loadingResponse]);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        loadingResponse,
        setLoadingState,
        setLoadingResponseState,
        clearLoading,
        clearComponentsLoading,
        clearAll,
        componentLoading,
        isComponentLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
