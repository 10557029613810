import { Checkbox, FontSizes, FontWeights, Shimmer } from "@fluentui/react";
import { useEffect, useState } from "react";
import { postRunTask } from "../../Api/Api";
import { useQuery } from "@tanstack/react-query";


const RoleSelector = ({ allowed_roles, taskTitle, fieldUpdateHandler, loadingState }) => {
    const [roles, setRoles] = useState(allowed_roles || []);

    useEffect(() => {
        if (fieldUpdateHandler)
            fieldUpdateHandler(roles, 'allowed_roles');
    }, [roles]);

    const apiCallBody = {
        task: "roles_blob_reader_processor",
        parameters: {},
    };

    const getRoles = async () => {
        const response = await postRunTask('run_task', apiCallBody);
        if (response.data) return response.data;
        throw new Error("Error getting roles");
    };

    const { data: roleList, isSuccess: roleListSuccess } = useQuery(['appRoles'], async () => getRoles())

    function addRemoveRole(role) {
        if (roles.includes(role)) {
            setRoles(roles.filter(item => item !== role));
        } else {
            setRoles([...roles, role]);
        }
    }

    return (
        <>
            <h4 className='mb-1 '>Set which user groups can access {taskTitle ? <em>{taskTitle}</em> : "the app"}</h4>
            <p className='mb-2 mt-0'>App will be public when no role is selected.</p>
            <br />
            {roleListSuccess ? roleList.map((role, index) => (
                <Checkbox
                    key={"role-" + index}
                    label={role.text}
                    checked={roles.includes(role.key)}
                    onChange={() => { addRemoveRole(role.key) }}
                    styles={{
                        root: {
                            backgroundColor: roles.includes(role.key) ?
                                (loadingState === 'loading' ? 'gray' :
                                    'rgb(0, 120, 212)') : '#fbfbfb',
                            pointerEvents: loadingState === 'loading' ? 'none' : 'auto',
                            marginTop: "0!important",
                            ":hover": {
                                backgroundColor: roles.includes(role.key) ? 'rgb(0, 90, 158)' : '#f4f4f4',
                            }
                        },
                        label: {
                            width: '100%',
                            alignItems: 'center',
                            padding: 15,
                        },
                        text: {
                            fontSize: FontSizes.mediumPlus,
                            fontWeight: roles.includes(role.key) ? FontWeights.semibold : FontWeights.light,
                            fontFamily: 'Aptos, Helvetica, Arial, sans-serif',
                        },
                        checkbox: {
                            display: 'none'
                        }
                    }}
                />
            )) :
                <>
                    {[...Array(8)].map((_, index) => (
                        <Shimmer key={"role-" + index} />
                    ))}
                </>
            }
        </>
    );
}

export default RoleSelector;