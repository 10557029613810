import { React, useRef, useEffect, useState } from 'react';
import { Modal, IconButton, Label, DefaultButton } from '@fluentui/react';
import { validateImage } from '../assets/utils/imageHelpers';

const SingleCardCreator = ({ task, fieldUpdateHandler, loadingState }) => {

    const [cardTitleVisibility, setCardTitleVisibility] = useState(true);
    const [cardTextVisibility, setCardTextVisibility] = useState(true);

    const defaultFieldLabelOrFileName = 'Upload an icon (Transparent PNG file 192 x 192px)';
    const imagesPath = "../assets/images/v3/";
    const cardTitleRef = useRef(null);
    const cardTextRef = useRef(null);
    const inputFileRef = useRef(null);
    const [iconList, setIconList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [newIcon, setNewIcon] = useState(null);


    const [fieldLabelOrFileName, setFieldLabelOrFileName] = useState(defaultFieldLabelOrFileName);
    const [browseButtonText, setBrowseButtonText] = useState('Browse');


    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }


    const handeFileSlection = () => {
        if (task.imageType.value === 'new') {
            
            setFieldLabelOrFileName(defaultFieldLabelOrFileName);
            setBrowseButtonText('Browse');
            fieldUpdateHandler([undefined, "existing"], 'image');
            setNewIcon(null);
            inputFileRef.current.value = '';
        } else {
            inputFileRef.current.click();
        }
    };

    function selectIcon(type, iconValue) {
        if (type === 'new') {
            const file = iconValue.target.files[0];

            validateImage(file, 'image/png', 192, 192)
                .then((isValid) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setNewIcon(reader.result);
                    };
                    reader.readAsDataURL(file);
                    fieldUpdateHandler([file, type], 'image');
                    setBrowseButtonText('Clear');
                    setFieldLabelOrFileName(file.name);
                    toggleModal();
                })
                .catch((error) => {
                    alert(error);
                });
        } else {
            setNewIcon(null);
            fieldUpdateHandler([iconValue, type], 'image');
            toggleModal();
            setBrowseButtonText('Browse');
            setFieldLabelOrFileName(defaultFieldLabelOrFileName);
        }
    }

    const adjustTextareaHeight = (refTextarea) => {
        const textarea = refTextarea.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        adjustTextareaHeight(cardTitleRef);
        adjustTextareaHeight(cardTextRef);
    }, [task.taskTitle, task.cardText]);


    useEffect(() => {
        const context = require.context('../assets/images/v3/', false, /\.(png|jpe?g|svg)$/);
        const icons = context.keys().map((route) => {
            const iconName = route.split('/').pop();
            return { key: iconName, text: iconName };
        });

        setIconList(icons);
    }, []);
    
    function inputSetFocus(ref, boton) {
        ref.current.focus();
    }


    return (
        <>
            <div className="col-12" style={{pointerEvents: (loadingState === "loading" ? 'none' : 'auto')}}>
                <div className="card d-flex flex-row m-0 p-0 text-decoration-none">
                    <div className="d-flex me-2">
                    <IconButton data-testid='selected-icon' iconProps={{ iconName: 'Edit' }} className='ms-2 me-0 mt-3' onClick={toggleModal}/>
                    <img src={newIcon != null ? newIcon : imagesPath + task.image.value} className="align-self-center cardImage" alt={task.taskTitle.value} onClick={toggleModal} />
                    </div>
                    <div className="m-0 p-3 pb-3 ps-0" style={{ width: '100%' }} >
                        <div className='d-flex'>
                        {cardTitleVisibility && (<IconButton iconProps={{ iconName: 'Edit' }} className='me-1' onClick={() => (inputSetFocus(cardTitleRef), setCardTitleVisibility(false))}/>)}
                            <textarea disabled={loadingState === "loading"}  ref={cardTitleRef} rows={1} type="text" onKeyUp={(event) => adjustTextareaHeight(cardTitleRef)} className="cardTitle ps-0" onBlur={() => (setCardTitleVisibility(true))} onFocus={() => (setCardTitleVisibility(false))} onChange={(event) => fieldUpdateHandler(event, "taskTitle")} placeholder='Card Title (maximum 100 characters)' maxLength={100} value={task.taskTitle.value}></textarea>
                        </div>

                        <div className='d-flex'>
                        {cardTextVisibility && (<IconButton iconProps={{ iconName: 'Edit' }} className='me-1' onClick={() => (inputSetFocus(cardTextRef), setCardTextVisibility(false))}/>)}
                            <textarea ref={cardTextRef} rows={1} type="text" onKeyUp={(event) => adjustTextareaHeight(cardTextRef)} className="cardText ps-0" onBlur={() => (setCardTextVisibility(true))} onFocus={() => (setCardTextVisibility(false))}  onChange={(event) => fieldUpdateHandler(event, "cardText")} placeholder='Card Text  (maximum 200 characters)' maxLength={50}  value={task.cardText.value}></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                key={"Icons"}
                titleAriaId={"Icons"}
                isOpen={isModalOpen}
                onDismiss={toggleModal}
                isBlocking={false}
                className='p-5'
            >
                <div className="row m-0 p-0" style={{ maxWidth: '800px' }}>
                    <div className='col-12 p-3 p-md-5 d-flex '>
                        <div className="col-12 p-0 d-block ">
                            <div className='col-12 mb-3 text-center text-md-start'>
                                <h4>Select a pre-existing Icon for your app</h4>
                            </div>
                            <div className='col-12 text-center text-md-start'>
                                {iconList.map((image, index) => (
                                    <img key={index} src={imagesPath + image.text} alt={image.text} className='cardImage' onClick={(event) => selectIcon("existing", image.text)} />
                                ))}
                            </div>
                            <div className="col-12 mt-3 mb-3">
                                <div className="taskDivider"> </div>
                            </div>

                            <div className='col-12 mb-3 text-center text-md-start'>
                                <h4>Or upload your own</h4>
                            </div>
                            <div className='col-12 text-center text-md-start d-flex'>
                                <>
                                    <Label className='pe-3 text-size'>{fieldLabelOrFileName}</Label>
                                    <DefaultButton
                                        onClick={handeFileSlection}
                                        aria-label={"Open File Browser"}
                                        text={browseButtonText}
                                    />

                                    <input
                                        ref={inputFileRef}
                                        type="file"
                                        onChange={(event) => selectIcon("new", event)}
                                        accept={".png"}
                                        className="d-none"
                                        data-testid="new-icon-file-upload"
                                    />
                                </>
                            </div>
                        </div>
                        <div className='d-none d-md-block'>

                            <IconButton key={"CloseModal"}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close popup modal"
                                onClick={toggleModal}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )

};

export default SingleCardCreator;