// Import Dependencies
import { React, useState, useEffect } from "react";
import { createSlug } from "../../assets/utils/textHelpers";
import { Pivot, PivotItem, ComboBox, DefaultButton, Stack, Label, IconButton, Shimmer } from '@fluentui/react';

import { postRunTask, fetchAppConfig } from '../../Api/Api'

// Import Components
import ApplicationHeader from "../../Components/ApplicationHeader";
import AdminCenterFormTextField from "../../Components/AdminCenter/AdminCenterFormTextField";
import AdminCenterPromptTextField from "../../Components/AdminCenter/AdminCenterPromptTextField";
import AppMakerSummary from "../../Components/AdminCenter/AppMakerSummary";
import GptTaskBodyCreator from "../../Components/GptTaskBodyCreator";
import AdminCenterFormLine from "../../Components/AdminCenter/AdminCenterFormLine";
import Divider from "../../Components/Divider";
import TypeSelector from "../../Components/TypeSelector";
import CloseButtonDialog from "../../Components/CloseButtonDialog";

// Import Component for creating custom fields
import SingleCardCreator from "../../Components/SingleCardCreator";
import ApplicationHeaderCreator from "../../Components/ApplicationHeaderCreator";
import CustomFieldsCreator from "../../Components/CustomFieldsCreator";
import LoadingSpinner from "../../Components/LoadingSpinner";
import RoleSelector from "../../Components/AdminCenter/RoleSelector";


const AppMaker = (props) => {

    // State variables for the form.
    const [appCategories, setAppCategories] = useState([]);
    const [modelDict, setModelDict] = useState({});
    const [modelOptions, setModelOptions] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // Config constants
    const taskTypes = [{ value: "GPT" }];
    const defaultImage = "default_icon.png";
    const defaultEndpoint = "run_task";

    useEffect(() => {
        const apiCallBody = {
            task: "model_blob_reader_processor",
            parameters: {},
        };

        const getModels = async () => {
            const response = await postRunTask('run_task', apiCallBody);
            setModelDict(response.data);
        };

        getModels();
    }, []);

    useEffect(() => {
        if (modelDict && Object.keys(modelDict).length > 0) {
            let modelOptionsTemp = Object.keys(modelDict)
                .filter(key => modelDict[key].enabled === true && modelDict[key].allowedTaskTypes.includes("GPT"))
                .map(key => ({
                    key: key,
                    text: key
                }));

            setModelOptions(modelOptionsTemp);
        }
    }, [modelDict]);

    const [selectedPivotKey, setSelectedPivotKey] = useState(0);
    const stackTokens = { childrenGap: 10 };
    const [previewTask, setPreviewTask] = useState({});

    // API Call to get the Categories and Apps
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await fetchAppConfig();
                // Categories
                var tempCategories = Object.keys(data).map(item => {
                    return { value: item };
                });

                setAppCategories(tempCategories);
                props.setLoadingState('');
            } catch (error) {
                props.setLoadingState('');
                showDialog("An error occurred while trying to get the catetgories list. Please try again.");
            };
        };

        if (!props.PreSelectedApp) {
            fetchCategories();
            props.setLoadingState('loading');
        }
    }, []);


    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
        if (refresh) {
            window.location.reload();
        }
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

    const onPivotNextSaveBtnClick = () => {
        if (validatePivot(selectedPivotKey))
            setSelectedPivotKey((selectedPivotKey + 1) % 5);
    };

    // Validates the form before moving to the next pivot
    const validatePivot = (pivotKey) => {
        const tempTask = task;
        switch (pivotKey) {
            case 0:
                if (task.taskType.value && task.category.value)
                    return true;
                console.log(task.allowed_roles);
                showDialog('Please fill all the required fields.');
                return false;
            case 1:
                if (task.taskTitle.value && task.cardText.value)
                    return true;
                showDialog('Please fill all the required fields.');
                return false;
            case 2:
                if (task.taskTitle.value && task.taskDescription.value && task.example_input_text.value) {
                    const customFieldsValidationMessage = validateCustomFieldsArray(task.custom_fields.value);
                    if (customFieldsValidationMessage !== "") {

                        showDialog(customFieldsValidationMessage);
                        return false;
                    }
                    return true;
                }


                showDialog('Please fill all the required fields.');
                return false;
            case 3:
                {
                    if (task.max_prompt.value && task.max_response_tokens.value && task.prompt.value) {
                        const max_prompt_value = parseInt(`${task.max_prompt.value}`.replace(/,/g, ''));
                        const model_max_prompt_value = parseInt(modelDict[task.model.value]?.max_tokens_input.replace(/,/g, ''));
                        if (max_prompt_value > model_max_prompt_value) {
                            showDialog(`Max tokens for input must be less or equals than ${model_max_prompt_value}.`);
                            return false;
                        }

                        const max_response_tokens_value = parseInt(`${task.max_response_tokens.value}`.replace(/,/g, ''));
                        const model_max_response_tokens_value = parseInt(modelDict[task.model.value]?.max_tokens_output.replace(/,/g, ''));
                        if (max_response_tokens_value > model_max_response_tokens_value) {
                            showDialog(`Max tokens for output must be less or equals than ${model_max_response_tokens_value}.`);
                            return false;
                        }
                        updatePreviewTask(task);
                        return true;
                    }

                    showDialog('Please fill all the required fields.');
                    return false;
                }
            case 4:
                {
                    shipApp();
                    return false;
                }
            default:
        }
        setTask(tempTask);
    }

    function validateCustomFieldsArray(arr) {

        let names = [];
        for (let item of arr) {
            const nameInLowerCase = item.name.toLowerCase();

            if (names.includes(nameInLowerCase)) {
                return 'Please rename the optional \'prompt\' field before continuing.';
            } else if (nameInLowerCase === 'prompt') {
                return 'There is more than one field with the same name. Please rename one of them before continuing.';
            }

            names.push(nameInLowerCase);
        }

        return '';
    }

    // Updates the preview task
    const updatePreviewTask = (task) => {
        const tempPreviewTask = {
            "task": task.task.value,
            "category": task.category.value,
            "image": task.image.value,
            "cardTitle": task.taskTitle.value,
            "cardText": task.cardText.value,
            "taskTitle": task.taskTitle.value,
            "taskDescription": task.taskDescription.value,
            "taskInstructions": task.taskDescription.value,
            "prompt": task.prompt.value,
            "max_prompt": task.max_prompt.value,
            "max_response_tokens": task.max_response_tokens.value,
            "model": task.model.value,
            "example_input_text": task.example_input_text.value,
            "template": "GptTasks",
            "custom_fields": task.custom_fields.value,
            "endpoint": "run_task",
            "enabled": task.enabled.value,
            "taskType": task.taskType.value,
            "api_error_message": task.api_error_message.value
        };
        setPreviewTask(tempPreviewTask);
    };

    // When the user clicks on the previous button
    const onPivotPreviousBtnClick = () => {
        setSelectedPivotKey((selectedPivotKey - 1) % 5);
    };

    // Submits the form
    const shipApp = async () => {
        const apiCallBody = {
            "type": "gpt_completion"
        };

        const parameters = {
            "task": task.task.value,
            "category": task.category.value,
            "image": task.image.value,
            "cardTitle": task.taskTitle.value,
            "cardText": task.cardText.value,
            "taskTitle": task.taskTitle.value,
            "taskDescription": task.taskDescription.value,
            "taskInstructions": task.taskDescription.value,
            "prompt": task.prompt.value,
            "max_prompt": task.max_prompt.value,
            "max_response_tokens": task.max_response_tokens.value,
            "model": task.model.value,
            "example_input_text": task.example_input_text.value,
            "template": "GptTasks",
            "custom_fields": task.custom_fields.value,
            "endpoint": "run_task",
            "enabled": task.enabled.value,
            "api_error_message": task.api_error_message.value,
            "taskType": task.taskType.value,
            "allowed_roles": task.allowed_roles.value
        };

        apiCallBody.parameters = parameters;
        props.setLoadingState('loading');

        try {
            console.log(apiCallBody);
            const data = await postRunTask("generate_app", apiCallBody);
            console.log(data);
            showDialog("App shipped successfully.");
            setRefresh(true);
            //setTask(taskTemplate);
        } catch (error) {
            showDialog("An error occured. Please try again.");
            console.error('Error:', error);
        };
        props.setLoadingState('');
    }


    // If true, all componets will check if are required and if they are empty, they will show an error message.
    const [isValidate, setIsValidate] = useState(false);

    const [task, setTask] = useState(null);

    useEffect(() => {
        if (modelOptions.length > 0) {
            const taskTemplate = {
                taskType: {
                    value: (props.PreSelectedApp && props.PreSelectedApp.taskType ? props.PreSelectedApp.taskType : taskTypes[0].value),
                    rule: { required: true },
                    error: {},
                },
                allowed_roles: { value: (props.PreSelectedApp && props.PreSelectedApp.allowed_roles ? props.PreSelectedApp.allowed_roles : []), rules: { required: true }, error: {} },
                task: { value: (props.PreSelectedApp && props.PreSelectedApp.task ? props.PreSelectedApp.task : ""), rules: { required: true }, error: {} },
                category: { value: (props.PreSelectedApp && props.PreSelectedApp.category ? props.PreSelectedApp.category : ""), rules: { required: true }, error: {} },
                image: { value: (props.PreSelectedApp && props.PreSelectedApp.image ? props.PreSelectedApp.image : defaultImage), rules: { required: true }, error: {} },
                imageType: { value: (props.PreSelectedApp ? "existing" : ""), rules: { required: true }, error: {} },
                cardText: { value: (props.PreSelectedApp && props.PreSelectedApp.cardText ? props.PreSelectedApp.cardText : ""), rules: { required: true }, error: {} },
                taskTitle: { value: (props.PreSelectedApp && props.PreSelectedApp.taskTitle ? props.PreSelectedApp.taskTitle : ""), rules: { required: true }, error: {} },
                taskDescription: { value: (props.PreSelectedApp && props.PreSelectedApp.taskDescription ? props.PreSelectedApp.taskDescription : ""), rules: { required: true }, error: {} },
                prompt: { value: (props.PreSelectedApp && props.PreSelectedApp.prompt ? props.PreSelectedApp.prompt : ""), rules: { required: true }, error: {} },
                max_prompt: { value: (props.PreSelectedApp && props.PreSelectedApp.max_prompt ? props.PreSelectedApp.max_prompt : ""), rules: { required: true }, error: {} },
                max_response_tokens: { value: (props.PreSelectedApp && props.PreSelectedApp.max_response_tokens ? props.PreSelectedApp.max_response_tokens : ""), rules: { required: true }, error: {} },
                model: { value: (props.PreSelectedApp && props.PreSelectedApp.model ? props.PreSelectedApp.model : modelOptions[0].key), rules: { required: true }, error: {} },
                example_input_text: { value: (props.PreSelectedApp && props.PreSelectedApp.example_input_text ? props.PreSelectedApp.example_input_text : ""), rules: { required: true }, error: {} },
                template: { value: (props.PreSelectedApp && props.PreSelectedApp.template ? props.PreSelectedApp.template : "GptTasks"), rules: { required: true }, error: {} },
                custom_fields: { value: (props.PreSelectedApp && props.PreSelectedApp.custom_fields ? props.PreSelectedApp.custom_fields : []), rules: { required: false }, error: {} },
                endpoint: { value: (props.PreSelectedApp && props.PreSelectedApp.endpoint ? props.PreSelectedApp.endpoint : defaultEndpoint), rules: { required: true }, error: {} },
                enabled: { value: (props.PreSelectedApp && props.PreSelectedApp.enabled ? props.PreSelectedApp.enabled : "true"), rules: { required: true }, error: {} },
                api_error_message: { value: (props.PreSelectedApp && props.api_error_message ? props.PreSelectedApp.api_error_message : "A problem has come up. We will investigate it. Please try again in a few minutes."), rules: { required: false }, error: {} },
            };
            setTask(taskTemplate);
        }
    }, [modelOptions]);


    // INITIAL TASK STATE
    // State for the task object


    // On any form item change, update the state of the corresponding variable
    const fieldUpdateHandler = (newValue, field) => {
        const tempTask = { ...task };
        switch (field) {
            case 'taskType':
                tempTask.taskType.value = newValue;
                break;
            case 'taskTitle':
                tempTask.taskTitle.value = newValue.target.value;
                if (!props.PreSelectedApp) {
                    tempTask.task.value = createSlug(newValue.target.value);
                }
                break;
            case 'cardText':
                tempTask.cardText.value = newValue.target.value;
                break;
            case 'taskDescription':
                tempTask.taskDescription.value = newValue.target.value;
                break;
            case 'example_input_text':
                tempTask.example_input_text.value = newValue.target.value;
                break;
            case 'category':
                tempTask.category.value = newValue;
                break;
            case 'appCategories':
                appCategories.push({ "value": newValue });
                setAppCategories(appCategories);
                break;
            case 'image':
                if (newValue[0] === undefined) {
                    tempTask.image.value = defaultImage;
                } else {
                    tempTask.image.value = newValue[0];
                }
                tempTask.imageType.value = newValue[1];
                break;
            case 'custom_fields':
                tempTask.custom_fields.value = newValue;
                break;
            case 'prompt':
                tempTask.prompt.value = newValue.target.value;
                break;
            case 'max_prompt':
                // Only numbers
                if (/^\d*$/.test(newValue.target.value)) {
                    tempTask.max_prompt.value = newValue.target.value;
                }
                break;
            case 'max_response_tokens':
                // Only numbers
                if (/^\d*$/.test(newValue.target.value)) {
                    tempTask.max_response_tokens.value = newValue.target.value;
                }
                break;
            case 'model':
                tempTask.model.value = newValue.target.innerText;
                break;
            case 'allowed_roles':
                tempTask.allowed_roles.value = newValue;
                break;
            default:
                break;
        }
        setTask(tempTask);
        updatePreviewTask(tempTask);
    }

    const openLink = (href, target) => {
        window.open(href, target);
    };

    const application = props.application;
    if (!task) {
        return (
            <>
                <Shimmer></Shimmer>
            </>
        );
    }
    return (
        <>
            { /* App Header */}
            <ApplicationHeader application={application} />

            <div style={{ border: '1px solid #EDEBE9', paddingBottom: '10px' }} className="mt-4">
                <Pivot aria-label="App Maker Pivot" selectedKey={String(selectedPivotKey)}>

                    { /* App Type and Category Tab */}
                    <PivotItem
                        itemKey="0"
                        headerText="App Type and Category"
                        className="p-3 pt-4 pb-0"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'App Type and Category',
                        }}
                    >

                        <AdminCenterFormLine
                            component={<TypeSelector fieldUpdateHandler={fieldUpdateHandler} field={"taskType"} fieldValue={task.taskType.value} collection={taskTypes} label={"Type"} />}
                        />


                        <AdminCenterFormLine
                            component={<Divider />}
                        />

                        <AdminCenterFormLine
                            component={<RoleSelector allowed_roles={task.allowed_roles.value} taskTitle={task.taskTitle.value} fieldUpdateHandler={fieldUpdateHandler} />}
                        />

                        {(!props.PreSelectedApp ?
                            <>
                                <AdminCenterFormLine
                                    component={<Divider />}
                                />

                                <AdminCenterFormLine
                                    component={
                                        <>
                                            <TypeSelector fieldUpdateHandler={fieldUpdateHandler} field={"category"} fieldValue={task.category.value} collection={appCategories} collectionName={"appCategories"} label={"Category"} />
                                        </>
                                    }
                                />
                            </>
                            : <></>
                        )}


                    </PivotItem>

                    { /* Card Information Tab */}
                    <PivotItem
                        itemKey="1"
                        headerText="Card Information"
                        className="p-3 pt-4 pb-0"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Card Informtion',
                        }}
                    >
                        <AdminCenterFormLine
                            component={<SingleCardCreator loadingState={props.loadingState} fieldUpdateHandler={fieldUpdateHandler} task={task} />}
                            customClass="col-12 col-md-6 col-lg-6 mb-4"
                        />

                    </PivotItem>

                    { /* Task Information Tab */}
                    <PivotItem
                        itemKey="2"
                        headerText="Task Information"
                        className="p-3 pt-4 pb-0"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Card Informtion',
                        }}
                    >

                        <AdminCenterFormLine
                            component={<ApplicationHeaderCreator loadingState={props.loadingState} fieldUpdateHandler={fieldUpdateHandler} task={task} />}
                        />

                        <AdminCenterFormLine
                            component={<Divider />}
                        />

                        <AdminCenterFormLine
                            component={<CustomFieldsCreator fieldUpdateHandler={fieldUpdateHandler} task={task} loadingState={props.loadingState} fileMaxSize={props.fileMaxSize} showDialog={showDialog} />}
                        />

                        <AdminCenterFormLine
                            component={<Divider />}
                        />


                        {<AdminCenterFormLine
                            component={<GptTaskBodyCreator fieldUpdateHandler={fieldUpdateHandler} task={task} loadingState={props.loadingState} />}
                        />}
                    </PivotItem>


                    { /* Model Information Tab */}
                    <PivotItem
                        itemKey="3"
                        headerText="Model Information"
                        className="p-3 pt-4 pb-0"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Model Informtion',
                        }}
                    >

                        <AdminCenterPromptTextField task={task} fieldUpdateHandler={fieldUpdateHandler} isValidate={isValidate} />

                        <AdminCenterFormLine
                            component={
                                <>
                                    <Label>Select a model: <IconButton iconProps={{ iconName: 'Info' }} onClick={() => openLink("https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/models", "_blank")} /></Label>

                                    <ComboBox
                                        name={""}
                                        allowFreeform={false}
                                        options={modelOptions}
                                        autoComplete="off"
                                        placeholder={"Model"}
                                        text={task.model.value}
                                        onChange={(event) => fieldUpdateHandler(event, "model")}
                                    />
                                    <p className=" mt-2">{modelDict[task.model.value]?.description_text || ''}</p>
                                </>
                            }
                        />

                        <div className="row">
                            <AdminCenterFormLine customClass="col-12 col-md-6 col-md-6 col-lg-6 mb-4"
                                component={<AdminCenterFormTextField fieldUpdateHandler={fieldUpdateHandler} field={"max_prompt"} value={task.max_prompt.value} label={"Specify the max tokens for input:"} placeholder={"Max value: " + modelDict[task.model.value]?.max_tokens_input || '2000'} isRequired={task.max_prompt.rules.required} isValidate={task.max_response_tokens.error?.message !== undefined} isMultiline={false} defaultErrorMessage={task.max_prompt.error?.message} maxLength={"100"} />}
                            />

                            <AdminCenterFormLine customClass="col-12 col-md-6 col-md-6 col-lg-6 mb-4"
                                component={<AdminCenterFormTextField fieldUpdateHandler={fieldUpdateHandler} field={"max_response_tokens"} value={task.max_response_tokens.value} label={"Specify the max tokens for output:"} placeholder={"Max value: " + modelDict[task.model.value]?.max_tokens_output || '2000'} isRequired={task.max_response_tokens.rules.required} isValidate={task.max_response_tokens.error?.message !== undefined} isMultiline={false} defaultErrorMessage={task.max_response_tokens.error?.message} maxLength={"100"} />}
                            />
                        </div>

                    </PivotItem>

                    { /* Summary Tab */}
                    <PivotItem
                        itemKey="4"
                        headerText="Summary"
                        className="p-3 pt-4 pb-0"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'Summary',
                        }}
                    >


                        <AppMakerSummary task={task} previewTask={previewTask} />


                    </PivotItem>

                </Pivot>

                { /* Navigation Buttons */}
                <Stack horizontal wrap tokens={stackTokens} horizontalAlign="end" className="p-3 pt-4 pb-0">
                    <DefaultButton
                        hidden={selectedPivotKey === 0}
                        text="Previous"
                        onClick={onPivotPreviousBtnClick} />
                    <DefaultButton
                        text={selectedPivotKey === 4 ? 'Ship App' : 'Next'}
                        onClick={onPivotNextSaveBtnClick} />
                    {props.loadingState === "loading" && <LoadingSpinner />}
                </Stack>
            </div>

            { /* Dialog */}
            < CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    );
}


export default AppMaker;