// Import Dependencies
import { useEffect, useState, useRef, useContext } from "react";
import { DefaultButton, IconButton } from "@fluentui/react";
import lodash from "lodash";


// Import Components
import CloseButtonDialog from "../Components/CloseButtonDialog";
import TwoButtonDialog from "../Components/TwoButtonDialog";
import ApplicationHeader from "../Components/ApplicationHeader";
import CustomDropdown from "../Components/GptTasks/CustomDropdown";
import CustomSlider from "../Components/GptTasks/CustomSlider";
import CustomTextField from "../Components/GptTasks/CustomTextField";
import CustomFileChooser from "../Components/GptTasks/CustomFileChooser";
import LoadingSpinner from "../Components/LoadingSpinner";
import StartOverButton from "../Components/StartOverButton";

import { LoadingContext } from "../Utils/LoadingManager";
import MediaTransDefaultOutput from "../Components/GptOutput/MediaTransOutput";
import MediaTransOutputModal from "../Components/GptOutput/MediaTransOutputModal";
import { postRunTask } from "../Api/Api";

const MEDIATRANS_DEFAULT_STATE = {
  RESETSTATE: false,
  TASKRESPONSE: null,
  DIALOGHIDDEN: true,
  DIALOGTEXT: true,
  APPLICATION: null,
  CUSTOMFIELDSSTATE: null,
  CUSTOMFIELDSELEMENTS: [],
  UPDATEFIELDDATA: true,
  STATUSRESULTS: false,
  INITCOMPONENT: false,
};

const MediaTrans = (props) => {

  const [modalInfo, setModalInfo] = useState([null, ""]);


  const task = props.application.task;
  //const isBatchProcess = task.includes("batch");
  const isBatchProcess = true;

  const { loading, loadingResponse, clearAll, setLoadingResponseState } =
    useContext(LoadingContext);

  const [resetState, setResetState] = useState(MEDIATRANS_DEFAULT_STATE.RESETSTATE);
  // Constant that will contain dynamic component, based on api response
  const [taskResponse, setTaskResponse] = useState(
    MEDIATRANS_DEFAULT_STATE.TASKRESPONSE
  );
  // Dialog Handle
  const [dialogHidden, setDiaglogHidden] = useState(
    MEDIATRANS_DEFAULT_STATE.DIALOGHIDDEN
  );
  const [dialogText, setDialogText] = useState(MEDIATRANS_DEFAULT_STATE.DIALOGTEXT);
  // Props treatment
  const [application, setApplication] = useState(
    MEDIATRANS_DEFAULT_STATE.APPLICATION
  );

  const [customFieldsState, setCustomFieldsState] = useState(
    MEDIATRANS_DEFAULT_STATE.CUSTOMFIELDSSTATE
  );
  const [customFieldsElements, setCustomFieldsElements] = useState(
    MEDIATRANS_DEFAULT_STATE.CUSTOMFIELDSELEMENTS
  );
  const [statusResults, setStatusResults] = useState(
    MEDIATRANS_DEFAULT_STATE.STATUSRESULTS
  );
  const [initComponent, setInitComponent] = useState(
    MEDIATRANS_DEFAULT_STATE.INITCOMPONENT
  );



  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }


  const [twoButtonDialogHidden, setTwoButtonDialogHidden] = useState(true);
  const [twoButtonDialogText, setTwoButtonDialogText] = useState('');
  const [twoButtonDialogActionFunction, setTwoButtonDialogActionFunction] = useState(null);
  const [twoButtonDialogActionButtonText, setTwoButtonDialogActionButtonText] = useState('');
  const [twoButtonDialogCloseButtonText, setTwoButtonDialogCloseButtonText] = useState('');


  function showTwoButtonDialog(text, actionFunction, actionButtonText, closeButtonText) {
    setTwoButtonDialogText(text);
    setTwoButtonDialogHidden(false);
    setTwoButtonDialogActionButtonText(actionButtonText);
    setTwoButtonDialogCloseButtonText(closeButtonText);
    setTwoButtonDialogActionFunction(() => actionFunction);
  }

  function hideTwoButtonDialog() {
    setTwoButtonDialogHidden(true);
  }

  ///////////////////////////////////////////////////////
  //////             TEMPLATE FUNCTIONS            //////
  ///////////////////////////////////////////////////////

  // Copy right panel content to clipboard
  const rightPanelDiv = useRef(null);

  const setErrors = (fieldNames) => {
    const newFieldData = {};
    Object.entries(customFieldsState).forEach(([key, value]) => {
      newFieldData[key] = {
        ...value,
        data: {
          ...value.data,
          hasError: fieldNames.includes(key),
        },
      };
    });
    setCustomFieldsState(newFieldData);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  function dateFormat(dateToBeFormatted) {
    const tempDate = new Date(dateToBeFormatted);

    const years = tempDate.getFullYear();
    const months = (tempDate.getMonth() + 1).toString().padStart(2, '0');
    const days = tempDate.getDate().toString().padStart(2, '0');
    const hours = tempDate.getHours().toString().padStart(2, '0');
    const minutes = tempDate.getMinutes().toString().padStart(2, '0');
    const seconds = tempDate.getSeconds().toString().padStart(2, '0');

    return `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`;
  }

  function getCustomFieldState(
    customField,
    hasError = false,
    userSelectedValue = null,
    errorMessage = null
  ) {
    const data = {
      hasError: hasError,
      errorMessage: errorMessage,
      userSelectedValue: userSelectedValue,
    };
    return {
      [customField.name]: {
        props: customField,
        data: data,
      },
    };
  }

  function isValidYouTubeLink(URL) {

    if (URL === null || URL === "") return false;

    var pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(URL);
  }


  // Submit form functions
  const submitForm = () => {
    var errorMessage = "";
    if (!isValidYouTubeLink(customFieldsState["video_url"].data.userSelectedValue) && customFieldsState["files"].data.userSelectedValue === null) {
      errorMessage = "Please enter a valid YouTube link.";
    }

    if ((customFieldsState["video_url"].data.userSelectedValue === null || customFieldsState["video_url"].data.userSelectedValue === "") && customFieldsState["files"].data.userSelectedValue === null) {
      errorMessage = "Please select one media file or paste a youtube link to transcribe.";
    }



    if (errorMessage !== "") {
      showDialog(errorMessage);
      return;
    } else {

      if (customFieldsState["video_url"].data.userSelectedValue !== null && customFieldsState["video_url"].data.userSelectedValue !== "" && customFieldsState["files"].data.userSelectedValue !== null) {
        showDialog("You selected files and pasted a youtube link. The app will only process files.");
      }

      //const componentId = uuidv4();
      const response = (
        <MediaTransDefaultOutput
          showDialog={showDialog}
          key={"transcriptionOutput"}
          application={application}
          leftPanelInputValue={null}
          setErrors={setErrors}
          fieldData={customFieldsState}
          taskResponse={taskResponse}
          setStatusResults={setStatusResults}
        />
      );
      setTaskResponse(response);
    }

  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  function downloadFile(job, jobId) {
    job["base64Files"].forEach((item, index) => {
      var base64Content = item;

      var byteArray = base64ToArrayBuffer(base64Content);

      var file = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      var downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = (job.files_names[0].key.startsWith("http") ? jobId + "_" + (index + 1) : job.files_names[0].key.split(".")[0]) + ".docx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });

  }



  function readJob(jobId, transcription) {
    setModalInfo([jobId, transcription]);
  }

  function handleDeleteJobRequest(jobId) {

    setTaskResponse(null);
    postRunTask(props.application.endpoint, {
      task: "delete_media_file_transcription",
      parameters: {
        transcription_id: jobId,
      },
    })
      .then(async (response) => {
        console.log(response);
        if (response.status === "Succeeded") {
          showDialog("Job successfully deleted.");
        } else {
          showDialog("There is an issue deleting the job. Please try again in a few minutes");
        }
      }
      )
      .catch((error) => {
        console.log(error);
        showDialog("There is an issue deleting the job. Please try again in a few minutes");
      });
    setResetState(true);
  }

  const renderIcon = (job) => {
    if (job["status"] === "Completed") {
      return (
        <p className="ctFontSize d-flex align-items-center justify-content-center pt-3" key={`${job["job_id"]}downloadAll`}>
          <IconButton
            iconProps={{ iconName: "DrillDownSolid" }}
            title="Download"
            role="button"
            className="download-icon align-self-center"
            key={`cell${job["job_id"]}-downloadAll`}
            onClick={() => downloadFile(job, job["job_id"])}
          />
          <IconButton
            iconProps={{ iconName: "Zoom" }}
            title="Read"
            role="button"
            className="download-icon"
            key={`cell${job["job_id"]}-readAll`}
            onClick={() => readJob(job["job_id"], job["data"][0].data)}
          />

          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Delete"
            role="button"
            className="download-icon"
            key={`cell${job["job_id"]}-deleteAll`}
            onClick={() => showTwoButtonDialog("Do you want to remove the Job?", () => handleDeleteJobRequest(job["job_id"]), "Yes", "No")}
          />
        </p>
      );
    } else if (job["status"] === "Processing") {
      return (
        <p className="ctFontSize d-flex align-items-center justify-content-center pt-3" key={`${job["job_id"]}processingAll`}>
          <IconButton
            iconProps={{ iconName: "SkypeCircleClock" }}
            title="Processing"
            role="icon"
            className="processing-icon"
            key={`cell${job["job_id"]}-processingAll`}
          />
        </p>
      );
    } else {
      return (
        <p className="ctFontSize d-flex align-items-center justify-content-center pt-3" key={`${job["job_id"]}failedAll`}>
          <IconButton
            iconProps={{ iconName: "StatusErrorFull" }}
            title="Failed"
            role="icon"
            className="failed-icon"
            key={`cell${job["job_id"]}-failedAll`}
          />
        </p>
      );
    }
  }


  const renderStatus = (job) => {
    if (job["status"] === "Completed") {
      return (
        <div className="ctStatePill completedState">
          {job["status"]}
        </div>
      );
    } else if (job["status"] === "Processing") {
      return (
        <div className="ctStatePill processingState">
          {job["status"]}
        </div>);
    } else {
      return (
        <div className="ctStatePill failedState">
          {job["status"]}
        </div>);
    }
  }


  useEffect(() => {
    if (resetState) {
      // Reset all states
      setResetState(MEDIATRANS_DEFAULT_STATE.RESETSTATE);
      setTaskResponse(MEDIATRANS_DEFAULT_STATE.TASKRESPONSE);
      setDiaglogHidden(MEDIATRANS_DEFAULT_STATE.DIALOGHIDDEN);
      setDialogText(MEDIATRANS_DEFAULT_STATE.DIALOGTEXT);
      setApplication(MEDIATRANS_DEFAULT_STATE.APPLICATION);
      setCustomFieldsState(MEDIATRANS_DEFAULT_STATE.CUSTOMFIELDSSTATE);
      setCustomFieldsElements(MEDIATRANS_DEFAULT_STATE.CUSTOMFIELDSELEMENTS);
      setStatusResults(MEDIATRANS_DEFAULT_STATE.STATUSRESULTS);
      setInitComponent(MEDIATRANS_DEFAULT_STATE.INITCOMPONENT);
    }
  }, [resetState]);

  useEffect(() => {

    const generateHash = async (str) =>
      Array.from(
        new Uint8Array(
          await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str))
        )
      )
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

    if (initComponent && !statusResults) {
      setLoadingResponseState(task, true);
      setStatusResults(true);
      const exclude_headers = ["data", "download_url", "index_data", "prompt_names", "job_id", "requestor_email", "base64Files"];

      postRunTask(props.application.endpoint, {
        task: "status_checker_processor",
        parameters: {
          process: "media_file_transcription",
          access_level: "private",
          job_id: "all"
        },
      })
        .then(async (response) => {
          const jobs = response.jobs.sort((a, b) => new Date(b.submission_date) - new Date(a.submission_date));
          const groups = {};

          for (const job of jobs) {
            job["submission_date"] = dateFormat(job["submission_date"]);
            job["download"] = renderIcon(job);
            job["status"] = renderStatus(job);


            job["files_names"] = job["files_names"]
              .split(",")
              .map((filename) => {
                if (filename.startsWith("http")) {
                  return <a target="_blank" key={`${filename}file`} href={filename} rel="noreferrer" className="ctFontSize">{filename}</a>;
                } else {
                  return <p key={`${filename}file`} className="ctFontSize">{filename}</p>;
                }
              });
            const headerList = Object.keys(job).filter(
              (key) => !exclude_headers.includes(key)
            );
            const formattedHeaderList = headerList.map((key) =>
              toTitleCase(key.replace("_", " "))
            );
            const header = headerList.join(",");
            const headerHash = await generateHash(header.toLowerCase());
            if (!groups[headerHash]) {
              groups[headerHash] = { header: formattedHeaderList, body: [] };
            }
            groups[headerHash].body.push(headerList.map((h) => job[h]));
          }
          const results = Object.values(groups);
          const body = results.map((result, i) => {
            return (
              <div
                className="ctable ctable-striped mb-4 w-100"
                key={`table${i}`}
              >
                <div className="cthead-dark">
                  <div className="ctr">
                    {result["header"].map((header, j) => (
                      <div className="cth ctFontSize" key={`header${j}`}>
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="ctbody">
                  {result["body"].map((row, j) => (
                    <div className="ctr" key={`row${j}`}>
                      {row.map((cell, k) => (
                        <div className="ctd ctFontSize" key={`cell${k}`}>
                          {cell}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            );
          });
          setInitComponent(false);
          setTaskResponse(body);
        })
        .catch((error) => {
          console.log(error);
          showDialog(
            "There is an issue loading the status of the jobs. Please try again in a few minutes"
          );
        })
        .finally(() => {
          setLoadingResponseState(task, false);
        });
    }
  },
    // eslint-disable-next-line
    [
      initComponent,
      isBatchProcess,
      props.application.endpoint,
      setLoadingResponseState,
      statusResults,
      task,
    ]);

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);

      clearAll();
      setApplication(props.application);
      setCustomFieldsState(
        props.application.custom_fields
          .map((customField) => getCustomFieldState(customField))
          .reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key];
            return acc;
          }, {})
      );
    }
  }, [clearAll, initComponent, props.application]);

  useEffect(() => {
    if (initComponent) {
      const clearError = (field) => {
        const newFieldData = {
          ...customFieldsState,
          [field]: {
            ...customFieldsState[field],
            data: {
              ...customFieldsState[field].data,
              hasError: false,
            },
          },
        };
        setCustomFieldsState(newFieldData);
      };
      const setValue = ({
        field,
        value,
        key,
        data,
        operation,
        refetch = false,
      }) => {
        if (field) {
          const newFieldData = {
            ...customFieldsState,
            [field]: {
              ...customFieldsState[field],
              data: {
                ...customFieldsState[field].data,
                userSelectedValue: value,
                userSelectedKey: key,
                userSelectedData: data,
                hasError: false,
                changed: !lodash.isEqual(
                  customFieldsState[field].data.userSelectedValue,
                  value
                ),
                dependencyChanged: false,
                operation: operation,
                refetch: refetch,
              },
            },
          };
          setCustomFieldsState(newFieldData);
        }
      };

      const _showLeftPanel = (show) => {
        // Nothing to do
      };

      // Create components for custom fields, based in JSON dictionary
      var generalOptions = `col-12 d-flex mt-2 mb-2 align-items-center ${Object.keys(customFieldsState).length > 1 ? "col-md-6" : ""
        }`;
      var conditionalOptions = "";
      const _customFieldsElements = Object.values(customFieldsState).map(
        (value, index) => {
          const fieldData = value.data;
          const fieldProps = value.props;
          if (index % 2 === 0) {
            conditionalOptions = "ps-0 pe-0 ps-md-0 pe-md-5";
          } else {
            conditionalOptions = "ps-0 pe-0  ps-md-2 pe-md-0";
          }

          const options = generalOptions + " " + conditionalOptions;

          let element = <></>;

          // Create component based on custom field type
          switch (fieldProps.type) {
            case "dropdown":
              element = (
                <div key={index} className={options}>
                  <CustomDropdown
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    endpoint={application.endpoint}
                    setValue={setValue}
                    clearError={clearError}
                  />
                </div>
              );
              break;
            case "slider":
              element = (
                <div key={index} className={options}>
                  <CustomSlider
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "text":
              element = (
                <div key={index} className={options}>
                  <CustomTextField
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "file":
              element = (
                <div key={index} className={options}>
                  <CustomFileChooser
                    showDialog={showDialog}
                    fileMaxSize={props.application.fileMaxSize}
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                    clearError={clearError}
                    showLeftPanel={_showLeftPanel}
                  />
                </div>
              );
              break;
            default:
              return <></>;
          }

          return element;
        }
      );

      setCustomFieldsElements(_customFieldsElements);
    }
  },
    // eslint-disable-next-line
    [
      application?.endpoint,
      customFieldsState,
      initComponent,
      props.fileMaxSize,
    ]);



  // Component Render
  return !initComponent ? null : (
    <>
      {/* App Header */}
      <ApplicationHeader application={application} />

      {/* Custom Controls Area */}
      {/* {Object.keys(customFieldsState ?? {}).length > 0 ? ( */}
      {customFieldsElements.length > 0 ? (
        <>
          <div className="col-12 mt-1 mb-2">
            <div className="taskDivider"> </div>
          </div>
          <div className="row m-0 mt-0 p-0 d-flex ">{customFieldsElements}</div>
        </>
      ) : null}
      <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center justify-content-end">
        {loadingResponse && <div className="me-3"> <LoadingSpinner /> </div>}
        <DefaultButton
          iconProps={{ iconName: "subscribe" }}
          disabled={loading || loadingResponse}
          text="Submit"
          onClick={submitForm}
        ></DefaultButton>
      </div>


      <div className="col-12 mt-1 mb-3">
        <div className="taskDivider"> </div>
      </div>

      {/* Result Area */}
      <div className="row d-flex flex-grow-1 m-0 p-0">
        {/* Right Panel */}
        <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-3 pt-2 pb-3 pe-md-3 mt-3 mt-md-0 w-100">

          <div className="col-12 p-0 m-0 pb-2">
            {/* Create a button to navigate to home */}
            <StartOverButton resetFunction={setResetState} label={"Refresh List"} />
          </div>


          <div
            id="rightPanelDiv"
            ref={rightPanelDiv}
            className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight"
          >
            {taskResponse}
          </div>
        </div>
      </div>


      { /* Two Button Dialog */}
      <TwoButtonDialog dialogHidden={twoButtonDialogHidden} dialogText={twoButtonDialogText} actionFunction={twoButtonDialogActionFunction} hideDialog={hideTwoButtonDialog} closeButtonText={twoButtonDialogCloseButtonText} actionButtonText={twoButtonDialogActionButtonText} />

      {/* Dialog */}
      <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />

      {modalInfo[0] !== null && <MediaTransOutputModal modalInfo={modalInfo} setModalInfo={setModalInfo} application={application} />}

    </>
  );
};

export default MediaTrans;
