// Create a functional component called Dropdown

import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@fluentui/react";
import { LoadingContext } from "../../Utils/LoadingManager";

const CustomTextField = (props) => {
  const defaultErrorMessage = "Please add some text here";
  // const inputRef = useRef(null);

  const { loading, loadingResponse } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ start: 0, end: 0 });
  const [userSelectedValue, setUserSelectedValue] = useState(null);

  useEffect(() => {
    setUserSelectedValue(props.fieldData.userSelectedValue);
  }, [props.fieldData.userSelectedValue]);

  const fieldUpdateHandler = (event, value) => {
    const textField = event.target;
    props.setValue({ field: props.fieldProps.name, value, operation: props.fieldData.operation });
    setUserSelectedValue(value);
    setCursorPosition({
      start: textField.selectionStart,
      end: textField.selectionEnd,
    });
  };

  useEffect(() => {
    if (props.fieldData.hasError) {
      setErrorMessage(
        props.fieldProps.errorMessage ??
        props.fieldData.errorMessage ??
        defaultErrorMessage
      );
    } else {
      setErrorMessage(null);
    }
  }, [props.fieldData.errorMessage, props.fieldData.hasError, props]);

  return (
    <TextField
      name={props.fieldProps.name}
      label={props.fieldProps.label} // TextField is not exporting any aria-label, it is ariaLabel- RS
      aria-label={props.fieldProps.label} // TextField is not exporting any aria-label, it is ariaLabel- RS
      placeholder={props.fieldProps.placeholder ?? "write here"}
      onChange={(event, newValue) => fieldUpdateHandler(event, newValue)}
      onBlur={(event) => fieldUpdateHandler(event, event.target.value)}
      className="w-100"
      errorMessage={props.mocked ? null : errorMessage}
      disabled={
        props.fieldData?.disabled ||
        (props.mocked ? false : loading || loadingResponse)
      }
      value={props.fieldData?.disabled ? "" : userSelectedValue ?? undefined}
      multiline={props.fieldProps.multiline === "true"}
      start={cursorPosition.start}
      end={cursorPosition.end}
    />
  );
};

export default CustomTextField;
