// Import Dependencies
import { React, useState, useRef } from "react";
import { TextField, DefaultButton, ActionButton } from '@fluentui/react';
import CloseButtonDialog from "../Components/CloseButtonDialog";
import { postRunTask } from '../Api/Api'

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";
import FabricationLogger from "../Components/FabricationLogger";

const VideoTranscript = (props) => {
    const rightPanelDiv = useRef(null);
    const leftPanelDiv = useRef(null);    

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);
    const [youtubeURL, setYoutubeURL] = useState('');
    const [questionValue, setQuestionValue] = useState("");
    const [youtubeTranscription, setYoutubeTranscription] = useState("");
    const [youtubeGeneratedText, setYoutubeGeneratedText] = useState("");
    const [answeredQuestion, setAnsweredQuestion] = useState('');
    const [questionLoadingState, setQuestionLoadingState] = useState('');
    const [hasResult, setHasResult] = useState(false);


    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

    // Props treatment
    const application = props.application;

    // Get answer to question

    const submitFormQuestion = async () => {
        if (youtubeTranscription !== '' && questionValue !== '') {
            setHasResult(false);
            const apiParameters = {};
            apiParameters['video_url'] = youtubeURL;
            apiParameters['transcription'] = youtubeTranscription;
            apiParameters['question'] = questionValue;
            
            setAnsweredQuestion('');
            setQuestionLoadingState('loading');

            //check if props.application.qnatask is set else default to youtube_qna_processor
            const apiCallBody = { task: props.application.qnatask ? props.application.qnatask : "youtube_qna_processor", parameters: apiParameters };

            try {
                // Call the API
                const data= await postRunTask(application.endpoint,apiCallBody);
                setAnsweredQuestion(data.generated_text);
                setQuestionLoadingState('');      
                setHasResult(true);
            }catch(error){
                 showDialog(application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please enter a valid YouTube URL and click button to transcribe.");
        }
    }


    // Get Video Transcription
    const  submitFormYoutubeURL = async () => {
        if (youtubeURL !== '') {
            setYoutubeGeneratedText('');
            setYoutubeTranscription('');
            setAnsweredQuestion('');
            setQuestionValue('');

            const apiParameters = {};
            apiParameters['video_url'] = youtubeURL;
            const apiCallBody = { task: props.application.task, parameters: apiParameters };

            props.setLoadingState('loading');

            try {
                // Call the API
                const data= await postRunTask(application.endpoint,apiCallBody);

                if(data.category){
                    showDialog(data.message);
                }else{
                setYoutubeTranscription(data.transcription);
                setYoutubeGeneratedText(data.generated_text);
                }       
                props.setLoadingState('');   
            }catch(error){
                 showDialog(application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please enter a valid YouTube URL.");
        }
    }

        // On any form item change, update the state of the corresponding variable
        const fieldUpdateHandler = (event, field) => {
            switch (field) {
                case 'questionValue':
                    setQuestionValue(event.target.value);
                    break;
                case 'youtubeURL':
                    setYoutubeURL(event.target.value);
                    break;
            }
        }

        // Handles the click on the left panel and focuses white div in left panel TextField, if theres any text selected, then deselect it
        const leftPanelClickHandle = () => {
            document.getElementsByName("leftPanelInput")[0].focus();
        }
 
        const handleYoutubeURLKeyDown = (event) => {
            if (event.key === 'Enter') {
                submitFormYoutubeURL();
            }
        }; 

        const handleAskKeyDown = (event) => {
            if (event.key === 'Enter') {
                submitFormQuestion();
            }
        }; 


        // Component Render
        return (
            <>
                { /* App Header */}
                <ApplicationHeader application={application} />


                <div className="col-12 mt-1 mb-3">
                    <div className="taskDivider"> </div>
                </div>

                { /* Question Area */}
                <div className="row m-0 mt-0 p-0 d-flex ">
                    <div className="col-12 d-sm-flex p-0 ">
                        <TextField readOnly={props.loadingState !== '' || questionLoadingState !== ''}  value={youtubeURL} onChange={(event) => fieldUpdateHandler(event, "youtubeURL")} placeholder={application.example_input_text} className="flex-grow-1 me-0 me-sm-3 mb-2 mb-sm-0"  onKeyDown={handleYoutubeURLKeyDown} />
                        <DefaultButton disabled={props.loadingState !== '' || questionLoadingState !== ''} iconProps={{ iconName: 'send' }} className="w-100-responsive" text="Transcript video" onClick={submitFormYoutubeURL} ></DefaultButton>
                        {props.loadingState === "loading" && <LoadingSpinner />}
                    </div>
                </div>

                <div className="col-12 mt-3 mb-3">
                    <div className="taskDivider"> </div>
                </div>


                { /* Result Area */}
                <div className="row d-flex flex-grow-1 m-0 p-0">

                    { /* Left Panel */}
                    <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3" onClick={leftPanelClickHandle}>

                        <div className="col-12 pb-1">
                            <h4>Transcript</h4>
                        </div>

                        <div className="col-12 pb-3 d-sm-flex">
                            <TextField  readOnly={props.loadingState !== '' || questionLoadingState !== ''}  value={questionValue} onChange={(event) => fieldUpdateHandler(event, "questionValue")} name="leftPanelInput" id="leftPanelInput" placeholder={"Ask a question about the transcript"} className="flex-grow-1 me-0 me-sm-3 mb-2 mb-sm-0" onKeyDown={handleAskKeyDown} />
                            <DefaultButton  disabled={props.loadingState !== '' || questionLoadingState !== ''}  iconProps={{ iconName: 'send' }} className="w-100-responsive" text="Ask" onClick={submitFormQuestion} />
                            {questionLoadingState === "loading" && <LoadingSpinner />}
                        </div>
                        <div ref={leftPanelDiv} id="leftPanel" className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight">
                            <p>{youtubeTranscription}</p>
                        </div>
                        <div className="col-12 pt-2 mb-2 panelFooterHeight">
                            <CopyTextButton  text={leftPanelDiv} isShowDialog={true} message={"Text copied."} loadingState={props.loadingState}  />                                                                    
                        </div>
                    </div>

                    { /* Right Panel */}
                    <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                        <div ref={rightPanelDiv}  className="col-12 flex-grow-1 bgCustomGray p-3 pt-2 mobileMinHeight">

                            {answeredQuestion !== "" ?
                                <p>
                                    <h4>Answer to your question</h4>
                                    {answeredQuestion}
                                </p>
                                : null}

                            {youtubeGeneratedText !== "" ?
                                <p>
                                    <h4>Summary</h4>
                                    {youtubeGeneratedText}
                                </p>
                                : null}
                        </div>
                        {hasResult === true && (
                            <FabricationLogger
                            application={application}
                            loadingState={props.loadingState}
                            setLoadingState={props.setLoadingState}
                            showDialog={showDialog}
                            />
                        )}                        
                        <div className="col-12 pt-2 mb- panelFooterHeight">
                        <StartOverButton loadingState={props.loadingState}  />                        
                        <CopyTextButton  text={rightPanelDiv} isShowDialog={true} message={"Text copied."} loadingState={props.loadingState}  />                                        
                        </div>
                    </div>
                </div>

                { /* Dialog */}
                <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
            </>
        )
    };

    export default VideoTranscript;