// Create a functional component called Dropdown

import { React, useContext, useEffect, useState } from "react";
import { Slider } from "@fluentui/react";
import { LoadingContext } from "../../Utils/LoadingManager";

const CUSTOM_SLIDER_DEFAULT_STATE = {
  INITCOMPONENT: false,
};

const CustomSlider = (props) => {
  const { loading, loadingResponse } = useContext(LoadingContext);

  const [initComponent, setInitComponent] = useState(
    CUSTOM_SLIDER_DEFAULT_STATE.INITCOMPONENT
  );
  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);
      const sliderLine = document.querySelector(".ms-Slider-line");
      sliderLine.setAttribute("data-testid", "slider-line");
      if (props.fieldProps.default_value) {
        props.setValue({
          field: props.fieldProps.name,
          value: props.fieldProps.default_value,
        });
      }
    }
  }, [initComponent, props]);

  // On any form item change, update the state of the corresponding variable
  const fieldUpdateHandler = (_event, value) => {
    props.setValue({ field: props.fieldProps.name, value });
  };

  return (
    <>
      <div className="pe-3 text-size">{props.fieldProps.label_min}</div>
      <Slider
        name={props.fieldProps.name}
        disabled={props.mocked ? false : loading || loadingResponse}
        aria-label={props.fieldProps.label}
        min={parseInt(props.fieldProps.min)}
        max={parseInt(props.fieldProps.max)}
        step={parseInt(props.fieldProps.step)}
        defaultValue={parseInt(props.fieldProps.default_value)}
        onChanged={(event, value, _range) => fieldUpdateHandler(event, value)}
        showValue={false}
        styles={{
          root: { width: "100%" },
        }}
        className="mt-1"
        //errorMessage={props.fieldProps.errorMessage ? props.fieldProps.errorMessage : null} fuentui slider is not exporting errorMessage props.
      />
      <div className="ps-3 text-size">{props.fieldProps.label_max}</div>
    </>
  );
};

export default CustomSlider;
