import UI_dict from "../assets/json/UI_dict.json";
import { Link } from "react-router-dom";
import { Carousel } from "bootstrap";
import { useCallback, useEffect, useRef } from "react";

const HomeCarousel = () => {
  const carouselRef = useRef(null);

  const disposeCarousel = useCallback(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const carouselInstance = Carousel.getInstance(carousel);
      if (carouselInstance) {
        carouselInstance.dispose();
      }
    }
  }, []);

  useEffect(() => {
    new Carousel(carouselRef.current, {
      ride: "carousel",
    });

    return () => {
      disposeCarousel();
    };
  }, [disposeCarousel]);

  return (
    <div
      id="mainPageCarousel"
      className="carousel slide m-0 p-0"
      ref={carouselRef}
    >
      <div className="carousel-inner carouselRadius">
        {UI_dict.carrousel.map((item, index) => (
          <div
            role="group"
            key={index}
            className={index === 0 ? "carousel-item active" : "carousel-item"}
          >
            <div
              className="carouselImage"
              role="img"
              style={{
                backgroundImage: `url(./assets/images/v3/carousel/${item.image})`,
              }}
            >
              <div role="caption" className="carouselCaption">
                <div className="carouselTitle">{item.title}</div>
                <p className="carouselText">{item.text}</p>
                <Link
                  to={item.appLink}
                  className="rightMenuElement"
                  aria-label={item.title}
                >
                  {item.appText !== "" ? (
                    <button
                      className="carouselCallToAction"
                      name={item.appLink}
                    >
                      {item.appText}
                    </button>
                  ) : null}{" "}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#mainPageCarousel"
        data-bs-slide="prev"
        style={{ width: "10%" }}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#mainPageCarousel"
        data-bs-slide="next"
        style={{ width: "10%" }}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
      <div className="carousel-indicators " id="carouselNavigator">
        {UI_dict.carrousel.map((item, index) => (
          <div
            key={index}
            type="button"
            data-bs-target="#mainPageCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-label={"Slide " + index}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HomeCarousel;
