// Page for Frequently Asked Questions

// Import frequently asked questions from json file 
import Faq_dict from '../assets/json/Faq_dict.json';
import parser from 'html-react-parser';


const Faq = () => {

    // Render the page
    return (
        <>
            <div class="row mt-0 p-0 ps-1 pe-1">
                <div class="col-12">
                    <h1 class="d-flex taskTitle text-start ms-0 mt-1 mb-0">Frequently Asked Questions</h1>
                </div>

                <div class="col-12 mt-4 mb-4">
                    <div class="taskDivider"> </div>
                </div>


                { /* Loop through the FAQ dictionary and render the questions and answers */}
                {Object.entries(Faq_dict).map(([claveExterna, valores]) => (
                    <div key={claveExterna}>
                        <h2 className="mb-3">{claveExterna}</h2>
                        {Object.entries(valores).map(([clave, valor]) => (
                            <div key={clave}>
                                <h3 className="faqQuestionTitle text-start">{clave}</h3>
                                <p>{parser(valor)}</p>
                                <br />
                            </div>
                        ))}
                    </div>
                ))}

            </div>



            <div class="row mt-3 p-0 ps-1 pe-1">
                <div class="col-12">
                    <h1 class="d-flex taskTitle text-start ms-0 mt-1 mb-3">Disclaimer</h1>
                </div>
                <div class="col-12 mt-2 mb-4">
                    <div class="taskDivider"> </div>
                </div>
                <div class="col-12 mb-4">
                    <p>This IHME SysReview Copilot is for UW IHME Use Only.</p>
                    <p>The IHME SysReview Copilot is provided “AS-IS” and “AS AVAILABLE” and is used at your own risk.  AI-generated content can have mistakes, and we do not guarantee that the IHME SysReview Copilot is error free. Note that interactions with this tool are not protected by the attorney-client privilege. This tool is not meant to replace any type of consulting. You are solely responsible for evaluating, checking, and deciding whether to rely on any information provided by the IHME tool.</p>
                    <p>Do not input personal or health information into the tool, or any information that is protected by the attorney-client privilege or work product immunity; and do not input information that is Highly Confidential or otherwise restricted into the IHME tool.</p>
                </div>
            </div>
        </>

    )

};

export default Faq;