// This component is used to display a diaglog box with a close button.

// Import Dependencies
import { React, useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, DialogContent } from '@fluentui/react';

const TwoButtonDialog = (props) => {
    const [actionRunning, setActionRunning] = useState(false);

    var dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Important',
        closeButtonAriaLabel: 'Close',
        subText: props.dialogText,
    };

    function action() {
        setActionRunning(true);
        Promise.resolve(props.actionFunction()).then(() => {
            setActionRunning(false);
            props.hideDialog()
        });
    }

    return (
        <Dialog
            hidden={props.dialogHidden}
            dialogContentProps={dialogContentProps}
            onDismiss={props.hideDialog}
        >
            {actionRunning && <div className='position-absolute spinner-border text-primary ms-3' role='status' />}
            <DialogFooter>
                <PrimaryButton
                    disabled={actionRunning}
                    onClick={action}
                    text={props.actionButtonText}
                />
                <DefaultButton
                    disabled={actionRunning}
                    onClick={props.hideDialog}
                    text={props.closeButtonText}
                />
            </DialogFooter>
        </Dialog>
    )

};

export default TwoButtonDialog;