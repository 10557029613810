// Import Dependencies
import { React, useState, useEffect } from "react";
import { createSlug } from "../../assets/utils/textHelpers";
import { DefaultButton, Label } from '@fluentui/react';

import { postRunTask, fetchAppConfig } from '../../Api/Api'

// Import Components
import ApplicationHeader from "../../Components/ApplicationHeader";
import AdminCenterFormLine from "../../Components/AdminCenter/AdminCenterFormLine";
import Divider from "../../Components/Divider";
import CloseButtonDialog from "../../Components/CloseButtonDialog";

// Import Component for creating custom fields
import SingleCardCreator from "../../Components/SingleCardCreator";
import ApplicationHeaderCreator from "../../Components/ApplicationHeaderCreator"
import LoadingSpinner from "../../Components/LoadingSpinner";



const AppMakerCustom = (props) => {
    // State variables for the form.
    const defaultImage = "default_icon.png";

    const [task, setTask] = useState(convertObject(props.PreSelectedApp));

    // Convert object to be compatible with components:
    function convertObject(originalObject) {
        const convertedObject = {};

        for (const key in originalObject) {
            if (originalObject.hasOwnProperty(key)) {
                convertedObject[key] = {
                    value: originalObject[key]
                };
            }
        }

        return convertedObject;
    }


    // Deconverte the object to be compatible with app shipping
    function deconvertObject(convertedObject) {
        const originalObject = {};
        for (const key in convertedObject) {
            if (convertedObject.hasOwnProperty(key)) {
                originalObject[key] = convertedObject[key].value;
            }
        }

        return originalObject;
    }


    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

    // Submits the form
    const shipApp = async () => {
        if (task.taskTitle.value === "" || task.taskDescription.value === "" || task.cardText.value === "" || task.image.value === "") {
            showDialog("Please fill out all fields.");
        } else {
            props.setLoadingState('loading');
            const apiCallBody = {
                "type": "forceupdate"
            };

            const parameters = deconvertObject(task)
            apiCallBody.parameters = parameters;
            try {
                const data = await postRunTask("generate_app", apiCallBody);
                showDialog("App shipped successfully.");
                props.setLoadingState('');
            } catch (error) {
                showDialog("An error occured. Please try again.");
                console.error('Error:', error);
                props.setLoadingState('');
            };
            
        }
    }





    // INITIAL TASK STATE
    // State for the task object


    // On any form item change, update the state of the corresponding variable
    const fieldUpdateHandler = (newValue, field) => {
        const tempTask = { ...task };
        switch (field) {
            case 'taskTitle':
                tempTask.taskTitle.value = newValue.target.value;
                if (!props.PreSelectedApp) {
                    tempTask.task.value = createSlug(newValue.target.value);
                }
                break;
            case 'cardText':
                tempTask.cardText.value = newValue.target.value;
                break;
            case 'taskDescription':
                tempTask.taskDescription.value = newValue.target.value;
                break;
            case 'image':
                if (newValue[0] === undefined) {
                    tempTask.image.value = defaultImage;
                } else {
                    tempTask.image.value = newValue[0];
                }
                break;
            default:
                break;
        }
        setTask(tempTask);
    }

    const application = props.application;

    return (
        <>

            { /* App Header */}
            <ApplicationHeader application={application} />

            <div className="mt-4">

                <div className="row">


                    <AdminCenterFormLine
                        component={
                            <>
                                <Label className="me-3 mb-3">Card Configuration:</Label>
                                <SingleCardCreator  loadingState={props.loadingState}  fieldUpdateHandler={fieldUpdateHandler} task={task} />
                            </>
                        }
                        customClass="col-12 col-md-6 col-lg-6 mb-4"
                    />

                    <AdminCenterFormLine
                        component={<Divider />}
                        customClass="col-12 mb-4"
                    />

                    <AdminCenterFormLine
                        component={
                            <>
                                <Label className="me-3 mb-3">App Header Configuration:</Label>
                                <ApplicationHeaderCreator loadingState={props.loadingState} fieldUpdateHandler={fieldUpdateHandler} task={task} />
                            </>}
                        customClass="col-12 mb-4"
                    />

                    <AdminCenterFormLine
                        component={<Divider />}
                    />


                </div>
                <div className="row">
                    <AdminCenterFormLine
                        component={
                            <div class="d-flex justify-content-end">
                            {props.loadingState === "loading" && <LoadingSpinner/>}
                            <DefaultButton
                                className="ms-3"
                                text='Ship App'
                                onClick={shipApp} />
                            
                            </div>
                        }
                        customClass="col-12 mb-4 text-end"
                    />
                </div>
            </div>

            { /* Dialog */}
            < CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    );
}


export default AppMakerCustom;