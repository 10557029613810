import React, { useEffect, useRef  } from 'react';
import { DataSet, Network } from 'vis-network/standalone';
import { IconButton } from '@fluentui/react';
import { requestFullScreen } from '../../Utils/Utils';

function NetworkGraph({ chart_list }) {

  const networkContainerRef = useRef(null);

  useEffect(() => {
    const uniqueNodes = {};
    const edgesData = [];


    chart_list.forEach(item => {

      uniqueNodes[item.from] = { id: item.from, label: item.from };
      uniqueNodes[item.to] = { id: item.to, label: item.to };


      edgesData.push({
        from: item.from,
        to: item.to,
        label: item.label,
        font: { align: 'middle' },
        arrows: 'to'
      });
    });

    const nodes = new DataSet(Object.values(uniqueNodes));
    const edges = new DataSet(edgesData);

    const container = document.getElementById('network');

    const networkData = {
      nodes: nodes,
      edges: edges,
    };

    const options = {
      nodes: {
        shape: 'box',
      },
      edges: {
        width: 2,
      },
      layout: {
        improvedLayout: true,
      },
      physics: {
        enabled: false,
        barnesHut: {
          avoidOverlap: 0.5,
        },
      },
    };

    new Network(container, networkData, options);
  }, [chart_list]);

  const goFullScreen = () => {
      requestFullScreen(networkContainerRef.current);
  };


  return (
    <div className='p-2 justify-content-end bgCustomGray'>
      <div className='bgCustomGray' ref={networkContainerRef} id="network" style={{ width: "100%", height: "400px" }} data-testid="network-graph-div"></div>
      <IconButton name='Fullscreen' ariaLabel='Fullscreen'  className="" iconProps={{ iconName: 'FullScreen' }} onClick={goFullScreen}/>
    </div>
  );
}




export default NetworkGraph;
