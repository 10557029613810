// Template for Question and Answer tasks

// Import Dependencies
import {
  ActionButton,
  DefaultButton,
  Dropdown,
  FontIcon,
  Modal,
  Stack,
  TextField,
} from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import { React, useEffect, useRef, useState } from "react";
import { postRunTask } from "../Api/Api";
import { convertFileToBase64, writeToClipboard } from "../Utils/Utils";

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import CloseButtonDialog from "../Components/CloseButtonDialog";
import CustomFileChooser from "../Components/GptTasks/CustomFileChooser";
import LoadingSpinner from "../Components/LoadingSpinner";
import ChatOutput from "../Components/QAApps/ChatOutput";
import StartOverButton from "../Components/StartOverButton";

const DEFAULT_MODEL_OPTIONS = [{ key: "gpt-35-turbo-16k", text: "gpt-35-turbo-16k" }, { key: "gpt-4-turbo", text: "gpt-4-turbo" }, { key: "gpt-4-vision", text: "gpt-4-vision (preview)" }]

const QAApps = (props) => {
  const chatPanelDiv = useRef(null);
  const inputImageRef = useRef(null);
  const inputFileRef = useRef(null);
  const streamChatDivRef = useRef(null);

  // Props treatment
  const application = props.application;
  const modelOptions = application.model_options && application.model_options.length > 0 ? application.model_options : DEFAULT_MODEL_OPTIONS;
  const isChatStream = application.endpoint === "chat_stream";
  const enableFileSearch = application.answerType !== "byod";

  // Constants
  const [dialogHidden, setDiaglogHidden] = useState(true);
  const [dialogText, setDialogText] = useState(true);
  const [localHistory, setLocalHistory] = useState([]);
  const [questionValue, setQuestionValue] = useState("");
  const [custom_fields, setCustomFields] = useState(
    application.custom_fields
  );
  const [model, setModel] = useState(modelOptions[0]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageObject, setCurrentImageObject] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [isImageOpen, { setTrue: showImageModal, setFalse: hideImageModal }] = useBoolean(false);
  const [abortController, setAbortController] = useState(new AbortController());


  const isLoading = () => props.loadingState !== "";

  /* TEMPLATE FUNCTIONS */

  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }

  // Create question and adds it to the chat history
  function createQuestion() {
    if (questionValue !== "") {
      props.setLoadingState("loading");
      const localQuestion = { user: questionValue };
      const updatedHistory = [...localHistory, localQuestion];
      setQuestionValue("");
      setLocalHistory(updatedHistory);
    } else {
      showDialog("Please type a question.");
    }
  }

  useEffect(() => {
    return () => {
      resetFunction();
    }
  }, []);

  // On localHistory change
  useEffect(() => {
    if (localHistory.length > 0 && !localHistory[localHistory.length - 1].hasOwnProperty("bot")) {
      const fileArray = [];

      // Create an array of parameters to pass to the API
      const apiParameters = {};

      // IF CUSTOMFIELTYPE IS FILE NOT ADD TO APIPARAMETERS
      if (application.custom_fields !== undefined) {
        application.custom_fields.forEach((item) => {
          if (item.type === "file" && item.userSelectedValue !== undefined) {
            fileArray.push(item.userSelectedValue);
          }
        });
      } else if (currentImage) {
        fileArray.push(currentImage);
      } else if (currentFile) {
        fileArray.push(currentFile);
      }

      const promiseConversion = fileArray.map(async (file) => {
        const base64 = await convertFileToBase64(file);
        return { filename: file.name, base64File: base64, type: file.type };
      });

      Promise.all(promiseConversion).then(async (results) => {
        // Check if the last question was asked by the user, if so, call the API
        apiParameters["files"] = results;
        apiParameters["chat_history"] = localHistory;
        // index of resource
        apiParameters["data_source"] = application.data_source;
        // search resource name
        apiParameters["search_resource"] = application.search_resource;
        if (isChatStream) apiParameters["model"] = model.key || application.model;

        // Body of the API call
        const apiCallBody = {
          task: application.task,
          parameters: apiParameters,
        };

        try {
          if (isChatStream) {
            const response = await postRunTask(application.endpoint, apiCallBody, true, abortController.signal);
            if (response?.body) {
              const reader = response.body.getReader();
              let chunkAcc = "";
              let messageAcc = "";

              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const decodedValue = new TextDecoder("utf-8").decode(value);
                if (response.status !== 200) throw JSON.parse(decodedValue);
                const chunks = decodedValue.split("\n");
                ({ chunkAcc, messageAcc } = processChunks(chunks, chunkAcc, messageAcc));
              }
            }
          } else {
            // Call the API
            const data = await postRunTask(application.endpoint, apiCallBody);
            // Update the last question with the answer from the API

            if (data.category && data.category === "exception") {
              showDialog(props.application.api_error_message);
            } else if (data.category && data.category !== "exception") {
              showDialog(props.application.api_error_message);
            } else if (data.category && data.category !== "unhandled_exception") {
              showDialog(props.application.api_error_message);
            } else {
              if (data.generated_text.includes("The request is blocked.")) {
                const localHistoryCopy = localHistory.slice(0, -1);
                setLocalHistory(localHistoryCopy);
                showDialog(
                  "Your message contains some characters that are not allowed and are being blocked by our policies. Please check your message and remove these characters before resubmitting."
                );
              } else {
                const updatedHistory = [...localHistory];
                const lastQuestion = updatedHistory[updatedHistory.length - 1];
                const updatedQuestion = {
                  ...lastQuestion,
                  bot: { content: data.generated_text },
                };
                updatedHistory[updatedHistory.length - 1] = updatedQuestion;
                setLocalHistory(updatedHistory);
              }
            }
          }
        } catch (error) {
          if (error && typeof error === "object" && error.name !== "AbortError")
            showDialog(error.message && error.message.includes("reduce the length")
              ?
              error.message :
              props.application.api_error_message);
        } finally {
          props.setLoadingState("");
        }

        function updateCitations(citations) {
          setLocalHistory((prev) => {
            // Get last item in history and check if it's a bot message
            const lastItem = prev[prev.length - 1];

            // if it's a bot message, append the message.content to the last item
            if (lastItem.bot !== undefined) {
              lastItem.bot.citations = citations;
              return [...prev];
            } else {
              // if it's not a bot message, create a new item in the history
              const newItem = { bot: { citations: citations } || "" };
              return [...prev, newItem];
            }
          });
        }

        function updateBotMessage(message) {
          setLocalHistory((prev) => {
            // Get last item in history and check if it's a bot message
            const lastItem = prev[prev.length - 1];

            // if it's a bot message, append the message.content to the last item
            if (lastItem.bot !== undefined) {
              lastItem.bot.content = message;
              return [...prev];
            } else {
              // if it's not a bot message, create a new item in the history
              const newItem = { bot: { content: message } || "" };
              return [...prev, newItem];
            }
          });
        }

        function processChunks(chunks, chunkAcc, messageAcc) {
          chunks.forEach(chunk => {
            chunkAcc += chunk;
            const result = getJsonFromText(chunkAcc);
            if (result) {
              if (result.error)
                throw new Error(result.error);
              else if (result.choices && !result.choices[0].finish_reason) {
                const content = result.choices[0].message.content
                const role = result.choices[0].message.role
                switch (role) {
                  case null:
                    messageAcc += content
                    break;
                  case "tool":
                    updateCitations(JSON.parse(content).citations);
                    break;
                  default:
                    break;
                }
                if (!role)
                  if (messageAcc.length > 0)
                    updateBotMessage(messageAcc);
                chunkAcc = "";
              }
            }
          }, chunkAcc);
          return { chunkAcc, messageAcc };
        }

        function getJsonFromText(text) {
          try {
            return JSON.parse(text);
          } catch (err) {
            return null;
          }
        }
      });
    }
    if (streamChatDivRef.current) {
      streamChatDivRef.current.scrollTop = streamChatDivRef.current.scrollHeight;
    }
  }, [localHistory]);

  useEffect(() => {
    if (!currentFile && inputFileRef && inputFileRef.current && inputFileRef.current.value !== null)
      inputFileRef.current.value = null;
  }, [currentFile]);

  useEffect(() => {
    if (!currentImage && inputImageRef && inputImageRef.current && currentImageObject && inputImageRef.current.value !== null) {
      inputImageRef.current.value = null;
      URL.revokeObjectURL(currentImageObject);
      setCurrentImageObject(null);
    }
    else if (currentImage && !currentImageObject) {
      setCurrentImageObject(URL.createObjectURL(currentImage));
    }
  }, [currentImage, currentImageObject]);

  // On any form item change, update the state of the corresponding variable
  const fieldUpdateHandler = (event, field) => {
    switch (field) {
      case "questionValue":
        setQuestionValue(event.target.value);
        break;
      default:
        // Handle other possible values for field
        break;
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !/^\s*$/.test(questionValue)
    ) {
      createQuestion();
    } else if (
      event.key === "Enter" &&
      !event.shiftKey &&
      /^\s*$/.test(questionValue)
    ) {
      event.preventDefault();
    }
  };

  // Create components for custom fields, based in JSON dictionary
  function createCustomFields() {
    var custom_field_list = [];
    var generalClassOptions = "col-12 d-flex mt-2 mb-2 align-items-center ";

    // If there's only custom field, then it will be full width
    if (custom_fields.length > 1) {
      generalClassOptions += "col-md-6";
    }

    var conditionalClassOptions = "";

    custom_fields.map((custom_field, index) => {
      if (index % 2 === 0) {
        conditionalClassOptions = "ps-0 pe-0 ps-md-0 pe-md-5";
      } else {
        conditionalClassOptions = "ps-0 pe-0  ps-md-2 pe-md-0";
      }

      // Create component based on custom field type
      switch (custom_field.type) {
        case "file":
          custom_field_list.push(
            <div
              className={generalClassOptions + " " + conditionalClassOptions}
            >
              <CustomFileChooser
                showDialog={showDialog}
                fileMaxSize={props.fileMaxSize}
                fieldProps={custom_field}
                fieldData={{}}
                setValue={(fieldState) => {
                  if (fieldState.value && fieldState.value.length > 0)
                    custom_field["userSelectedValue"] = fieldState.value[0];
                }}
                clearError={{}}
              />
            </div>
          );
          break;
        default:
          return <></>;
      }
    });

    return custom_field_list;
  }

  const copyToClipboard = (content, message, isShowDialog) => {
    try {
      if (localHistory.length === 0) {
        showDialog("Can't copy an empty output.");
      } else {
        writeToClipboard(content);
        if (isShowDialog) {
          showDialog(message);
        }
      }
    } catch (err) {
      showDialog("An error occured while copying to clipboard.");
    }
  };


  const resetFunction = () => {
    if (abortController) setAbortController(prev => {
      if (prev) prev.abort();
      return new AbortController();
    });
    props.setLoadingState("");
    setLocalHistory([]);
    setQuestionValue("");
    setCurrentImage(null);
    setCurrentFile(null);
  };

  function truncateFilename(filename) {
    const maxLength = 10;

    if (filename.length <= maxLength + 3) {
      return filename;
    } else {
      const truncatedName = filename.substr(0, maxLength - 3) + '...';
      const fileExtension = filename.split('.').pop();
      return truncatedName + fileExtension;
    }
  }

  // Render the component
  return (
    <>
      {/* App Header */}
      <ApplicationHeader application={application} />

      {/* Custom Controls Area */}
      {custom_fields && custom_fields.length > 0 ? (
        <>
          <div className="col-12 mt-1 mb-2">
            <div className="taskDivider"> </div>
          </div>
          <div className="row m-0 mt-0 p-0 d-flex ">{createCustomFields()}</div>
        </>
      ) : null}

      <div className="col-12 mt-1 mb-3">
        <div className="taskDivider"> </div>
      </div>

      <div
        ref={isChatStream ? streamChatDivRef : null}
        className="row m-0 d-flex p-0 flex-grow-1 d-flex"
        style={isChatStream ? { overflowY: "auto", maxHeight: "calc(100vh - 430px)" } : {}}
      >
        <div
          ref={chatPanelDiv}
          className={`col-12 col-md-6 p-0 mb-3 flex-grow-1 position-relative ${localHistory.length === 0 ? "noChatHighlight" : ""
            }`}
        >
          {/* Iterates chat history to list messages between user and bot */}
          {localHistory.map((item, index) => (
            <div key={index}>
              {item.user && <div className="col-12 bg-white mb-2 d-flex chatQuestion">
                <p className="m-0 p-0 fw-bold">{item.user}</p>
                {props.loadingState === "loading" &&
                  index === localHistory.length - 1 && (
                    <LoadingSpinner spinnerSize={"small"} />
                  )}
              </div>}
              {item.hasOwnProperty("bot") ? (
                <ChatOutput
                  index={index}
                  bot={item.bot.content}
                  citations={item.bot.citations}
                  application={application}
                  loadingState={props.loadingState}
                  setLoadingState={props.setLoadingState}
                  showDialog={showDialog}
                />
              ) : null}
            </div>
          ))}

          {localHistory.length === 0 && props.loadingState === "" && (
            <div className="welcomeMessage d-none d-md-flex col-10 col-md-5 col-lg-7 col-xl-8 col-xxl-5 p-3 d-flex d-flex align-items-center justify-content-center">
              <div className="col-3 d-none d-md-flex justify-content-center p-3">
                <FontIcon
                  iconName="Rocket"
                  className="pe-2"
                  style={{ fontSize: "50px" }}
                />
              </div>

              <div className="col-12 col-md-9 p-1 p-md-3">
                <p className="mb-2">
                  <strong>Start chatting</strong>
                </p>
                <p className="m-0 p-0">
                  Welcome to the {application.cardTitle} app.
                </p>
                <p className="m-0 p-0">{application.startPrompt || 'Please type your question below.'} </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-0 p-0 d-flex m-0">
        <div className="col-12 p-0 pb-3 d-flex align-items-center position-relative">
          <TextField
            aria-label="Chat Question background"
            className="w-100 position-absolute"
            style={{ height: "100px" }}
            styles={{
              root: {
                zIndex: -1
              }
            }}
            multiline
            readOnly
          ></TextField>
          <Stack
            horizontal={true}
            className="buttonGroupCorpChat"
          >
            <Stack.Item grow={1} >
              <TextField
                data-testid="qa-question-textarea"
                ariaLabel="Chat Question"
                readOnly={isLoading()}
                style={{
                  height: "100px",
                }}
                styles={{
                  root: {
                    margin: 1
                  }
                }}
                value={questionValue}
                onChange={(event) => fieldUpdateHandler(event, "questionValue")}
                onKeyDown={handleKeyDown}
                multiline
                borderless
              ></TextField>
            </Stack.Item>
            {
              isImageOpen &&
              <Modal
                isOpen={isImageOpen}
                onDismiss={hideImageModal}
                isBlocking={false}
                styles={{
                  main: {
                    minWidth: 0,
                    minHeight: 0,
                  },
                }}
              >
                <img
                  className="imageCorpChatModal"
                  src={currentImageObject}
                  alt="selected file"
                />
              </Modal>
            }
            {
              currentImage &&
              <Stack.Item className="itemCorpChatImage" >
                <div className="d-flex justify-content-end">
                  <img
                    className="imageCorpChat"
                    src={currentImageObject}
                    alt="selected file"
                    onClick={() => showImageModal()}
                  />
                  <FontIcon
                    iconName="BoxMultiplySolid"
                    className="removeImageCorpChat"
                    onClick={() => setCurrentImage(null)}
                  />
                </div>
              </Stack.Item>
            }
            {
              isChatStream && enableFileSearch &&
              model.key === "gpt-4-vision" &&
              <Stack.Item className="buttonCorpChatItem">
                <DefaultButton
                  data-testid="qa-camera-upload"
                  ariaLabel="camera"
                  disabled={isLoading()}
                  className="buttonCorpChat p-0"
                  iconProps={{ iconName: "camera" }}
                  onClick={() => inputImageRef.current.click()}
                />
                <input
                  type="file"
                  id="file"
                  ref={inputImageRef}
                  className="d-none"
                  accept="image/*"
                  onChange={event => {
                    event.target.files[0] && setCurrentImageObject(() => setCurrentImage(event.target.files[0]));
                  }}
                />
              </Stack.Item>
            }
            {
              isChatStream && enableFileSearch &&
              model.key !== "gpt-4-vision" &&
              <Stack.Item className={`buttonCorpChatItem${!currentFile ? "" : "WithFile"}`}>
                <div className="d-flex flex-column">
                  <DefaultButton
                    data-testid="qa-file-upload"
                    ariaLabel="OpenFile"
                    disabled={isLoading()}
                    className="buttonCorpChat align-self-center p-0"
                    iconProps={{ iconName: "OpenFile" }}
                    onClick={() => inputFileRef.current.click()}
                  />
                  {
                    currentFile &&
                    <>
                      <p className="align-self-center m-1 p-0 fs-lg">
                        {truncateFilename(currentFile.name)}
                      </p>
                      <FontIcon
                        className="position-absolute"
                        iconName="BoxMultiplySolid"
                        onClick={() => setCurrentFile(null)}
                      />
                    </>
                  }
                  <input
                    type="file"
                    id="file"
                    ref={inputFileRef}
                    className="d-none"
                    title="Upload document"
                    onChange={event => event.target.files[0] && setCurrentFile(event.target.files[0])}
                  />
                </div>
              </Stack.Item>
            }
            {
              isChatStream &&
              isLoading() &&
              <Stack.Item className="buttonCorpChatItem">
                <DefaultButton
                  data-testid="qa-stop-stream"
                  ariaLabel="stop"
                  disabled={props.loadingState === ""}
                  className="buttonCorpChat p-0"
                  iconProps={{ iconName: "stop" }}
                  onClick={() => setAbortController(prev => {
                    if (prev) prev.abort();
                    return new AbortController();
                  })}
                />
              </Stack.Item>
            }
            {
              props.loadingState === "" &&
              <Stack.Item className="buttonCorpChatItem">
                <DefaultButton
                  data-testid="qa-question-submit"
                  ariaLabel="send"
                  disabled={isLoading()}
                  className="buttonCorpChat p-0"
                  iconProps={{ iconName: "send" }}
                  onClick={createQuestion}
                />
              </Stack.Item>
            }
          </Stack>
        </div>
        <div className="col-12 m-0 p-0 d-flex">
          <ActionButton
            disabled={isLoading()}
            iconProps={{ iconName: "copy" }}
            text="Copy text"
            onClick={() =>
              copyToClipboard(
                chatPanelDiv.current.innerText,
                "Text copied.",
                true
              )
            }
          />
          <StartOverButton
            loadingState={props.loadingState}
            resetFunction={isChatStream ? resetFunction : null} />
          {
            isChatStream &&
            <Dropdown
              className="ddModelCorpChat"
              label="Model:"
              selectedKey={model.key}
              onChange={(_event, option) => {
                if (option.key === "gpt-4-vision") setCurrentFile(null);
                else setCurrentImage(null);
                setModel(option);
              }}
              disabled={modelOptions.length <= 1}
              options={modelOptions}
              styles={{
                dropdown: {
                  width: 200,
                },
                label: {
                  fontWeight: "regular",
                  marginRight: "8px",
                },
              }}
            />}
        </div>
      </div>

      {/* Dialog */}
      <CloseButtonDialog
        dialogHidden={dialogHidden}
        dialogText={dialogText}
        hideDialog={hideDialog}
      />
    </>
  );
};

export default QAApps;
