// Create a functional component called Dropdown

import {
  Callout,
  ComboBox,
  DirectionalHint,
  FontIcon,
  Icon,
  IconButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import { React, useContext, useEffect, useRef, useState } from "react";
import { postRunTask } from "../../Api/Api";
import { LoadingContext } from "../../Utils/LoadingManager";
import lodash from "lodash";

const CustomDropdown = (props) => {
  const defaultErrorMessage = "Please select an option";

  const { loading, loadingResponse, setLoadingState, isComponentLoading } =
    useContext(LoadingContext);

  const comboBoxRef = useRef(null);
  const fieldName = props.fieldProps.name;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [options, setOptions] = useState([]);

  const {
    isSuccess: promptSuccess,
    isLoading: promptLoading,
    isRefetching: promptRefetching,
    data: promptData,
    refetch,
  } = useQuery(
    [
      fieldName ?? "CustomDropdown",
      props.endpoint ?? "none",
      props.fieldData.addProperties ?? "none",
    ],
    async () => {
      const { fetch, options: fieldOptions } = props.fieldProps || {};
      const { endpoint, fieldData } = props;
      let optionList = fieldOptions || [];

      if (fetch) {
        const { requestTemplate, requestOptions, responseOptions } = fetch;
        const addFromList = requestOptions?.addFrom || [];
        const addProperties = fieldData.addProperties || {};

        const missingFields = addFromList.filter(
          (addFrom) =>
            !(
              addFrom.targetProperty in addProperties &&
              addFrom.targetKey in addProperties[addFrom.targetProperty]
            )
        );

        if (missingFields.length === 0) {
          let request = JSON.parse(JSON.stringify(requestTemplate));
          Object.entries(addProperties).forEach(
            ([propertyKey, propertyValue]) => {
              if (propertyValue instanceof Object) {
                request[propertyKey] = {
                  ...request[propertyKey],
                  ...propertyValue,
                };
              } else {
                request[propertyKey] = propertyValue;
              }
            }
          );

          const dataKey = responseOptions?.dataKey || "data";
          try {
            const response = await postRunTask(endpoint, request);
            const data = response[dataKey] || {};
            const optionKey = responseOptions?.optionKey || "key";
            const optionText = responseOptions?.optionText || "text";
            const optionData = responseOptions?.optionData || "data";
            if (data instanceof Array) {
              optionList = data.map((e) => ({
                key: e[optionKey] || e[optionText],
                text: e[optionText],
                data: e[optionData],
              }));
            } else if (Object.keys(data).length > 0) {
              optionList = data.map((e) => ({
                key: e[optionKey] || e[optionText],
                text: e[optionText],
                data: e[optionData],
              }));
            }
          } catch (error) {
            console.error(error);
          }
        }
      }

      const options = optionList.map((option, index) => ({
        key: option.key ?? index,
        text: option.text ?? option,
        data: option.data,
        styles: {
          optionText: {
            whiteSpace: "normal",
            overflow: "visible",
          },
        },
      }));

      return options;
    }
  );

  useEffect(() => {
    if (props.fieldData.dependencyChanged) {
      props.setValue({
        field: fieldName,
        data: null,
        key: null,
        value: null,
        operation: null,
        refetch: null,
      });
    }
  }, [props.fieldData.dependencyChanged]);

  useEffect(() => {
    if (promptSuccess && !lodash.isEqual(promptData, options)) {
      setOptions(promptData);
    }
  }, [promptData, promptSuccess]);

  useEffect(() => {
    if (isComponentLoading(fieldName) !== (promptLoading || promptRefetching)) {
      setLoadingState(fieldName, promptLoading || promptRefetching);
    }
  }, [promptLoading, promptRefetching]);

  useEffect(() => {
    if (props.fieldData.refetch) {
      props.setValue({
        field: fieldName,
        value: props.fieldData.userSelectedValue,
        data: props.fieldData.userSelectedData,
        key: props.fieldData.userSelectedKey,
        operation: "update",
        refetch: false,
      });

      refetch();
    }
  }, [props.fieldData.refetch]);

  useEffect(() => {
    if (props.fieldData.hasError) {
      setErrorMessage(props.fieldData.errorMessage ?? defaultErrorMessage);
    } else {
      setErrorMessage(null);
    }
  }, [props.fieldData.errorMessage, props.fieldData.hasError, props]);

  // On any form item change, update the state of the corresponding variable
  const fieldUpdateHandler = ({ event, option, key, value }) => {
    const data = option?.data ?? (value ? "" : undefined);
    const operation = option ? "update" : "add";
    props.setValue({
      field: fieldName,
      key,
      value,
      data,
      operation,
    });
  };

  const onIconClick = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };


  const deleteOption = async (option) => {
    comboBoxRef.current.dismissMenu();

    const request = { ...props.fieldProps.delete.requestTemplate };
    if (props.fieldData.addProperties && props.fieldData.addProperties.parameters.prompt_type)
      request.parameters.prompt_type = props.fieldData.addProperties.parameters.prompt_type;
    else if (fieldName === "prompt_type")
      request.parameters.prompt_type = option.key;
    request.parameters.prompt_field_name = fieldName;
    request.parameters.prompt_field_value = option.key;

    const response = await postRunTask(props.endpoint, request);
    if (response) {
      const data = response.data || [];
      if (data instanceof Array && (data.length === 0 || !data.some(d => d[fieldName] === option.key))) {
        if (props.fieldData.userSelectedValue === option.key) props.fieldData.dependencyChanged = true;
        refetch();
      }
    }
  }

  const comboBox = (
    <div className="w-100 h-100 d-flex align-items-end">
      <ComboBox
        componentRef={comboBoxRef}
        name={fieldName}
        className="w-100"
        label={props.fieldProps.label}
        allowFreeform={props.fieldProps.allowFreeform === "true"}
        autoComplete={props.fieldProps.autoComplete === "true" ? "on" : "off"}
        options={options}
        placeholder={props.fieldProps.placeholder}
        required={props.fieldProps.required === "yes"}
        onChange={
          props.mocked
            ? null
            : (event, option, index, value) =>
              fieldUpdateHandler({ event, option, key: index, value })
        }
        errorMessage={props.mocked ? null : errorMessage}
        disabled={
          props?.fieldData?.disabled ||
          (props.mocked ? false : loading || loadingResponse)
        }
        useComboBoxAsMenuWidth={true}
        calloutProps={{
          calloutMaxHeight: props.fieldProps.calloutMaxHeight,
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        text={
          props.fieldData?.disabled ? "" : props.fieldData.userSelectedValue
        }
        selectedKey={
          props.fieldData?.disabled ? "" : props.fieldData.userSelectedValue
        }
        comboBoxOptionStyles={{
          flexContainer: {
            display: "unset",
          },
        }}
        styles={{
          callout: {
          }
        }}
        onRenderOption={props.fieldProps.delete ? (option) => (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <span>{option.text}</span>
            <FontIcon
              iconName="Delete"
              title="Delete"
              ariaLabel="Delete"

              style={{
                cursor: 'pointer',
                padding: '5px',
                color: 'rgb(0, 120, 212)',
                borderRadius: '2px',
                fontSize: '16px',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#c8c8c8";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
              onClick={async (e) => {
                e.preventDefault();
                const confirmation = window.confirm(`Are you sure you want to delete '${props.fieldProps.label}: ${option.text}'?`);
                if (confirmation) {
                  e.stopPropagation();
                  setLoadingState(fieldName, true);
                  deleteOption(option);
                }
              }}
            />
          </div>
        ) : undefined}
      />
      {props.fieldData.userSelectedData && (
        <div
          className="d-flex align-items-end ml-2 ms-2 mb-1"
        >
          <Icon
            iconName="Info"
            id={`${fieldName}info`}
            aria-label={`${fieldName} info}`}
            onClick={onIconClick}
            onMouseEnter={() => setIsCalloutVisible(true)}
            style={{ cursor: "pointer", color: "#0078d4" }}
          />
          {isCalloutVisible && (
            <Callout
              target={`#${fieldName}info`}
              directionalHint={DirectionalHint.bottomCenter}
              onDismiss={() => setIsCalloutVisible(false)}
              onMouseLeave={() => setIsCalloutVisible(false)}
              style={{
                width: 400,
                maxHeight: 400,
                padding: "20px 24px",
                fontSize: "small",
              }}
            >
              <div>{props.fieldData.userSelectedData}</div>
            </Callout>
          )}
        </div>
      )}
      <Spinner
        role="spinbutton"
        style={{
          alignSelf: "end",
          padding: "5px",
          display: !isComponentLoading(fieldName)
            ? "none"
            : "block",
        }}
        size={SpinnerSize.large}
      />
    </div>
  );

  const comboBoxControl = props.mocked ? (
    <div className="d-flex">
      <IconButton
        role="move-up-button"
        iconProps={{ iconName: "ChevronLeft" }}
        title="Move it Left"
        ariaLabel="Move it Left"
        onClick={() => props.moveCustomField(props.index, "up")}
      />
      <IconButton
        role="move-down-button"
        iconProps={{ iconName: "ChevronRight" }}
        title="Move it Right"
        ariaLabel="Move it ight"
        onClick={() => props.moveCustomField(props.index, "down")}
      />
      <IconButton
        className="me-2"
        iconProps={{ iconName: "Edit" }}
        title="Edit"
        ariaLabel="Edit"
        onClick={() => props.editCustomField(props.fieldProps, props.index)}
      />
      <IconButton
        role="delete-button"
        iconProps={{ iconName: "Delete" }}
        title="Delete"
        ariaLabel="Delete"
        onClick={() => props.removeCustomField(props.index)}
      />
      {comboBox}
    </div>
  ) : (
    comboBox
  );

  return comboBoxControl;
};

export default CustomDropdown;

