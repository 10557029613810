// This component is used to display the header of the application page.

// Import Dependencies
import {React, useState, useEffect} from 'react';
import { TextField } from '@fluentui/react';

const AdminCenterFormTextField = ({fieldUpdateHandler, field,  value, label, placeholder, isRequired, isValidate, isMultiline,  defaultErrorMessage, maxLength}) => {
    
    const [errorMessage, setErrorMessage] = useState('');
    const [multiline, setMultiline] = useState(false);

    useEffect(() => {
        if(defaultErrorMessage){
            setErrorMessage(defaultErrorMessage);
        }else{
            setErrorMessage('');
        }

    },[defaultErrorMessage]);

    useEffect(() => {
        if(isMultiline){
            setMultiline(true);
        }
    }, []);

    return (
        <div className="col-12 mb-4">
            <TextField maxLength={maxLength} multiline={multiline} errorMessage={errorMessage} value={value} onChange={(event) => fieldUpdateHandler(event, field)} label={label} placeholder={placeholder}/>
        </div>
    )

};

export default AdminCenterFormTextField;