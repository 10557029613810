import waffleMenu from "../assets/images/v3/waffleMenu.png";
import { Link } from "react-router-dom";
import { ContextualMenu, IconButton } from "@fluentui/react";
import { useQueryClient } from "@tanstack/react-query";
import { refreshToken, logout } from "../Api/Api";
import { useState, useCallback, useRef } from "react";
import Sidebar from "./Sidebar";

const Header = ({
  user_name,
  current_dict,
  isWaffleMenuOpen,
  setIsWaffleMenuOpen,
}) => {
  const userNameText = user_name
    ?.split(" ", 2)
    .join(" ")
    .substring(0, 50)
    .concat(user_name?.length > 50 ? "..." : "");
  const queryClient = useQueryClient();

  // Contextual menu for the add custom field button
  const mobileMenuProps = {
    ariaLabel: "Mobile menu",
    items: [
      {
        key: "userName",
        text: user_name,
        iconProps: { iconName: "Contact" },
        style: { fontWeight: "600" },
      },
      {
        key: "refreshToken",
        text: "Refresh Token",
        iconProps: { iconName: "UserSync" },
        onClick: () => refreshToken(queryClient),
      },
      {
        key: "userLogout",
        text: "Logout",
        iconProps: { iconName: "SignOut" },
        onClick: () => logout(),
      },
    ],
  };

  function getMobileMenu(props) {
    return <ContextualMenu {...props} />;
  }

  function toggleWaffleMenu() {
    if (isWaffleMenuOpen) {
      setIsWaffleMenuOpen(false);
    } else {
      setIsWaffleMenuOpen(true);
    }
  }

  const handleResize = () => {
    setIsWaffleMenuOpen(window.innerWidth >= 1200);
  };
  window.addEventListener("resize", handleResize);
  const openLink = (href, target) => {
    window.open(href, target);
  };

  const userMenuItems = [
    {
      ariaLabel: "User menu refresh token",
      key: "refreshToken",
      text: "Refresh Token",
      iconProps: { iconName: "UserSync" },
      onClick: () => refreshToken(queryClient),
    },
    {
      ariaLabel: "User menu logout",
      key: "logout",
      text: "Logout",
      iconProps: { iconName: "SignOut" },
      onClick: () => logout(),
    },
  ];

  const linkRef = useRef(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const onShowUserMenu = useCallback((ev) => {
    ev.preventDefault(); // don't navigate
    setShowUserMenu(true);
  }, []);
  const onHideUserMenu = useCallback(() => setShowUserMenu(false), []);

  const userMenuStyle = {
    verticalAlign: "middle",
    marginLeft: "5px",
    fontWeight: "bold",
    fontSize: "8px",
    fontStyle: "normal",
    fontFamily: "FabricMDL2Icons",
    cursor: "pointer",
  };
  return (
    <>
      <header id="header">
        <div className="container-fluid">
          <div className="row m-0 p-0 header d-flex">
            <div className="col-auto m-0 ps-3 pt-1 pt-lg-0">
              <img
                id="waffleMenu"
                src={waffleMenu}
                alt="menu"
                onClick={toggleWaffleMenu}
                className="d-inline"
              />
              <a href="../" className="headerLink">
                IHME SysReview Copilot
              </a>
              <span className="previewPill">beta</span>
            </div>
            <div className="col-auto m-0 ms-auto pe-3 d-none d-lg-flex">
              {userNameText ? (
                <>
                  <span className="rightMenuElement d-none d-md-inline">
                    <a
                      aria-label="User menu button"
                      onClick={onShowUserMenu}
                      href="#"
                    >
                      <span>Welcome {userNameText}</span>
                    </a>
                    <i
                      ref={linkRef}
                      aria-hidden="true"
                      style={userMenuStyle}
                      onClick={onShowUserMenu}
                    >
                      
                    </i>
                  </span>
                  <ContextualMenu
                    ariaLabel="User menu"
                    items={userMenuItems}
                    hidden={!showUserMenu}
                    target={linkRef}
                    onItemClick={onHideUserMenu}
                    onDismiss={onHideUserMenu}
                  />

                  <span className="rightMenuSeparator"> </span>
                </>
              ) : null}
            </div>
            <div className="col-auto m-0 ms-auto d-flex d-lg-none">
              <IconButton
                iconProps={{ iconName: "GlobalNavButton" }}
                menuProps={mobileMenuProps}
                menuAs={getMobileMenu}
                allowDisabledFocus
                className="headerContextualMenuButton"
                aria-label="Header Contextual Menu"
                style={{ paddingRight: "27px" }}
              />
            </div>
          </div>
        </div>
      </header>
      <Sidebar
        key={"Sidebar"}
        sidebarState={isWaffleMenuOpen ? "" : "sidebar-collapse"}
        current_dict={current_dict}
      />
    </>
  );
};

export default Header;
