// Create a functional component called Dropdown

import React from 'react';
import { TextField, IconButton } from '@fluentui/react';

const CustomTextFieldMockup = (props) => {

    return (
        <div className='d-flex'>
            <IconButton iconProps={{ iconName: 'ChevronLeft' }} title="Move it Left" ariaLabel="Move it Left" onClick={() => props.moveCustomField(props.index, "up")} />
            <IconButton iconProps={{ iconName: 'ChevronRight' }} title="Move it ight" ariaLabel="Move it ight" onClick={() => props.moveCustomField(props.index, "down")} />
            <IconButton className='me-2' iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => props.editCustomField(props.custom_field, props.index)} />
            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
            <TextField
                name={props.custom_field.name}
                aria-label={props.custom_field.label}
                placeholder={props.custom_field.placeholder}
                className='w-100'
            />
        </div>
    )
}

export default CustomTextFieldMockup;