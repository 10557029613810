// Import Dependencies
import { React, useEffect, useState, useContext } from "react";
import { postRunTaskMultiPartForm, postRunTask } from "../../Api/Api";
import { LoadingContext } from "../../Utils/LoadingManager";
import { Shimmer } from "@fluentui/react";

const MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE = {
  APIRESULTS: null,
  APIPARAMETERS: null,
  FETCHDATA: false,
  FIELDDATA: null,
  INITCOMPONENT: false,
  RESETREQUEST: false,
  STATUSRESULTS: null,
};

const MediaTransDefaultOutput = (props) => {

  const { loadingResponse, setLoadingResponseState } =
    useContext(LoadingContext);
  // API Call
  const [apiResults, setApiResults] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.APIRESULTS
  );
  const [apiParameters, setApiParameters] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.APIPARAMETERS
  );
  const [fetchData, setFetchData] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.FETCHDATA
  );
  const [fieldData, setFieldData] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.FIELDDATA
  );
  const [initComponent, setInitComponent] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.INITCOMPONENT
  );
  const [resetRequest, setResetRequest] = useState(
    MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.RESETREQUEST
  );

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);
      setApiResults(MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.APIRESULTS);
      setFieldData(props.fieldData);
      setResetRequest(MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.RESETREQUEST);
    }
  }, [initComponent, props, resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (resetRequest) {
      setResetRequest(MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.RESETREQUEST);
      setFetchData(MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.FETCHDATA);
      setApiParameters(MEDIATRANS_DEFAULT_STATE_OUTPUT_DEFAULT_STATE.APIPARAMETERS);
      setLoadingResponseState("MediaTransOutput", false);
    }
  }, [resetRequest, setLoadingResponseState]);

  // IF CUSTOMFIELTYPE IS FILE NOT ADD TO APIPARAMETERS
  useEffect(() => {
    if (fieldData) {
      const _apiParameters = {};
      let fileArray = [];
      let errors = [];
      Object.values(fieldData).forEach((value) => {
        const field = value;
        if (
          !(
            field?.data &&
            (field.data?.userSelectedValue || field.data.userSelectedData)
          )
        ) {
          _apiParameters[field.props.name] = "";
          if (field.props.required === "yes") {
            errors.push(field.props.name);
          }
        } else if (field.props.type === "file") {
          if (field.data.userSelectedValue instanceof FileList) {
            fileArray = Array.from(field.data.userSelectedValue);
          } else {
            fileArray.push(field.data.userSelectedValue);
          }

        } else {
          _apiParameters[field.props.name] =
            field.data.userSelectedValue || field.data.userSelectedData;
        }
      });

      if (errors.length > 0) {
        props.setErrors(errors);
        props.showDialog("Please fill all the required fields.");
        setInitComponent(false);
      } else {
        _apiParameters["sync"] = false;

        if (props.leftPanelInputValue)
          _apiParameters.prompt = props.leftPanelInputValue;

        if (fileArray.length > 0) {
          var formData = new FormData();
          formData.append(fileArray[0].name, fileArray[0]);
          formData.append('task', props.application.task);
          setApiParameters(formData);
          setFetchData(true);
        } else {

          const iterator = {
            video_url: [fieldData["video_url"].data.userSelectedValue],
          };

          _apiParameters.iterator = iterator;
          setApiParameters(_apiParameters);
          setFetchData(true);
        }
      }
    }
  }, [fieldData, props]);

  useEffect(() => {

    if (fetchData) {
      setFetchData(false);
      // Create the body of the API call

      setLoadingResponseState("MediaTransOutput", true);
      // Call the API
      try {
        if(apiParameters.iterator) {
          const apiCallBody = {
            task: props.application.task,
            parameters: apiParameters,
          };

          // Delete unused keys from the API call body
          delete apiCallBody.parameters.files;
          delete apiCallBody.parameters.video_url;

          postRunTask(props.application.endpoint, apiCallBody)
            .then(async (response) => {
              props.setStatusResults(false);
              props.showDialog("Your request has been submitted successfully.");
            })
            .catch((error) => {
              props.showDialog(
                "A problem has come up. We will investigate it. Please try again in a few minutes"
              );
            })
            .finally(() => {
              setResetRequest(true);
            });
        }else{
          
          props.showDialog("Uploading file. Please wait...")

          postRunTaskMultiPartForm(props.application.endpoint, apiParameters, false, null, props.application.task)
          .then(async (response) => {
            props.setStatusResults(false);
            props.showDialog("Your request has been submitted successfully.");
          })
          .catch((error) => {
            props.showDialog(
              "A problem has come up. We will investigate it. Please try again in a few minutes"
            );
          })
          .finally(() => {
            setResetRequest(true);
          });          
        }
      } catch (error) {
        props.showDialog(
          "A problem has come up. We will investigate it. Please try again in a few minutes"
        );
        props.setInitComponent(false);
      }
    }
  }, [apiParameters, fetchData, props, setLoadingResponseState]);

  // Component Render
  return loadingResponse && (
    <div className="ctable ctable-striped">
      <div className="cthead-dark">
        <div className="ctr">
          <div className="cth">
            <Shimmer />
          </div>
          <div className="cth">
            <Shimmer />
          </div>
        </div>
      </div>
      <div className="ctbody">
        {Array.from(Array(6).keys()).map((element, index) => {
          return (
            <div className="ctr" key={`${element}${index}`}>
              <div className="ctd">
                <Shimmer />
              </div>
              <div className="ctd">
                <Shimmer />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MediaTransDefaultOutput;
