// Template for Audio to Article task

// Import Dependencies,
import { React, useRef, useState } from "react";
import { Label, DefaultButton, ActionButton } from '@fluentui/react';
import parser from 'html-react-parser';
import { postRunTask } from '../Api/Api';
import { convertFileToBase64 } from '../Utils/Utils';

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import CloseButtonDialog from "../Components/CloseButtonDialog";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";
import FabricationLogger from "../Components/FabricationLogger";

const AudioToArticle = (props) => {
    const rightPanelDiv = useRef(null);

    // Props treatment
    const application = props.application;
    

    /* TEMPLATE FUNCTIONS */
    // Constants and variables
    const labelDefaultText = "Upload an audio file from your computer";
    const [fieldLabelOrFileName, setFieldLabelOrFileName] = useState(labelDefaultText);
    const inputFileRef = useRef(null);
    const [originalFile, setOriginalFile] = useState('');
    const [filename, setFilename] = useState('');
    const [transcription, setTranscription] = useState('');
    const [article, setArticle] = useState('');
    const [browseButtonText, setBrowseButtonText] = useState('Browse');
    const [hasResult, setHasResult] = useState(false);

    // Submits audio file to API, expecting a transcription in return
    const createTranscription = async () => {
        if (originalFile) {
            props.setLoadingState('loading');
            const base64File = await convertFileToBase64(originalFile);
            const apiCallBody = { task: props.application.task, parameters: { "files": [{ filename, base64File }] } };
            try {
                // Call the API
                const data= await postRunTask(props.application.endpoint,apiCallBody);
                props.setLoadingState('');

                if (data.recognition_status[0] === "Success") {
                    setTranscription(data.display_text[0].replace(/\n/g, "<br>"));
                } else {
                    showDialog(data.recognition_status[0]);
                }
            }catch(error){
                 showDialog(props.application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please select an audio file to upload.");
        }
    }

    
    // Submits the transcription  to the API, expecting an article in return
    const createArticle = async () => {
        if (transcription !== '') {
            setHasResult(false);
            props.setLoadingState('loading');

            // Create an array of parameters to pass to the API
            const apiParameters = {};
            apiParameters['prompt'] = transcription;
            const apiCallBody = { task: "news_article_generator", parameters: apiParameters };
            try {
                // Call the API
                const data= await postRunTask(props.application.endpoint,apiCallBody);

                props.setLoadingState('');
                setArticle(data.generated_text.replace(/\n/g, "<br>"));
                setHasResult(true);
            }catch(error){
                 showDialog(props.application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please select an audio file to upload, and press button to generate transcription.");
        }
    }

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }



    // Triggers the file selection dialog
    const handeFileSlection = () => {
        if (filename !== '') {
            setOriginalFile ('');
            setFilename ('');
            setBrowseButtonText('Browse');
            setFieldLabelOrFileName(labelDefaultText);
            inputFileRef.current.value = '';
        } else {
            inputFileRef.current.click();
        }
    };


    // On any form item change, update the state of the corresponding variable
    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case 'fileInputValue':
                const file = event.target.files[0];
                if (file) {
                    if (file.size > props.fileMaxSize) {
                        showDialog('File size is too large. Please upload a file smaller than ' + props.fileMaxSize / 1024 / 1024 + ' MB.');
                        inputFileRef.current.value = '';
                    } else {
                        setFieldLabelOrFileName(file.name);
                        setFilename(file.name);
                        setOriginalFile(file);
                        setBrowseButtonText('Clear');
                    }
                } else {
                    setFieldLabelOrFileName(labelDefaultText);
                    setOriginalFile('');
                    setFilename('');
                }
                break;
            // default: // Not required as field is hardcoded in line 172
            //     break;
        }
    }


    //***NOT IN USE PLEASE REMOVE IN CLEANUP*** Start over function, resets all fields

    // Render the template
    return (
        <>
            { /* App Header */}
            <ApplicationHeader application={application} />

            <div className="col-12 mt-1 mb-3">
                <div className="taskDivider"> </div>
            </div>

            { /* File Selection Area */}
            <div className="row m-0 mt-0 p-0 d-flex mb-3 mb-md-0">
                <div className="col-12 col-lg-8 mt-0 d-flex p-0">
                    <Label className='pe-3 text-size'>{fieldLabelOrFileName}</Label>
                    <DefaultButton
                        onClick={handeFileSlection}
                        text={browseButtonText}
                        disabled={props.loadingState !== ''}
                    />

                    { /* Hidden file input, used to handle file selection */}
                    <input
                        ref={inputFileRef}
                        type="file"
                        onChange={(event) => fieldUpdateHandler(event, "fileInputValue")}
                        accept={".wav"}
                        className='d-none'
                        data-testid="file-upload-input"
                    />
                </div>

            </div>

            <div className="col-12 mt-3 mb-3">
                <div className="taskDivider"> </div>
            </div>

            { /* Result Area */}
            <div className="row d-flex flex-grow-1 m-0 p-0">

                { /* Left Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3 ">
                    <div className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight">
                        <p>{parser(transcription)}</p>
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
                        <DefaultButton disabled={props.loadingState !== ''} className="w-100-responsive" text="1. Generate a transcription" onClick={createTranscription} />
                        {props.loadingState === "loading" && <LoadingSpinner />}
                    </div>
                </div>

                { /* Right Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                    <div ref={rightPanelDiv} className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight">
                        <p>{parser(article)}</p>
                    </div>
                    {hasResult === true && (
                            <FabricationLogger
                            application={application}
                            loadingState={props.loadingState}
                            setLoadingState={props.setLoadingState}
                            showDialog={showDialog}
                            />
                        )}                    
                    <div className="col-12  p-md-1 pt-2  mb-2 mt-0 panelFooterHeight">
                        <DefaultButton disabled={props.loadingState !== ''} className="me-3 w-100-responsive" text="2. Generate an article" onClick={createArticle} />
                        <CopyTextButton  text={rightPanelDiv} isShowDialog={true} message={"Text copied."} loadingState={props.loadingState}  />                    
                        <StartOverButton loadingState={props.loadingState}  />                        
                    </div>
                </div>                         
            </div>

            { /* Dialog */}
            <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    )
};

export default AudioToArticle;