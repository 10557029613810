// This component is used to display a diaglog box with a close button.

// Import Dependencies
import { React, useContext, useState } from "react";
import { ActionButton } from "@fluentui/react";
import CloseButtonDialog from "./CloseButtonDialog";
import { writeToClipboard } from "../Utils/Utils";
import { LoadingContext } from "../Utils/LoadingManager";

const CopyTextButton = ({ text, isShowDialog, message }) => {
  const { loading, loadingResponse } = useContext(LoadingContext);

  // Dialog Handle
  const [dialogHidden, setDiaglogHidden] = useState(true);
  const [dialogText, setDialogText] = useState(true);

  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }

  const copyToClipboard = () => {
    try {
      if (text.current && text.current.innerText !== "") {
        writeToClipboard(text.current.innerText);
        if (isShowDialog) {
          showDialog(message);
        }
      } else {
        showDialog("Can't copy an empty output.");
      }
    } catch (err) {
      showDialog("An error occured while copying to clipboard.");
    }
  };

  return (
    <>
      <ActionButton
        data-testid="copy-button"
        ariaLabel="Copy text to clipboard"
        disabled={loading || loadingResponse}
        iconProps={{ iconName: "copy" }}
        text="Copy text"
        onClick={() => copyToClipboard()}
      />
      <CloseButtonDialog
        dialogHidden={dialogHidden}
        dialogText={dialogText}
        hideDialog={hideDialog}
      />
    </>
  );
};

export default CopyTextButton;
