// This component is used to display the header of the application page.

// Import Dependencies
import parser from "html-react-parser";
import { React, useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

import MarkdownIt from "markdown-it";
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css";

import { ActionButton, DefaultButton } from "@fluentui/react";
import FabricationLogger from "../../Components/FabricationLogger";
import ChatOutputModal from "../GptOutput/ChatOutputModal";

const ChatOutput = (props) => {
    const id = `QAAppsMessage${props.index}`;
    const [botAnswer, setBotAnswer] = useState(null);
    const [stop, setStop] = useState(false);

    const [modalInfo, setModalInfo] = useState(["", ""]);

    const [referencesComponents, setReferencesComponents] = useState([]);

    const copyCode = useCallback((e) => {
        if (e.currentTarget.id === "copyButton") {
            const code = e.currentTarget.closest("pre").querySelector("code");
            navigator.clipboard.writeText(code.textContent);
            const toast = document.getElementById("copyToast");
            toast.classList.add("corpChatCodeCopyToastFadeIn");
            setTimeout(() => {
                toast.classList.remove("corpChatCodeCopyToastFadeIn");
            }, 500);
        }
    }, []);

    useEffect(() => {
        const md = new MarkdownIt({
            highlight: function (str, lang) {
                if (lang && !Prism.languages[lang]) {
                    try {
                        require("prismjs/components/prism-" + lang + ".js");
                    } catch (__) { }
                }
                const iconButton = <ActionButton
                    id="copyButton"
                    title="Copy to clipboard"
                    iconProps={{ iconName: "Copy" }}
                />;
                const iconHTML = ReactDOMServer.renderToString(iconButton);
                const wrapped =
                `<pre className={position-relative language-${lang}}>
                        <div class="corpChatCodeCopyDiv">
                            ${iconHTML}
                        </div>
                        <code className={language-${lang}}>
                            [code]
                        </code>
                    </pre>`;
                return wrapped.replace(/\s*\n\s*/g, "").replace("[code]", Prism.highlight(str, Prism.languages[lang] || Prism.languages.markup));
            },
        });
        const defaultTextRenderer = md.renderer.rules.text;
        md.renderer.rules.text = function (tokens, idx, options, env, self) {
            const renderedText = defaultTextRenderer(tokens, idx, options, env, self);
            const fixedText = renderedText.replace(/&lt;\[/g, "<[").replace(/\]&gt;/g, "]>");
            return fixedText;
        };

        function buildAnswer(answer) {
            const md_answer = md.render(answer);

            const links_regex = /<\[(.*?)\]>|\[doc\d+\]/g;
            const links = [...md_answer.matchAll(links_regex)].map((match) => match[1] || match[0]);


            let index = 1;
            let new_answer = md_answer.replace(links_regex, (_match, link) => {
                let link_number = index++;

                if (props.application.answerType === "files") {
                    return `<sup>[${link_number}]</sup> `;
                } else if (props.application.answerType === "byod") {
                    return `<sup>[${link_number}]</sup> `;
                } else {
                    return `<a href="${link}" target="_blank"><sup>[${link_number}]</sup></a> `;
                }
            });


            var answerText = "<div className='row d-flex p-0 m-0'><div className='chatAnswer'>";
            answerText += "<div className='col-12 flex-grow-1 p-0 m-0' data-testid='bot-answer-main-output-p'>" + new_answer + "</div>";
            answerText += "</div></div>";

            if (links.length > 0) {
                const learnMore = props.application.learnMoreLabel || "Learn more";
                answerText += "<div className='d-block col-12 flex-column justify-content-end mt-3'>";
                answerText += `<p className='m-0 p-0 text-end' style='font-weight:400' data-testid='bot-answer-learn-more-p'>${learnMore}:</p>`;

                var tempReferencesComponents = [];
                for (var i = 0; i < links.length; i++) {
                    var noW3Domain = links[i];
                    try {
                        var domain = links[i].match(/^https?:\/\/(?:www\.)?([^/?#]+)(?:[/?#]|$)/i)[1];
                        noW3Domain = domain.replace(/^www\./, "");
                    } catch (e) { }


                    if (props.application.answerType === "files") {
                        answerText +=
                            ' <span className="ms-0 ms-mb-3 mb-md-0 mb-1 mt-1 mt-md-0 d-md-inline d-block chatLearnMoreItem noMouseEvents">' +
                            (i + 1) +
                            ". " +
                            noW3Domain +
                            "</span> ";
                    } else if (props.application.answerType === "byod") {
                        const citationIndex = (parseInt(links[i].replace(/[^\d]/g, "")) - 1);
                        tempReferencesComponents.push(
                            <DefaultButton className="ms-1 ms-mb-3 mb-md-0 mb-1 mt-1 mt-md-1 d-block chatLearnMoreItem" key={"citation" + i} text={(i + 1) + ". " + props.citations[citationIndex].filepath}
                                onClick={() => modalHandle("Citation from: " + props.citations[citationIndex].filepath, props.citations[citationIndex].content)}>
                            </DefaultButton>
                        );
                    } else {
                        answerText +=
                            ' <a className="ms-0 ms-mb-3 mb-md-0 mb-1 mt-1 mt-md-0 d-md-inline d-block chatLearnMoreItem" href="' +
                            links[i] +
                            '" target="_blank">' +
                            (i + 1) +
                            ". " +
                            noW3Domain +
                            "</a> ";
                    }
                }
            }
            setReferencesComponents(tempReferencesComponents);
            return answerText;
        }

        try {
            const builtAnswer = buildAnswer(props.bot);
            const parsedAnswer = parser(builtAnswer);
            setBotAnswer(parsedAnswer);
        } catch (err) {
            setBotAnswer("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bot]);

    const modalHandle = (filepath, content) => {
        setModalInfo([filepath, content]);
    };

    useEffect(() => {
        if (stop) {
            const setCopyCode = () => {
                const root = document.getElementById(id);
                if (root && root.querySelectorAll("#copyButton")) {
                    if (!document.getElementById("copyToast")) {
                        const toast = document.createElement("div");
                        toast.id = "copyToast";
                        toast.innerHTML = "Copied to clipboard";
                        toast.classList.add("corpChatCodeCopyToast");
                        document.body.appendChild(toast);
                    }
                    const copyButtons = root.querySelectorAll("#copyButton");
                    copyButtons.forEach((copyButton) => {
                        copyButton.onclick = copyCode;
                    });
                }
            };
            setCopyCode();
        }
    }, [copyCode, id, stop]);

    useEffect(() => {
        if (props.loadingState === "" && !stop) {
            setStop(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loadingState]);

    return (
        <div id={id} className="mb-4">
            {botAnswer}
            {
                referencesComponents && referencesComponents.length > 0 &&
                <div className="d-flex col-12 flex-wrap justify-content-end">
                    {referencesComponents}
                </div>
            }
            <div className="d-flex justify-content-end">
                <FabricationLogger
                    showDialog={props.showDialog}
                    application={props.application}
                    loadingState={props.loadingState}
                    setLoadingState={props.setLoadingState}
                />
            </div>

            {modalInfo[0] !== '' && <ChatOutputModal modalInfo={modalInfo} setModalInfo={setModalInfo} />}

        </div>
    );
};

export default ChatOutput;
