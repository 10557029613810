// Create a functional component called Dropdown

import { React } from 'react';
import { ComboBox, IconButton } from '@fluentui/react';

const CustomDropdowMockup = (props) => {

    // Converts custom_field.options into an array of objects with key and text properties
    var options = props.custom_field.options.map((option) => {
        return {
            key: option,
            text: option
        }
    });

    return (
        <div className="d-flex">
            <IconButton role='move-up-button' iconProps={{ iconName: 'ChevronLeft' }} title="Move it Left" ariaLabel="Move it Left" onClick={() => props.moveCustomField(props.index, "up")} />
            <IconButton role='move-down-button' iconProps={{ iconName: 'ChevronRight' }} title="Move it Right" ariaLabel="Move it ight" onClick={() => props.moveCustomField(props.index, "down")} />
            <IconButton className='me-2' iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => props.editCustomField(props.custom_field, props.index)} />
            <IconButton role='delete-button' iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
            <ComboBox
                name={props.custom_field.name}
                label={props.custom_field.label}
                allowFreeform={false}
                autoComplete="off"
                options={options}
                placeholder={props.custom_field.placeholder}
                required={props.custom_field.required}
                style={{ width: "100%" }}
            />
        </div>
    )
}

export default CustomDropdowMockup;