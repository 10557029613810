// Import Dependencies
import { React, useEffect, useState, useRef } from "react";
import { TextField, DefaultButton, ActionButton } from '@fluentui/react';
import parser from 'html-react-parser';
import { postRunTask } from '../Api/Api'

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import CloseButtonDialog from "../Components/CloseButtonDialog";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";

const RudeFAQGenerator = (props) => {
    const rightPanelDiv = useRef(null);

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);
    const [generatedText, setGeneratedText] = useState("");

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }


    // Props treatment
    const application = props.application;


    ///////////////////////////////////////////////////////
    //////             TEMPLATE FUNCTIONS            //////
    ///////////////////////////////////////////////////////

    const [reporterName, setReporterName] = useState("");
    const [narrative, setNarrative] = useState("");

    // Handles the click on the left panel and focuses white div in left panel TextField, if theres any text selected, then deselect it
    const leftPanelClickHandle = () => {
        //document.getElementsByName("leftPanelInput")[0].focus();
    }

        // On any form item change, update the state of the corresponding variable
        const fieldUpdateHandler = (event, field) => {
            switch (field) {
                case 'reporterName':
                    setReporterName(event.target.value);
                    break;
                case 'narrative':
                    leftPanelHeightCapture();                    
                    setNarrative(event.target.value);
                    break;
            }
        }    


    // Capture leftPanelInput content height and set it to leftPanelInput style height
    const leftPanelHeightCapture = () => {
        const leftPanelInput = document.getElementsByName("leftPanelInput")[0];
        const leftPanel = document.getElementById("leftPanel");
        leftPanelInput.style.height = "auto";
        leftPanelInput.style.height = leftPanelInput.scrollHeight + "px";
        leftPanel.style.height = leftPanelInput.scrollHeight + "px";
    }


    useEffect(() => {
        leftPanelHeightCapture();
    }, []);


    // Submit form functions
    const submitForm = async() => {
        if (reporterName !== '' && narrative !== '') {
            setGeneratedText('');

            const apiParameters = {};
            apiParameters['reporter_name'] = reporterName;
            apiParameters['prompt'] = narrative;
            const apiCallBody = { task: application.task, parameters: apiParameters };

            props.setLoadingState('loading');

            try {
                // Call the API
                const data= await postRunTask(application.endpoint,apiCallBody);

                setGeneratedText(data.generated_text.replace(/\n/g, "<br>"));
                props.setLoadingState('');            
            }catch(error){
                 showDialog(application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please fill all the fields.");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            document.getElementById("leftPanelInput").focus();
        }
      };    


    

    // Component Render
    return (
        <>
            { /* App Header */}
            <ApplicationHeader application={application} />


            <div className="col-12 mt-1 mb-3">
                <div className="taskDivider"> </div>
            </div>


            { /* Result Area */}
            <div className="row d-flex flex-grow-1 m-0 p-0">

                { /* Left Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3" onClick={leftPanelClickHandle}>
                    <div className="col-12 pb-3">
                    <TextField readOnly={props.loadingState !== ''} value={reporterName} onChange={(event) => fieldUpdateHandler(event, "reporterName")} placeholder={"Write reporter name here"} onKeyDown={handleKeyDown}/>
                    </div>
                    <div id="leftPanel" className="col-12 flex-grow-1 bg-white p-0 pb-3 mobileMinHeight" >
                        <TextField readOnly={props.loadingState !== ''} value={narrative} onChange={(event) => fieldUpdateHandler(event, "narrative")}  style={{padding: '0px'}} name="leftPanelInput" id="leftPanelInput" multiline borderless={true} placeholder={"Write some Microsoft narrative"} />
                    </div>
                    <div className="col-12 pt-2 mb-2 d-flex panelFooterHeight align-items-center">
                        <DefaultButton disabled={props.loadingState !== ''} text="Submit" onClick={submitForm} ></DefaultButton>
                        {props.loadingState === "loading" && <LoadingSpinner />}
                    </div>

                    
                </div>

                { /* Right Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                    <div ref={rightPanelDiv} className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight">
                         <p>{parser(generatedText)}</p>
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight">
                        <StartOverButton loadingState={props.loadingState}  />                                                
                        <CopyTextButton  text={rightPanelDiv} isShowDialog={true} message={"Text copied."} loadingState={props.loadingState}  />                    
                    </div>
                </div>
            </div>

            { /* Dialog */}
            <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    )
};

export default RudeFAQGenerator;