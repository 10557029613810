// This is the main component of the application. It is the parent of all other components.
// It is responsible for rendering the header, sidebar, and main content.
// It also contains the routing logic for the application.

// Import CSS
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/styles.css';

// Import Dependencies
import { React, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'

// Import Components
import Header from './Layout/Header'
import Disclaimer from './Components/Disclaimer';

// Import Pages
import Home from './Pages/Home';
import Faq from './Pages/Faq';


// List of public app templates
// IF THIS CHANGES, SO SHOULD THE SWITCH STATEMENT IN ROUTES
import IHME from './Pages/IHME';
import MediaTrans from './Pages/MediaTrans';
import GptTasks from './Pages/GptTasks';
import AudioToArticle from './Pages/AudioToArticle';
import VideoTranscript from './Pages/VideoTranscript';
import RudeFAQGenerator from './Pages/RudeFAQGenerator';
import QAApps from './Pages/QAApps';
import URLGpt from './Pages/URLGpt';
import AIGeneratedImagesClassifier from './Pages/AIGeneratedImagesClassifier';

// List of admin center templates
import AppMaker from './Pages/AdminCenter/AppMaker';
import AppModifier from './Pages/AdminCenter/AppModifier';
import AppManager from './Pages/AdminCenter/AppManager';

import { fetchIdentity, fetchAppConfig, fetchAdminCenterConfig } from './Api/Api';
import { LoadingContext } from './Utils/LoadingManager';
import PromptEditor from './Pages/PromptEditor';
function App() {
  // Max filesize for file uploads in bytes (20MB)
  const fileMaxSize = 20971520;
  const api_url = '/api/';

  // Loading state, if "loading", then show loading overlay
  const { loading } = useContext(LoadingContext);
  const [loadingState, setLoadingState] = useState('');
  const [isWaffleMenuOpen, setIsWaffleMenuOpen] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [categories, setCategories] = useState(null);

  // Fetch the identity
  const { data: identity, isSuccess: identitySuccess } = useQuery(['identity', api_url], () => fetchIdentity())
  // Fetch the app config and admin center config if the identity is not null
  const { data: appConfig, isSuccess: appConfigSuccess } = useQuery(['app_config', api_url], () => fetchAppConfig(), { enabled: !!identitySuccess && !!identity })
  const { data: adminCenterConfig, isSuccess: adminCenterConfigSuccess } = useQuery(['admin_center_config', api_url], () => fetchAdminCenterConfig(), { enabled: !!identitySuccess && !!identity })

  useEffect(() => {
    if (identitySuccess && appConfigSuccess && adminCenterConfigSuccess && currentTemplate === null && categories === null) {
      //If the user is a site admin, then check the current path to see if it is an admin center page.
      var url = window.location.pathname.split("/"); // Utilizar pathname en lugar de href
      url = url.filter(Boolean); // Eliminar elementos vacíos en caso de tener '/' al final
      var currentPath =
        url.length > 1 ? url[url.length - 2] : url[url.length - 1];

      var current_dict = appConfig;

      if (identity.roles.includes("Task.SiteAdmin")) {
        if (
          currentPath === "admin" ||
          currentPath === "admin-app-maker" ||
          currentPath === "admin-app-manager" ||
          currentPath === "admin-app-modifier"
        )
          current_dict = adminCenterConfig;
      }

      setCurrentTemplate(current_dict);
      setCategories(Object.keys(current_dict));
    }
  }, [adminCenterConfig, adminCenterConfigSuccess, appConfig, appConfigSuccess, categories, currentTemplate, identity, identitySuccess]);


  // Render the application
  return (
    <Router>

      <Disclaimer />
      <Header user_name={identity?.name} current_dict={currentTemplate ?? {}} isWaffleMenuOpen={isWaffleMenuOpen} setIsWaffleMenuOpen={setIsWaffleMenuOpen} />

      <main role="main" id="mainDiv">
        <div className="container-fluid">
          <div className="row m-0 p-0">

            <div id="mainContent" className={`col-sm-12 main d-flex flex-column ${isWaffleMenuOpen ? 'menuPaddingOpened' : 'menuPaddingClosed'}`} style={{ pointerEvents: loading ? 'none' : 'auto' }}>

              <Routes>
                <Route path='/' element={<Home key="CardList" data={currentTemplate ?? {}} />} />
                <Route path='/faq' element={<Faq key="Faq" />} />
                <Route path='/admin' element={<Home key="CardList" data={currentTemplate??{}} />} />

                {
                  (categories ?? []).map((category) => (
                    currentTemplate[category].map(application => (
                      (application.enabled === "true") ? (
                        (() => {
                          const taskPath = application.task.replace(/_/g, '-');
                          switch (application.template) {

                            // Public App Templates
                            case 'IHME':
                              return <Route path={`/${taskPath}`} element={<IHME fileMaxSize={fileMaxSize} key={application.task} application={application} api_url={api_url} />} />;
                            case 'PromptEditor':
                              return <Route path={`/${taskPath}`} element={<PromptEditor fileMaxSize={fileMaxSize} key={application.task} application={application} api_url={api_url} />} />;
                            case 'GptTasks':
                              return <Route path={`/${taskPath}`} element={<GptTasks fileMaxSize={fileMaxSize} loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'AudioToArticle':
                              return <Route path={`/${taskPath}`} element={<AudioToArticle fileMaxSize={fileMaxSize} loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'VideoTranscript':
                              return <Route path={`/${taskPath}`} element={<VideoTranscript loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'RudeFAQGenerator':
                              return <Route path={`/${taskPath}`} element={<RudeFAQGenerator loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'AIGeneratedImagesClassifier':
                              return <Route path={`/${taskPath}`} element={<AIGeneratedImagesClassifier fileMaxSize={fileMaxSize} loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'QAApps':
                              return <Route path={`/${taskPath}`} element={<QAApps fileMaxSize={fileMaxSize} loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                            case 'URLGpt':
                              return <Route path={`/${taskPath}`} element={<URLGpt loadingState={loadingState} setLoadingState={setLoadingState} key={application.task} application={application} api_url={api_url} />} />;
                              case 'MediaTrans':
                                return <Route path={`/${taskPath}`} element={<MediaTrans fileMaxSize={fileMaxSize} key={application.task} application={application} api_url={api_url} />} />;

                            // Admin Center Templates
                            case 'AppMaker':
                              return <Route path={`/${taskPath}`} element={<AppMaker key={"AppMaker"} loadingState={loadingState} fileMaxSize={fileMaxSize} setLoadingState={setLoadingState} application={application} App_dict={appConfig} api_url={api_url} />} />;
                            case 'AppModifier':
                              return <Route path={`/${taskPath}/:paramTask?`} element={<AppModifier key={"AppModifier"} loadingState={loadingState} setLoadingState={setLoadingState} application={application} App_dict={appConfig} api_url={api_url} />} />;
                            case 'BackupRetriever':
                              return <Route path={`/${taskPath}`} element={<AppMaker key={"BackupRetriever"} loadingState={loadingState} setLoadingState={setLoadingState} application={application} App_dict={appConfig} api_url={api_url} />} />;
                            case 'AppManager':
                              return <Route path={`/${taskPath}`} element={<AppManager key={"AppManager"} loadingState={loadingState} setLoadingState={setLoadingState} application={application} App_dict={appConfig} api_url={api_url} />} />;
                            default:
                              return <Route element={<Navigate to="/" />} />;
                          }
                        })()
                      ) : null
                    ))
                  ))
                }
                {
                  <Route path='*' element={currentTemplate ? <Navigate to="/" /> : <Home key="CardList" data={currentTemplate ?? {}} />} />
                }
              </Routes>
            </div>
          </div>
        </div>
      </main>
    </Router>
  );
}

export default App;
