// Import Dependencies
import { React, useState } from 'react';
import { DefaultButton, IconButton, Icon,  Modal } from '@fluentui/react';
import { postRunTask } from '../Api/Api'

const FabricationLogger = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userGaveFeedbadk, setUserGaveFeedback] = useState(false);

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }


    const submitLog = async () => {
        
        props.setLoadingState('loading');
        const apiCallBody = {};
        apiCallBody['category'] = "fabrication_logging";
        apiCallBody['processor_id'] = props.application.task;
        try {
            // Call the API
            const data = await postRunTask("log", apiCallBody);
            if (data.data === "Request logged") {
                props.showDialog("Thank you for your feedback!");
                setUserGaveFeedback(true);

            } else {
                props.showDialog("Error submitting feedback.");
                setUserGaveFeedback(false);
            }
            props.setLoadingState('');
        } catch (error) {
            props.showDialog(props.application.api_error_message);
            props.setLoadingState('');
        };
    }


    return (
        <>
            <div className="d-block d-sm-flex align-items-center">
                {userGaveFeedbadk ? (
                    <>
                        <p className="me-3 mt-1 pt-3 feedback-text">Feedback successfully sent.</p>
                        <Icon className="me-3 mt-1"  iconName="CheckMark" />
                    </>
                ) : (
                    <>
                        <p className="me-3 mt-1 pt-3 feedback-text">Does this answer include content that is incorrect, non-sensical, or not real?</p>
                        <IconButton className="me-3 mt-1" iconProps={{ iconName: "Info" }} onClick={toggleModal} disabled={props.loadingState !== ''} />
                        <DefaultButton className="  mt- fw-semibold" text='Yes' style={{ fontSize: '12.5px', border: '0px' }} disabled={props.loadingState !== ''} onClick={submitLog} />
                    </>
                )}
            </div>

            <Modal
                key={"Icons"}
                titleAriaId={"Icons"}
                isOpen={isModalOpen}
                onDismiss={toggleModal}
                isBlocking={false}
                className='p-1'
            >
                <div className="row m-0 p-0" style={{ maxWidth: '800px' }}>
                    <div className='col-12 p-3 p-md-5 d-flex '>
                        <div className="col-12 p-0 d-block ">
                            <p className='p-0 m-0'>We conducts ongoing research into large language models like ChatGPT.
                                This optional feedback button enables you to report incorrect, non-sensical, or not real answers.
                                We use the data to inform where additional research should be prioritized.
                                The tool does not save answers, only the YES votes reported.</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )


};

export default FabricationLogger;