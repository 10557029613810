import {React, useState} from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

const LoadingSpinner = (props) => {

    const [spinnerSize, setSpinnerSize] = useState(props.spinnerSize === "small" ? SpinnerSize.small : SpinnerSize.large);

    return (
        <Spinner className="ms-3 justify-content-start" label="Loading" ariaLive="polite" labelPosition="right" size={spinnerSize}/>
    )

};

export default LoadingSpinner;