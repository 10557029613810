// Import Dependencies
import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const Disclaimer = () => {
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("disclaimer") === "false") {
            setShowDisclaimer(false);
        } else {
            setShowDisclaimer(true);
        }
    }, []);

    function closeDisclaimer() {
        localStorage.setItem("disclaimer", "false");
        setShowDisclaimer(false);
    }


    if (!showDisclaimer) {
        return null; // No se renderizará ningún contenido si disclaimerValue es false
    }

    return (
        <>
            <div className="app-disclaimer-bg" onClick={closeDisclaimer}>
            </div>

            <div className="app-disclaimer p-3 p-lg-4 col-10 col-md-7 col-lg-7 col-xl-5 col-xxl-5">
                <div className="row mt-0 p-0 m-0">
                    <div className="col-12 d-flex">
                        <div className="col-6 mb-3">
                            <h1 className="taskTitle text-start ms-0 mt-1 mb-0">Notice</h1>
                        </div>
                        <div className="d-none d-md-flex col-6 mb-3 mt-2 flex-row-reverse ">
                            <i className="ms-Icon ms-Icon--ChromeClose" onClick={closeDisclaimer} style={{ cursor: "pointer" }}></i>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="pDisclaimer">This tool is for Internal Use Only.</p>
                        <p className="pDisclaimer">This tool is provided “AS-IS” and “AS AVAILABLE” and is used at your own risk. AI-generated
                            content can have mistakes, and we do not guarantee that this tool is error free. Note that
                            interactions with this tool are not protected by the attorney-client privilege. This tool is not
                            meant to replace consulting your IHME contact for legal advice. You
                            are solely responsible for evaluating, checking, and deciding whether to rely on any information
                            provided by this tool.</p>
                        <p className="pDisclaimer">Do not input personal or health information into the tool, or any information that is protected by
                            the attorney-client privilege or work product immunity; and do not input information that is Highly
                            Confidential.</p>
                    </div>
                </div>
            </div>
        </>
    )


};

export default Disclaimer;