// Import Dependencies
import {React} from 'react';


const Divider = () => {



    return (
        <div className="taskDivider"> </div>
    )
    

};

export default Divider;