// Import Dependencies
import { React, useState, useMemo, useContext } from "react";
import { IconButton } from "@fluentui/react";
import { postRunTask } from "../../Api/Api";
import { LoadingContext } from "../../Utils/LoadingManager";

const SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE = {
  TWITTERPOST: "copy",
  LINKEDINPOST: "copy",
  AICREATEDIMAGES: "copy",
  BINGIMAGEURLS: "copy",
  APIRESULTS: null,
  APIPARAMETERS: null,
  FETCHDATA: false,
  FIELDDATA: null,
  FILESPROCESSING: null,
  INITCOMPONENT: false,
  RESETREQUEST: false,
};

const SocialMediaOutput = (props) => {
  const { setLoadingResponseState } = useContext(LoadingContext);

  const [twitter_post_icon, setTwitter_post_icon] = useState(
    SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE.TWITTERPOST
  );
  const [linkedin_post, setLinkedin_post_icon] = useState(
    SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE.LINKEDINPOST
  );
  const [ai_created_images_icon, setAi_created_images_icon] = useState(
    SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE.AICREATEDIMAGES
  );
  const [bing_image_urls_icon, setBing_image_urls_icon] = useState(
    SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE.BINGIMAGEURLS
  );
  const [apiResults, setApiResults] = useState(
    SOCIAL_MEDIA_OUTPUT_DEFAULT_STATE.APIRESULTS
  );
  useMemo(async () => {
    if (props.leftPanelInputValue !== "") {
      // Create an array of parameters to pass to the API
      const apiParameters = {};
      apiParameters["prompt"] = props.leftPanelInputValue;

      // Create the body of the API call
      const apiCallBody = {
        task: props.application.task,
        parameters: apiParameters,
      };

      try {
        // Call the API
        setLoadingResponseState("SocialMediaOutput", true);
        const data = await postRunTask(props.application.endpoint, apiCallBody);
        setApiResults(data);
        props.setHasResult(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (error) {
        props.showDialog(
          "A problem has come up. We will investigate it. Please try again in a few minutes."
        );
      } finally {
        setLoadingResponseState("SocialMediaOutput", false);
      }
    } else {
      props.showDialog(
        "Please copy and paste an article in the left panel before submitting the form."
      );
    }
  }, []);

  function copyToClipboard(currentBlock, text, message) {
    navigator.clipboard.writeText(text.replace(//g, ""));

    setTwitter_post_icon("copy");
    setLinkedin_post_icon("copy");
    setAi_created_images_icon("copy");
    setBing_image_urls_icon("copy");

    switch (currentBlock) {
      case "twitter_post":
        setTwitter_post_icon("checkmark");
        break;
      case "linkedin_post":
        setLinkedin_post_icon("checkmark");
        break;
      case "generated_images":
        setAi_created_images_icon("checkmark");
        break;
      case "url_list":
        setBing_image_urls_icon("checkmark");
        break;
      default:
        break;
    }
  }

  // Component Render
  return (
    <>
      {/* Twitter post */}
      {apiResults?.twitter_post ? (
        <>
          <h6 className="mb-3"> Twitter Post</h6>
          <row
            className="m-0 p-0 d-flex p-2 mb-5"
            style={{ backgroundColor: "#EAF4F7" }}
          >
            <div className="d-flex flex-grow-1 p-0">
              {apiResults.twitter_post ? (
                <p className="mb-3 pe-3">{apiResults.twitter_post} </p>
              ) : null}
            </div>
            <div>
              <IconButton
                className="m-0 p-0"
                aria-label="Twitter Post Copy"
                iconProps={{ iconName: twitter_post_icon }}
                onClick={() =>
                  copyToClipboard(
                    "twitter_post",
                    apiResults.twitter_post,
                    "Twitter post copied."
                  )
                }
              />
            </div>
          </row>
        </>
      ) : null}

      {/* Linkedin post */}
      {apiResults?.linkedin_post ? (
        <>
          <h6 className="mb-3"> Linkedin Post</h6>
          <row
            className="m-0 p-0 d-flex p-2 mb-5"
            style={{ backgroundColor: "#EFF9ED" }}
          >
            <div className="d-flex flex-grow-1 p-0">
              {apiResults.linkedin_post ? (
                <p className="mb-3 pe-3">{apiResults.linkedin_post} </p>
              ) : null}
            </div>
            <div>
              <IconButton
                className="m-0 p-0"
                aria-label="Linkedin Post Copy"
                iconProps={{ iconName: linkedin_post }}
                onClick={() =>
                  copyToClipboard(
                    "linkedin_post",
                    apiResults.linkedin_post,
                    "Linkedin post copied."
                  )
                }
              />
            </div>
          </row>
        </>
      ) : null}

      {/* AI Generated Images */}
      {apiResults?.generated_images ? (
        <>
          <row className="m-0 p-0 d-flex p-2 ps-0 pt-0">
            <div className="d-flex flex-grow-1 p-0">
              <h6 className="mb-3"> AI Created Images</h6>
            </div>
            <div>
              <IconButton
                className="m-0 p-0"
                aria-label="Image Copy"
                iconProps={{ iconName: ai_created_images_icon }}
                onClick={() =>
                  copyToClipboard(
                    "generated_images",
                    apiResults.generated_images.join("\n"),
                    "AI created images copied."
                  )
                }
              />
            </div>
          </row>
          <div className="col-12 mb-5">
            {apiResults.generated_images.map((image_url) => {
              return (
                <img
                  key={image_url}
                  alt="AI Generated"
                  src={"data:image/png;base64," + apiResults.generated_images}
                  className="w-100 mb-2"
                />
              );
            })}
          </div>
        </>
      ) : null}

      {/* Bing Images */}
      {apiResults?.url_list ? (
        <>
          <row className="m-0 p-0 d-flex p-2 ps-0 pt-0">
            <div className="d-flex flex-grow-1 p-0">
              <h6 className="mb-3"> Bing Images</h6>
            </div>
            <div>
              <IconButton
                className="m-0 p-0"
                aria-label="Image Copy"
                iconProps={{ iconName: bing_image_urls_icon }}
                onClick={() =>
                  copyToClipboard(
                    "url_list",
                    apiResults.url_list.join("\n"),
                    "Links copied."
                  )
                }
              />
            </div>
          </row>

          {apiResults.url_list.map((image_url) => {
            return (
              <img
                key={image_url}
                src={image_url}
                className="w-100 mb-2"
                alt="Related Bing"
              />
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default SocialMediaOutput;
