

export function createSlug(text) {
    let slug = text.toLowerCase();
    slug = slug.replace(/\s+/g, '-');
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    slug = slug.replace(/[^a-z0-9-_]/g, '');
  
    return slug;
}

export function validateImage(icon, imageType, imageWidth, imageHeight) {
  return new Promise((resolve, reject) => {
      if (icon.type !== imageType) {
          reject('Image must be a PNG file');
      }

      const img = new Image();
      img.onload = function() {
          if (this.width !== imageWidth || this.height !== imageHeight) {
              reject("Image must be " + imageWidth + " x " + imageHeight + "px");
          } else {
              resolve(true);
          }
      }
      img.onerror = function() {
          reject('There was an error loading the image. Please try again.');
      }

      img.src = URL.createObjectURL(icon);
  });
}