// Create a functional component called Dropdown

import {React, useState} from 'react';
import { TextField, IconButton, Toggle, PrimaryButton } from '@fluentui/react';

const CustomSliderCreator = (props) => {

    const [name, setName] = useState(props.customField.name);
    const [label, setLabel] = useState(props.customField.label);
    const [label_min, setLabelMin] = useState(props.customField.label_min);
    const [label_max, setLabelMax] = useState(props.customField.label_max);
    const [min, setMinValue] = useState(props.customField.min);
    const [max, setMaxValue] = useState(props.customField.max);
    const [default_value, setDefaultValue] = useState(props.customField.default_value);
    const [step, setStep] = useState(props.customField.step);
    const [required, setRequired] = useState(props.customField.required);




    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case "name":
                if (/^[A-Za-z0-9]+$/.test(event.target.value) || event.target.value === "") {
                    setName(event.target.value);
                }  
                break;
            case "label":
                setLabel(event.target.value);
                break;
            case "label_min":
                setLabelMin(event.target.value);
                break;
            case "label_max":
                setLabelMax(event.target.value);
                break;
            case "min":
                if (/^\d*$/.test(event.target.value)) {
                    setMinValue(event.target.value);
                }
                break;
            case "max":
                if (/^\d*$/.test(event.target.value)) {
                    setMaxValue(event.target.value);
                }
                break;
            case "default_value":
                if (/^\d*$/.test(event.target.value)) {
                    setDefaultValue(event.target.value);
                }
                break;
            case "step":
                if (/^\d*$/.test(event.target.value)) {
                    setStep(event.target.value);
                }
                break;
            case "required":
                if(required === "no"){
                    setRequired("no");
                }else{
                    setRequired("yes");
                }
                break;
            default:
                break;
        }
    }

    function fieldUpdateOnBlurHandler(){
        if(name !== "" && label !== "" && label_min !== "" && label_max !== "" && min !== "" && max !== "" && default_value !== "" && step !== "") {
            const tempItem = {
                name: name,
                type: "slider",
                label: label,
                label_min: label_min,
                label_max: label_max,
                min: min,
                max: max,
                default_value: default_value,
                step: step,
                required: required
            };

            props.updateCustomField(props.index, tempItem);
            props.closeModal();
        }else{
            alert("Please fill all the fields");
        }
    }

    return (
        <div className='row'>
            <div className="col-6 mb-md-3 pe-0">
                <h6 className='tCustomBlue'>Create a Slider</h6>
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0 text-md-end mb-">
                {props.mode !== "creation" ?
                    <>
               <IconButton data-testid="custom-slider-move-up-btn" iconProps={{ iconName: 'Up' }} title="Up" ariaLabel="Up" onClick={() => props.moveCustomField(props.index, "up")} />
                        <IconButton data-testid="custom-slider-move-down-btn" iconProps={{ iconName: 'Down' }} title="Down" ariaLabel="Down" onClick={() => props.moveCustomField(props.index, "down")} />
                        <IconButton data-testid="custom-slider-delete-btn" iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
                    </> : ""
                }
                <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => props.closeModal()} />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={20} value={name}  onChange={(event) => fieldUpdateHandler(event, "name")} label="Name:" placeholder={"Will be the identifier in API"} className="" />
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={40} value={label}  onChange={(event) => fieldUpdateHandler(event, "label")} label="Label:" placeholder={"Aria Label"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={20} value={label_min}  onChange={(event) => fieldUpdateHandler(event, "label_min")} label="Label Min:" placeholder={"Label for min value"} className="" />
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={20} value={label_max}  onChange={(event) => fieldUpdateHandler(event, "label_max")} label="Label Max:" placeholder={"Label for max value"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField value={min}  onChange={(event) => fieldUpdateHandler(event, "min")} label="Min Value:" placeholder={"Min value accepted"} className="" />
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField value={max} onChange={(event) => fieldUpdateHandler(event, "max")} label="Max Value:" placeholder={"Max value accepted"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField value={default_value}  onChange={(event) => fieldUpdateHandler(event, "default_value")} label="Default Value:" placeholder={"Selected value on load"} className="" />
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField value={step}  onChange={(event) => fieldUpdateHandler(event, "step")} label="Step:" placeholder={"The value that changes on slide"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0 pt-2">
                <Toggle label="Required"    defaultChecked={required ? "yes" : undefined}  onChange={(event) => fieldUpdateHandler(event, "required")} onText="Is Required" offText="Not Required" />
            </div>

            <div className="col-12 mb-3 pe-0 pt-2 text-end">
                <PrimaryButton text="Save" onClick={fieldUpdateOnBlurHandler} />
            </div>

        </div>
    )
}

export default CustomSliderCreator;