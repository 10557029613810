// Import Dependencies
import { React, useEffect, useState, useRef, } from "react";
import { TextField, DefaultButton, ActionButton } from '@fluentui/react';
import CloseButtonDialog from "../Components/CloseButtonDialog";
import parser from 'html-react-parser';
import { postRunTask } from '../Api/Api'


// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";


const URLGpt = (props) => {

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

 

    // Props treatment
    const [application, setApplication] = useState(props.application);


    ///////////////////////////////////////////////////////
    //////             TEMPLATE FUNCTIONS            //////
    ///////////////////////////////////////////////////////

    const [questionValue, setQuestionValue] = useState("");
    const [urlList, setUrlList] = useState('');
    const [generatedText, setGeneratedText] = useState('');

    // Handles the click on the left panel and focuses white div in left panel TextField, if theres any text selected, then deselect it
    const leftPanelClickHandle = () => {
        document.getElementById("leftPanelInput").focus();
    }


    // On any form item change, update the state of the corresponding variable
    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case 'questionValue':
                setQuestionValue(event.target.value);
                break;
            case 'urlList':
                leftPanelHeightCapture();
                setUrlList(event.target.value);
                break;
        }
    }

    // Copy right panel content to clipboard
    const rightPanelDiv = useRef(null);

    // Capture leftPanelInput content height and set it to leftPanelInput style height
    const leftPanelHeightCapture = () => {
        const leftPanelInput = document.getElementById("leftPanelInput");
        const leftPanel = document.getElementById("leftPanel");
        leftPanelInput.style.height = "auto";
        leftPanelInput.style.height = leftPanelInput.scrollHeight + "px";
        leftPanel.style.height = leftPanelInput.height + "px";
    }

    useEffect(() => {
        leftPanelHeightCapture();
    }, []);


    // Submit form functions
    const submitForm = async () => {
        if (questionValue !== '' && urlList !== '') {
            setGeneratedText('');

            const apiParameters = {};
            apiParameters['url_list'] = urlList.split('\n').filter(linea => linea.trim() !== '');
            apiParameters['prompt'] = questionValue;
            const apiCallBody = { task: props.application.task, parameters: apiParameters };

            props.setLoadingState('loading');
            
            try {
                // Call the API
                const data= await postRunTask(props.application.endpoint,apiCallBody);

                setGeneratedText(data.generated_text.replace(/\n/g, "<br>"));
                props.setLoadingState('');
            }catch(error){
                showDialog(props.application.api_error_message);
                props.setLoadingState('');
            };
        } else {
            showDialog("Please fill all the fields.");
        }
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submitForm();
        }
    };

    // Component Render
    return (
        <>
            { /* App Header */}
            <ApplicationHeader application={application} />



            <div className="col-12 mt-1 mb-3">
                <div className="taskDivider"> </div>
            </div>

            { /* Question Area */}
            <div className="row m-0 mt-0 p-0 d-flex ">
                <div className="col-12 d-sm-flex p-0 ">
                    <TextField readOnly={props.loadingState !== ''} placeholder="Write your question" className="flex-grow-1 me-0 me-sm-3 mb-2 mb-sm-0" value={questionValue} onChange={(event) => fieldUpdateHandler(event, "questionValue")}  onKeyDown={handleKeyDown} />
                    <DefaultButton disabled={props.loadingState !== ''} iconProps={{ iconName: 'send' }} className="w-100-responsive" text="Generate Response" onClick={submitForm} />
                </div>
            </div>

            <div className="col-12 mt-3 mb-3">
                <div className="taskDivider"> </div>
            </div>


            { /* Result Area */}
            <div className="row d-flex flex-grow-1 m-0 p-0">

                { /* Left Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3" onClick={leftPanelClickHandle}>
                    <div className="col-12 pb-1 ps-2">
                        <h4>URL List, one url per line</h4>
                    </div>
                    <div id="leftPanel" className="col-12 flex-grow-1 bg-white p-0 pb-3 mobileMinHeight">
                        <TextField className="p-0" value={urlList} name="leftPanelInput" id="leftPanelInput" multiline borderless={true} placeholder={application.example_input_text} onChange={(event) => fieldUpdateHandler(event, "urlList")} />
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
                    </div>
                </div>

                { /* Right Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                    <div className="col-12 pb-1 ps-2">
                        <h4>Answer to query</h4>
                    </div>
                    <div id="rightPanelDiv" ref={rightPanelDiv} className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight">
                        <p>{parser(generatedText)}</p>
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight">
                        <StartOverButton loadingState={props.loadingState}  />                        
                        <CopyTextButton  text={rightPanelDiv} isShowDialog={true} message={"Text copied."} loadingState={props.loadingState}  />                                        
                    </div>
                </div>
            </div>

            { /* Dialog */}
            <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    )
};

export default URLGpt;