// Import Dependencies
import { useEffect, useState, useRef, useContext } from "react";
import { IconButton, DefaultButton, ActionButton, FontIcon, TextField, Dropdown, Label } from "@fluentui/react";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";

// Import Components
import CloseButtonDialog from "../Components/CloseButtonDialog";
import ApplicationHeader from "../Components/ApplicationHeader";
import CustomDropdown from "../Components/GptTasks/CustomDropdown";
import CustomSlider from "../Components/GptTasks/CustomSlider";
import CustomTextField from "../Components/GptTasks/CustomTextField";
import CustomFileChooser from "../Components/GptTasks/CustomFileChooser";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";

import { LoadingContext } from "../Utils/LoadingManager";
import IHMEDefaultOutput from "../Components/GptOutput/IHMEOutput";
import { postRunTask } from "../Api/Api";

const IHME_DEFAULT_STATE = {
  RESETSTATE: false,
  TASKRESPONSE: null,
  DIALOGHIDDEN: true,
  DIALOGTEXT: true,
  APPLICATION: null,
  CUSTOMFIELDSSTATE: null,
  CUSTOMFIELDSELEMENTS: [],
  UPDATEFIELDDATA: true,
  STATUSRESULTS: false,
  INITCOMPONENT: false,
};

const IHME = (props) => {

  const [existingRequestorEmails, setExistingRequestorEmails] = useState([]);
  const [selectedRequestorEmails, setSelectedRequestorEmails] = useState([]);
  const [filenameFilter, setFilenameFilter] = useState("");

  const [responseData, setResponseData] = useState(null);
  const [sortedBy, setSortedBy] = useState({ field: null, direction: 'desc' });


  const task = props.application.task;
  const isBatchProcess = task.includes("batch");

  const { loading, loadingResponse, clearAll, setLoadingResponseState } =
    useContext(LoadingContext);

  const [resetState, setResetState] = useState(IHME_DEFAULT_STATE.RESETSTATE);
  // Constant that will contain dynamic component, based on api response
  const [taskResponse, setTaskResponse] = useState(
    IHME_DEFAULT_STATE.TASKRESPONSE
  );
  // Dialog Handle
  const [dialogHidden, setDiaglogHidden] = useState(
    IHME_DEFAULT_STATE.DIALOGHIDDEN
  );
  const [dialogText, setDialogText] = useState(IHME_DEFAULT_STATE.DIALOGTEXT);
  // Props treatment
  const [application, setApplication] = useState(
    IHME_DEFAULT_STATE.APPLICATION
  );

  const [customFieldsState, setCustomFieldsState] = useState(
    IHME_DEFAULT_STATE.CUSTOMFIELDSSTATE
  );
  const [customFieldsElements, setCustomFieldsElements] = useState(
    IHME_DEFAULT_STATE.CUSTOMFIELDSELEMENTS
  );
  const [statusResults, setStatusResults] = useState(
    IHME_DEFAULT_STATE.STATUSRESULTS
  );
  const [initComponent, setInitComponent] = useState(
    IHME_DEFAULT_STATE.INITCOMPONENT
  );

  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }

  ///////////////////////////////////////////////////////
  //////             TEMPLATE FUNCTIONS            //////
  ///////////////////////////////////////////////////////

  // Copy right panel content to clipboard
  const rightPanelDiv = useRef(null);

  const setErrors = (fieldNames) => {
    const newFieldData = {};
    Object.entries(customFieldsState).forEach(([key, value]) => {
      newFieldData[key] = {
        ...value,
        data: {
          ...value.data,
          hasError: fieldNames.includes(key),
        },
      };
    });
    setCustomFieldsState(newFieldData);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  function getCustomFieldState(
    customField,
    hasError = false,
    userSelectedValue = null,
    errorMessage = null
  ) {
    const data = {
      hasError: hasError,
      errorMessage: errorMessage,
      userSelectedValue: userSelectedValue,
    };
    return {
      [customField.name]: {
        props: customField,
        data: data,
      },
    };
  }

  // Clear filter form
  const clearFilterForm = () => {
    setFilenameFilter("");
    setSelectedRequestorEmails([]);
  };

  // Submit form functions
  const submitForm = () => {
    const componentId = uuidv4();
    const response = (
      <IHMEDefaultOutput
        showDialog={showDialog}
        key={componentId}
        application={application}
        leftPanelInputValue={null}
        setErrors={setErrors}
        fieldData={customFieldsState}
        isBatch={isBatchProcess}
        taskResponse={taskResponse}
        setStatusResults={setStatusResults}
      />
    );
    setTaskResponse(response);
  };


  const quotedCSVToArray = (csv) => {
    const regex = /(?:^|,)(?:"([^"]*(?:""[^"]*)*)"|([^",]*))/g;
    return csv.match(regex).map((match) => {
      return match
        .replace(/,/g, "")
        .replace(/""/g, '"')
        .replace(/^"/, "")
        .replace(/"$/, "");
    });
  };
  const generateHash = async (str) =>
    Array.from(
      new Uint8Array(
        await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str))
      )
    )
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");



  useEffect(() => {

    if (responseData !== null && responseData !== undefined) {

      // Get all unique emails to populate the filter dropdown
      setExistingRequestorEmails(
        responseData.jobs
          .map((job) => job.requestor_email)
          .filter(
            (value, index, self) =>
              value && self.indexOf(value) === index
          )
          .map((email) => ({ key: email, text: email }))
      );


      const responseDataTemp = JSON.parse(JSON.stringify(responseData));

      // Filter by filename any file that contains the filenameFilter
      if (filenameFilter !== "") {
        responseDataTemp.jobs = responseDataTemp.jobs.filter((job) => {
          return job.job_id.includes(filenameFilter) || job.files_names.includes(filenameFilter);
        });
      }

      // Filter by requestor email
      if (selectedRequestorEmails.length > 0) {
        responseDataTemp.jobs = responseDataTemp.jobs.filter((job) => {
          return selectedRequestorEmails.includes(job.requestor_email);
        });
      }

      const exclude_headers = ["data", "download_url", "index_data", "base64Files"];
      const sortable_headers = [{ field: "job_id", data_type: "string" }, { field: "requestor_email", data_type: "string" }, { field: "submission_date", data_type: "date" }];
      const jobs = responseDataTemp.jobs;
      const groups = {};

      for (const job of jobs) {
        // generate random string to concatenate to the download url

        job["submission_date"] = dateFormat(job["submission_date"]);
        job["status"] = renderStatus(job);

        // build json data
        const data_array = job["data"];
        const index_data = [];

        for (const data of data_array) {
          const rows = data.split(/\r?\n/).filter((row) => {
            return row.trim() !== "";
          });
          const dataHeaderList = quotedCSVToArray(rows[0]).map((h) =>
            h.toLowerCase().trim()
          );
          const filenameIndex = dataHeaderList.indexOf("filename");
          for (let i = 1; i < rows.length; i++) {
            const csvList = quotedCSVToArray(rows[i]);
            if (csvList.length > 0) {
              const obj = {};
              obj[csvList[filenameIndex]] = data;
              index_data.push(obj);
            }
          }
        }

        job["download"] = job["data"].length > 0 && (
          <p className="text-center" key={`${job["job_id"]}downloadAll`}>
            <IconButton
              iconProps={{ iconName: "DrillDownSolid" }}
              title="Download"
              role="button"
              className="download-icon align-self-center"
              key={`cell${job["job_id"]}-downloadAll`}
              onClick={() => {
                const link = document.createElement("a");
                link.href = `data:text/csv;charset=utf-8,${job["data"]}`;
                link.download = `${job["job_id"]}.csv`;
                link.click();
              }}
            />
          </p>
        );

        job["files_names"] = job["files_names"]
          .split(",")
          .map((filename) => <p key={`${filename}file`} title={filename} className="ctFontSize d-block pb-0 mb-0">{truncateText(filename, 35)}</p>);

        // Truncate texts to fit cell width  
        job["prompt_names"] = <p className="ctFontSize d-block pb-0 mb-0" title={job["prompt_names"]}>{truncateText(job["prompt_names"], 35)}</p>;
        job["requestor_email"] = <p className="ctFontSize d-block pb-0 mb-0" title={job["requestor_email"]}>{truncateText(job["requestor_email"], 35)}</p>;          

        // Column Reorder
        const reorderedJob = { download: job.download, files_names: job.files_names, requestor_email: job.requestor_email, status: job.status, message: job.message, submission_date: job.submission_date, prompt_names: job.prompt_names, ...job };

        const headerList = Object.keys(reorderedJob).filter(
          (key) => !exclude_headers.includes(key)
        );

        const formattedHeaderList = headerList.map((key) =>
          toTitleCase(key.replace("_", " "))
        );
        const header = headerList.join(",");
        const headerHash = generateHash(header.toLowerCase());
        if (!groups[headerHash]) {
          groups[headerHash] = { header: formattedHeaderList, body: [], fields: headerList };
        }
        groups[headerHash].body.push(headerList.map((h) => job[h]));
      }
      const results = Object.values(groups);
      const body = results.map((result, i) => {
        return (
          <div
            className="ctable ctable-striped mb-4 w-100"
            key={`table${i}`}
          >


            <div className="cthead-dark">
              <div className="ctr">
                {result["header"].map((header, j) => {
                  // Find the corresponding object in sortable_headers
                  const sortableHeader = sortable_headers.find(
                    sortable_header => sortable_header.field === result.fields[j]
                  );

                  // Check if the field is sortable
                  const isSortable = Boolean(sortableHeader);
                  const className = `cthIHME ctFontSize ${isSortable ? "clickable" : ""}`;

                  return (
                    <div
                      className={className}
                      key={`header${j}`}
                      // Add onClick only if the field is sortable
                      {...(isSortable ? { onClick: () => sortByField(sortableHeader.field, sortableHeader.data_type, responseData) } : {})}
                    >
                      {header}
                      {isSortable && <SortArrow field={sortableHeader.field} />}
                    </div>
                  );
                })}
              </div>
            </div>



            <div className="ctbody">
              {result["body"].map((row, j) => (
                <div className="ctr" key={`row${j}`}>
                  {row.map((cell, k) => (
                    <div className="ctdIHME ctFontSize" key={`cell${k}`}>
                      {cell}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      });
      setTaskResponse(body);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, filenameFilter, selectedRequestorEmails]);


  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  };

  const handleSelectedRequestorEmailsChange = (event, option) => {
    const updatedSelectedRequestorEmails = option.selected
      ? [...selectedRequestorEmails, option.key]
      : selectedRequestorEmails.filter((key) => key !== option.key);
    setSelectedRequestorEmails(updatedSelectedRequestorEmails);
  };

  const SortArrow = ({ field }) => {
    const renderArrow = () => {
      if (sortedBy.field === field) {
        if (sortedBy.direction === 'asc') {
          return <FontIcon className="icon-right ps-2 fw-bold" iconName='SortUp' />;
        } else {
          return <FontIcon className="icon-right ps-2 fw-bold" iconName='SortDown' />;
        }
      } else {
        return <FontIcon className="icon-right ps-2 fw-bold" iconName='Sort' />;
      }
    };

    return renderArrow();
  };




  const sortByField = (field, data_type, source) => {

    setSortedBy({ field: field, direction: sortedBy.direction === 'desc' ? 'asc' : 'desc' });

    const sortedJobs = [...source.jobs];
    sortedJobs.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (data_type === 'date') {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (sortedBy.direction === 'desc') {

        if (valueA > valueB) {
          return -1;
        }
        if (valueA < valueB) {
          return 1;
        }
        return 0;
      } else {
        if (valueA > valueB) {
          return 1;
        }
        if (valueA < valueB) {
          return -1;
        }
        return 0;
      }
    });

    setResponseData({ ...source, jobs: sortedJobs });
  };


  function dateFormat(dateToBeFormatted) {
    const tempDate = new Date(dateToBeFormatted);

    const years = tempDate.getFullYear();
    const months = (tempDate.getMonth() + 1).toString().padStart(2, '0');
    const days = tempDate.getDate().toString().padStart(2, '0');
    const hours = tempDate.getHours().toString().padStart(2, '0');
    const minutes = tempDate.getMinutes().toString().padStart(2, '0');
    const seconds = tempDate.getSeconds().toString().padStart(2, '0');

    return `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`;
  }



  useEffect(() => {

    if (initComponent && isBatchProcess && !statusResults) {
      setLoadingResponseState(task, true);
      setStatusResults(true);

      postRunTask(props.application.endpoint, {
        task: "status_checker_processor",
        parameters: {
          job_id: "all",
          process: "ihme"
        },
      })
        .then(async (response) => {
          debugger;
          sortByField("submission_date", "date", response);
        })
        .catch((error) => {
          console.log(error);
          showDialog(
            "There is an issue loading the status of the jobs. Please try again in a few minutes"
          );
        })
        .finally(() => {
          setLoadingResponseState(task, false);
        });
    }
  }, [
    initComponent,
    isBatchProcess,
    props.application.endpoint,
    setLoadingResponseState,
    statusResults,
    task,
  ]);

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);

      clearAll();
      setApplication(props.application);
      setCustomFieldsState(
        props.application.custom_fields
          .map((customField) => getCustomFieldState(customField))
          .reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key];
            return acc;
          }, {})
      );
    }
  }, [clearAll, initComponent, props.application]);

  useEffect(() => {
    if (initComponent) {
      const clearError = (field) => {
        const newFieldData = {
          ...customFieldsState,
          [field]: {
            ...customFieldsState[field],
            data: {
              ...customFieldsState[field].data,
              hasError: false,
            },
          },
        };
        setCustomFieldsState(newFieldData);
      };
      const setValue = ({
        field,
        value,
        key,
        data,
        operation,
        refetch = false,
      }) => {
        if (field) {
          const newFieldData = {
            ...customFieldsState,
            [field]: {
              ...customFieldsState[field],
              data: {
                ...customFieldsState[field].data,
                userSelectedValue: value,
                userSelectedKey: key,
                userSelectedData: data,
                hasError: false,
                changed: !lodash.isEqual(
                  customFieldsState[field].data.userSelectedValue,
                  value
                ),
                dependencyChanged: false,
                operation: operation,
                refetch: refetch,
              },
            },
          };
          setCustomFieldsState(newFieldData);
        }
      };

      const _showLeftPanel = (show) => {
        // Nothing to do
      };

      // Create components for custom fields, based in JSON dictionary
      var generalOptions = `col-12 d-flex mt-2 mb-2 align-items-center ${Object.keys(customFieldsState).length > 1 ? "col-md-6" : ""
        }`;
      var conditionalOptions = "";
      const _customFieldsElements = Object.values(customFieldsState).map(
        (value, index) => {
          const fieldData = value.data;
          const fieldProps = value.props;
          if (index % 2 === 0) {
            conditionalOptions = "ps-0 pe-0 ps-md-0 pe-md-5";
          } else {
            conditionalOptions = "ps-0 pe-0  ps-md-2 pe-md-0";
          }

          const options = generalOptions + " " + conditionalOptions;

          let element = <></>;

          // Create component based on custom field type
          switch (fieldProps.type) {
            case "dropdown":
              element = (
                <div key={index} className={options}>
                  <CustomDropdown
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    endpoint={application.endpoint}
                    setValue={setValue}
                    clearError={clearError}
                  />
                </div>
              );
              break;
            case "slider":
              element = (
                <div key={index} className={options}>
                  <CustomSlider
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "text":
              element = (
                <div key={index} className={options}>
                  <CustomTextField
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "file":
              element = (
                <div key={index} className={options}>
                  <CustomFileChooser
                    showDialog={showDialog}
                    fileMaxSize={props.fileMaxSize}
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                    clearError={clearError}
                    showLeftPanel={_showLeftPanel}
                  />
                </div>
              );
              break;
            default:
              return <></>;
          }

          return element;
        }
      );

      setCustomFieldsElements(_customFieldsElements);
    }
  }, [
    application?.endpoint,
    customFieldsState,
    initComponent,
    props.fileMaxSize,
  ]);

  const renderStatus = (job) => {
    if (job["status"] === "Completed") {
      return (
        <div className="ctStatePill completedState">
          {job["status"]}
        </div>
      );
    } else if (job["status"] === "Processing") {
      return (
        <div className="ctStatePill processingState">
          {job["status"]}
        </div>);
    } else {
      return (
        <div className="ctStatePill failedState">
          {job["status"]}
        </div>);
    }
  }

  useEffect(() => {
    if (resetState) {
      // Reset all states
      setResetState(IHME_DEFAULT_STATE.RESETSTATE);
      setTaskResponse(IHME_DEFAULT_STATE.TASKRESPONSE);
      setDiaglogHidden(IHME_DEFAULT_STATE.DIALOGHIDDEN);
      setDialogText(IHME_DEFAULT_STATE.DIALOGTEXT);
      setApplication(IHME_DEFAULT_STATE.APPLICATION);
      setCustomFieldsState(IHME_DEFAULT_STATE.CUSTOMFIELDSSTATE);
      setCustomFieldsElements(IHME_DEFAULT_STATE.CUSTOMFIELDSELEMENTS);
      setStatusResults(IHME_DEFAULT_STATE.STATUSRESULTS);
      setInitComponent(IHME_DEFAULT_STATE.INITCOMPONENT);
    }
  }, [resetState]);


  // Component Render
  return !initComponent ? null : (
    <>
      {/* App Header */}
      <ApplicationHeader application={application} />

      {/* Custom Controls Area */}
      {/* {Object.keys(customFieldsState ?? {}).length > 0 ? ( */}
      {customFieldsElements.length > 0 ? (
        <>
          <div className="col-12 mt-1 mb-2">
            <div className="taskDivider"> </div>
          </div>
          <div className="row m-0 mt-0 p-0 d-flex ">{customFieldsElements}</div>
        </>
      ) : null}
      <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
        <DefaultButton
          disabled={loading || loadingResponse}
          text="Submit"
          onClick={submitForm}
        ></DefaultButton>
        {loadingResponse && <LoadingSpinner />}
      </div>
      <div className="col-12 mt-1 mb-3">
        <div className="taskDivider"> </div>
      </div>

      {/* Result Area */}
      <div className="row d-flex flex-grow-1 m-0 p-0">
        {/* Right Panel */}
        <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-3 pt-3 pb-3 pe-md-3 mt-3 mt-md-0 w-100">

          {/* Result Filter */}
          {isBatchProcess &&
            <div className="col-12 p-0 m-0 pb-3 d-flex align-items-center justify-content-between">
              <ActionButton
                iconProps={{ iconName: "Refresh" }}
                disabled={loading || loadingResponse}
                onClick={() => setTaskResponse(null) & setStatusResults(false)}
              >Refresh List</ActionButton>


              <div className="d-flex">
                <Label className="ms-2">Filter by: </Label>

                <TextField
                  className="ms-2"
                  disabled={loading || loadingResponse}
                  onChange={(e) => setFilenameFilter(e.target.value)}
                  placeholder=" Job Id or Filename"
                  styles={{ fieldGroup: { width: 250 } }}
                  value={filenameFilter}
                />

                <Label className="ms-2">by </Label>

                <Dropdown
                  selectedKeys={selectedRequestorEmails}
                  onChange={handleSelectedRequestorEmailsChange}
                  multiSelect
                  placeholder="any Requestor"
                  options={existingRequestorEmails ?? []}
                  className="ms-2"
                  styles={{
                    dropdown: { width: 250 },
                    dropdownItemsWrapper: { maxHeight: '300px', overflowY: 'auto' }
                  }}
                  disabled={loading || loadingResponse}
                />

                <DefaultButton disabled={loading || loadingResponse} className="ms-2" onClick={clearFilterForm}>Clear</DefaultButton>
              </div>
            </div>
          }

          <div
            id="rightPanelDiv"
            ref={rightPanelDiv}
            className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight IHMEScrollbar"
            style={{ overflowY: "auto", height: "10px"}}
          >
            {taskResponse}
          </div>
          <div className="col-12 pt-2 mb-2 panelFooterHeight">

            {/* Create a button to navigate to home */}
            <StartOverButton resetFunction={setResetState} />
            <CopyTextButton
              text={rightPanelDiv}
              isShowDialog={true}
              message={"Text copied."}
            />
          </div>
        </div>
      </div>

      {/* Dialog */}
      <CloseButtonDialog
        dialogHidden={dialogHidden}
        dialogText={dialogText}
        hideDialog={hideDialog}
      />
    </>
  );
};

export default IHME;
