// Import Dependencies
import { React, useEffect } from "react";
import { postRunTask } from "../../Api/Api";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
} from '@fluentui/react';

const MediaTransOutputModal = ({ modalInfo, setModalInfo, application }) => {

  const cancelIcon = { iconName: 'Cancel' };

  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  // Component Render
  return (
    <div className="p-5">
      <Modal
        titleAriaId={modalInfo[0]}
        isOpen={true}
        onDismiss={() => setModalInfo([null,''])}
        isBlocking={false}
        containerClassName={contentStyles.container + " col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8"}
      >
        <div className={contentStyles.header}>
          <h5 className={contentStyles.heading} id={modalInfo[0]}>
            Transcription
          </h5>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => setModalInfo([null,''])}
          />
        </div>
        <div className={contentStyles.body}>
          <p>
            {modalInfo[1].replace(/\n/g, ' ')}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default MediaTransOutputModal;


