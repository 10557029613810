// Import Dependencies
import { React, useState } from 'react';
import { IconButton, Toggle, Stack } from '@fluentui/react';


const AdminCenterAppManagerCategory = ({ openRolesModal, filterText, items, categoryKey, onItemsOrderChange, reorderApps, disableApp, loadingState, removeApp, cloneApp, navigateToPage, showTwoButtonDialog }) => {

    const [draggingItem, setDraggingItem] = useState(null);

    const onDragStart = (event, index) => {
        if (loadingState !== '' || filterText !== '') return;
        setDraggingItem(index);
        event.dataTransfer.effectAllowed = "move";
    };

    const onDragOver = index => {
        if (loadingState !== '' || filterText !== '') return;
        if (draggingItem === index) return;
        const itemsCopy = [...items];
        const [draggedItem] = itemsCopy.splice(draggingItem, 1);
        itemsCopy.splice(index, 0, draggedItem);
        onItemsOrderChange(itemsCopy);
        setDraggingItem(index);
    };


    const onDragEnd = () => {
        if (loadingState !== '' || filterText !== '') return;
        reorderApps(items, categoryKey);
    };


    const [itemsIsVisible, setItemsIsVisible] = useState(true);

    // Toggle the visibility of the items
    const toggleItemsVisibility = () => {
        setItemsIsVisible(!itemsIsVisible);
    };


    return (

        <>

            <div className="row detailListTitle pt-2 pb-2 align-items-center d-flex" key={categoryKey}>
                <div className="col-11 detailListItem p-2 m-0">
                    {categoryKey}
                </div>
                <div className="col-1 detailListItem d-flex justify-content-end">
                    <IconButton iconProps={{ iconName: itemsIsVisible ? "ChevronDown" : "ChevronUp" }} style={{ color: '#000000' }} onClick={toggleItemsVisibility} />
                </div>
            </div>

            {itemsIsVisible && items.map((app, index) => (
                <div className="row detailListRow pt-2 pb-2 align-items-center" key={app.task} draggable onDragStart={e => onDragStart(e, index)} onDragOver={() => onDragOver(index)} onDragEnd={onDragEnd}>
                    <div className="col-6 detailListItem p-0 m-0">
                        <img src={"../assets/images/v3/" + app.image} style={{ maxWidth: '40px' }} className="me-3" alt='app icon'></img>
                        {app.taskTitle}
                    </div>
                    <div className="col-3 detailListItem ">
                        {app.task}
                    </div>
                    <div className="col-1 detailListItem d-flex justify-content-start">
                        <Toggle
                            checked={app.enabled === "true" ? true : false}
                            onChange={(event) => disableApp(app.task, app.category, app.enabled === "true" ? "false" : "true")}
                            className="pt-2"
                            disabled={loadingState !== ''}
                        />
                    </div>
                    <div className="col-1 detailListItem d-flex justify-content-center">
                        <IconButton iconProps={{ iconName: 'Edit' }} disabled={app.taskType === "QA" || loadingState !== '' ? true : false} title={app.taskType !== "QA" ? 'Only GPT and Customs tasks can be edited' : ''} onClick={() => navigateToPage(app.task)} style={{ color: '#000000' }} />
                        <IconButton iconProps={{ iconName: 'DependencyAdd' }} disabled={app.taskType === "QA" || app.taskType === "Custom" || loadingState !== '' ? true : false} title={app.taskType !== "GPT" ? 'Only GPT tasks can be cloned' : ''} style={{ color: '#000000' }} onClick={() => showTwoButtonDialog("Do you want to clone the app \"" + app.taskTitle + "\"? This will take you to App Modifier to customize the new app.", () => cloneApp(app), "Yes", "No")} />
                        <Stack tokens={{ childrenGap: 8 }} horizontal>
                            <IconButton
                                menuProps={{
                                    items: [
                                        {
                                            key: 'rolesApp',
                                            text: 'Roles',
                                            iconProps: { iconName: 'PlayerSettings' },
                                            onClick: () => openRolesModal(app)
                                        },
                                        {
                                            key: 'deleteApp',
                                            text: 'Delete',
                                            iconProps: { iconName: 'Delete' },
                                            onClick: () => showTwoButtonDialog("Do you want to delete the app \"" + app.taskTitle + "\"?", () => removeApp(app.task, app.category), "Yes", "No")
                                        }
                                    ],
                                    directionalHintFixed: true,
                                }}
                                iconProps={{ iconName: 'MoreVertical' }}
                                title="MoreVertical"
                                ariaLabel="MoreVertical"
                                disabled={loadingState !== '' ? true : false}
                                onRenderMenuIcon={() => null}
                            />
                        </Stack>
                    </div>
                    <div className="col-1 detailListItem d-flex justify-content-end align-items-center">
                        {filterText === '' ?
                            <IconButton iconProps={{ iconName: "CalculatorEqualTo" }} style={{ color: '#000000' }} />
                            : (<></>)}
                    </div>
                </div>

            ))}


        </>
    )
};

export default AdminCenterAppManagerCategory;