// Import Dependencies
import { useEffect, useState, useContext, useCallback } from "react";
import { DefaultButton } from "@fluentui/react";
import lodash from "lodash";

// Import Components
import CloseButtonDialog from "../Components/CloseButtonDialog";
import ApplicationHeader from "../Components/ApplicationHeader";
import CustomDropdown from "../Components/GptTasks/CustomDropdown";
import CustomSlider from "../Components/GptTasks/CustomSlider";
import CustomTextField from "../Components/GptTasks/CustomTextField";
import CustomFileChooser from "../Components/GptTasks/CustomFileChooser";
import LoadingSpinner from "../Components/LoadingSpinner";

import { LoadingContext } from "../Utils/LoadingManager";
import { postRunTask } from "../Api/Api";

const PROMPT_EDITOR_DEFAULT_STATE = {
  RESETSTATE: false,
  TASKRESPONSE: null,
  DIALOGHIDDEN: true,
  DIALOGTEXT: true,
  APPLICATION: {},
  CUSTOMFIELDSSTATE: null,
  CUSTOMFIELDSELEMENTS: [],
  UPDATEFIELDDATA: true,
  BUTTONTEXT: "Update",
  ONSUBMIT: false,
  INITCOMPONENT: false,
};

const PromptEditor = (props) => {
  const { loading, loadingResponse, clearAll, setLoadingResponseState } = useContext(LoadingContext);

  const [resetState, setResetState] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.RESETSTATE
  );

  // Dialog Handle
  const [dialogHidden, setDiaglogHidden] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.DIALOGHIDDEN
  );
  const [dialogText, setDialogText] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.DIALOGTEXT
  );
  // Props treatment
  const [application, setApplication] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.APPLICATION
  );

  const [customFieldsState, setCustomFieldsState] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.CUSTOMFIELDSSTATE
  );
  const [customFieldsElements, setCustomFieldsElements] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.CUSTOMFIELDSELEMENTS
  );
  const [buttonText, setButtonText] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.BUTTONTEXT
  );
  const [onSubmit, setOnSubmit] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.ONSUBMIT
  );
  const [initComponent, setInitComponent] = useState(
    PROMPT_EDITOR_DEFAULT_STATE.INITCOMPONENT
  );

  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }

  ///////////////////////////////////////////////////////
  //////             TEMPLATE FUNCTIONS            //////
  ///////////////////////////////////////////////////////

  // Copy right panel content to clipboard

  const setErrors = (fieldNames) => {
    if (fieldNames && fieldNames.length > 0) {
      const newFieldData = Object.entries(customFieldsState).reduce(
        (acc, [key, value]) => {
          acc[key] = {
            ...value,
            data: {
              ...value.data,
              hasError: fieldNames.includes(key),
            },
          };
          return acc;
        },
        {}
      );
      setCustomFieldsState(newFieldData);
    }
  };

  function buildCustomFieldState(
    customField,
    hasError = null,
    userSelectedValue = null,
    errorMessage = null,
    changed = null,
    dependencyChanged = null,
    operation = null,
    refetch = null
  ) {
    const data = {
      hasError,
      errorMessage,
      userSelectedValue,
      changed,
      dependencyChanged,
      operation,
      refetch,
    };
    return {
      [customField.name]: {
        props: customField,
        data: data,
      },
    };
  }

  const resetButton = useCallback(() => {
    const update = Object.entries(customFieldsState ?? {}).every(
      ([key, field]) => {
        if (["prompt_type", "prompt_name"].includes(key)) {
          return field.data.operation === "update";
        }
        return true;
      }
    );
    setButtonText(update ? "Update" : "Add");
  }, [customFieldsState]);

  useEffect(() => {
    async function fetchData() {
      const request = {
        ...application.submitRequest,
        parameters: {
          ...application.submitRequest.parameters,
          ...Object.values(customFieldsState).reduce((acc, curr) => {
            const parameterName = curr.props.name;
            const parameterValue = curr.data.userSelectedValue;
            if (parameterName && parameterValue) {
              acc = {
                ...acc,
                [parameterName]: parameterValue,
              };
            }
            return acc;
          }, {})
        },
      };
      try {
        const response = await postRunTask(application.endpoint, request);
        setLoadingResponseState(application.task, false);
        if ("data" in response) {
          setCustomFieldsState(prev => {
            Object.values(prev).filter(field => field.props.copyFrom).forEach(field => {
              prev[field.props.copyFrom.fieldName].data[field.props.copyFrom.sourceKey ?? "userSelectedValue"] = field.data[field.props.copyFrom.targetKey ?? "userSelectedValue"];
            });
            return Object.values(prev).reduce((acc, curr) => {
              acc = {
                ...acc,
                [curr.props.name]: {
                  ...curr,
                  data: {
                    ...curr.data,
                    operation: "update",
                    changed: true,
                    refetch: true,
                  },
                },
              };
              return acc;
            }, {});
          });
        }
      } catch (error) {
        console.log(error);
      }

      resetButton();
    }
    if (onSubmit) {
      setOnSubmit(false);
      fetchData();
    }
  }, [application.endpoint, application.submitRequest, application.task, customFieldsState, onSubmit, resetButton, setLoadingResponseState]);

  // Submit form functions
  const submitForm = () => {
    const hasErrors = Object.values(customFieldsState).some((field) => {
      return field.props.required === "yes" && !field.data.userSelectedValue;
    });
    if (hasErrors) {
      setErrors(Object.keys(customFieldsState));
    } else {
      setLoadingResponseState(application.task, true);
      setOnSubmit(true);
      setButtonText("Updating...");
    }
  };

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);

      clearAll();
      setApplication(props.application);
      setCustomFieldsState(
        (props.application.custom_fields || [])
          .map((customField) => buildCustomFieldState(customField))
          .reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key];
            return acc;
          }, {})
      );
    }
  }, [clearAll, initComponent, props.application]);

  useEffect(() => {
    if (initComponent) {
      const clearError = (field) => {
        const newFieldData = {
          ...customFieldsState,
          [field]: {
            ...customFieldsState[field],
            data: {
              ...customFieldsState[field].data,
              hasError: false,
            },
          },
        };
        setCustomFieldsState(newFieldData);
      };

      const setValue = ({
        field,
        value,
        key,
        data,
        operation,
        refetch = false,
      }) => {
        if (field) {
          const newFieldData = {
            ...customFieldsState,
            [field]: {
              ...customFieldsState[field],
              data: {
                ...customFieldsState[field].data,
                userSelectedValue: value,
                userSelectedKey: key,
                userSelectedData: data,
                hasError: false,
                changed: !lodash.isEqual(
                  customFieldsState[field].data.userSelectedValue,
                  value
                ),
                dependencyChanged: false,
                operation: operation,
                refetch: refetch,
              },
            },
          };
          setCustomFieldsState(newFieldData);
        }
      };

      // Create components for custom fields, based in JSON dictionary
      var generalOptions = "col-12 d-flex mt-2 mb-2 align-items-center";
      var conditionalOptions = "";
      var mod = false;

      const _customFieldsState = JSON.parse(JSON.stringify(customFieldsState));
      let changeList = Object.values(_customFieldsState).filter(
        (field) => field.data.changed !== false
      );
      // Preprocess custom fields
      if (changeList.length > 0) {
        Object.values(_customFieldsState).forEach((field) => {
          const fieldData = field.data;
          const fieldProps = field.props;
          if (fieldProps.copyFrom) {
            fieldData.dependencyChanged = false;
            const copyFieldName = fieldProps.copyFrom.fieldName;
            const dependencyFieldState = _customFieldsState[copyFieldName];
            const copySourceKey =
              fieldProps.copyFrom.sourceKey ?? "userSelectedValue";
            const copyTargetKey =
              fieldProps.copyFrom.targetKey ?? "userSelectedValue";
            fieldData.disabled =
              !(
                typeof dependencyFieldState?.data[copySourceKey] === "string"
              ) || dependencyFieldState?.data?.disabled;
            if (dependencyFieldState?.data?.changed && !fieldData.changed) {
              fieldData[copyTargetKey] = dependencyFieldState?.data?.disabled
                ? ""
                : dependencyFieldState.data[copySourceKey];
              fieldData.dependencyChanged = true;
            }
            if (dependencyFieldState?.data?.operation) {
              fieldData.operation = dependencyFieldState.data.operation;
            }
          }

          const requestOptions = fieldProps.fetch?.requestOptions;
          if (requestOptions) {
            const addFrom = requestOptions.addFrom ?? [];
            fieldData.addProperties = addFrom.reduce((acc, curr) => {
              const fieldName = curr.fieldName;
              const dependencyFieldState = _customFieldsState[fieldName];
              if (dependencyFieldState) {
                fieldData.dependencyChanged = false;
                const fieldKey = curr.sourceKey ?? "userSelectedValue";
                const fieldValue = dependencyFieldState.data[fieldKey];
                if (fieldValue) {
                  const targetProperty = curr.targetProperty ?? "parameters";
                  const targetKey = curr.targetKey ?? fieldName;
                  fieldData.dependencyChanged =
                    fieldData.dependencyChanged ||
                    dependencyFieldState.data.changed ||
                    false;
                  if (dependencyFieldState.data.operation) {
                    acc = {
                      ...acc,
                      [targetProperty]: {
                        ...(acc ?? {})[targetProperty],
                        [targetKey]: fieldValue,
                      },
                    };
                  }
                  fieldData.disabled = false;
                } else {
                  fieldData.disabled = true;
                }
              }
              return acc;
            }, null);
          }
        });
      }
      // TODO: Move to it's own useEffect
      if (lodash.isEqual(_customFieldsState, customFieldsState)) {
        const _customFieldsElements = Object.values(customFieldsState).map(
          (value, index) => {
            const fieldData = value.data;
            const fieldProps = value.props;
            if (
              fieldProps.fullWidth === "true" ||
              Object.keys(customFieldsState).length === 1
            ) {
              conditionalOptions = "ps-0 pe-0 ps-md-0";
              mod = !mod;
            } else if (index % 2 === Number(mod)) {
              conditionalOptions = "col-md-6 ps-0 pe-0 ps-md-0 pe-md-5";
            } else {
              conditionalOptions = "col-md-6 ps-0 pe-0 ps-md-2 pe-md-0";
            }

            const options = `${generalOptions} ${conditionalOptions}`;
            let element = <></>;

            // Create component based on custom field type
            switch (fieldProps.type) {
              case "dropdown":
                element = (
                  <div key={index} className={options}>
                    <CustomDropdown
                      fieldProps={fieldProps}
                      fieldData={fieldData}
                      endpoint={application.endpoint}
                      setValue={setValue}
                      clearError={clearError}
                    />
                  </div>
                );
                break;
              case "slider":
                element = (
                  <div key={index} className={options}>
                    <CustomSlider
                      fieldProps={fieldProps}
                      fieldData={fieldData}
                      setValue={setValue}
                    />
                  </div>
                );
                break;
              case "text":
                element = (
                  <div key={index} className={options}>
                    <CustomTextField
                      fieldProps={fieldProps}
                      fieldData={fieldData}
                      setValue={setValue}
                    />
                  </div>
                );
                break;
              case "file":
                element = (
                  <div key={index} className={options}>
                    <CustomFileChooser
                      showDialog={showDialog}
                      fileMaxSize={props.fileMaxSize}
                      fieldProps={fieldProps}
                      fieldData={fieldData}
                      setValue={setValue}
                      clearError={clearError}
                      showLeftPanel={() => { }}
                    />
                  </div>
                );
                break;
              default:
                return <></>;
            }

            return element;
          }
        );

        setCustomFieldsElements(_customFieldsElements);
      } else {
        changeList.forEach((field) => (field.data.changed = false));
        setCustomFieldsState(_customFieldsState);
      }
    }
  }, [
    application.endpoint,
    customFieldsState,
    initComponent,
    props.fileMaxSize,
  ]);

  useEffect(() => {
    resetButton();
  }, [resetButton]);

  useEffect(() => {
    if (resetState) {
      // Reset all states
      setResetState(PROMPT_EDITOR_DEFAULT_STATE.RESETSTATE);
      setDiaglogHidden(PROMPT_EDITOR_DEFAULT_STATE.DIALOGHIDDEN);
      setDialogText(PROMPT_EDITOR_DEFAULT_STATE.DIALOGTEXT);
      setApplication(PROMPT_EDITOR_DEFAULT_STATE.APPLICATION);
      setCustomFieldsState(PROMPT_EDITOR_DEFAULT_STATE.CUSTOMFIELDSSTATE);
      setCustomFieldsElements(PROMPT_EDITOR_DEFAULT_STATE.CUSTOMFIELDSELEMENTS);
      setInitComponent(PROMPT_EDITOR_DEFAULT_STATE.INITCOMPONENT);
    }
  }, [resetState]);

  // Component Render
  return !initComponent ? null : (
    <>
      {/* App Header */}
      <ApplicationHeader application={application} />

      {/* Custom Controls Area */}
      {/* {Object.keys(customFieldsState ?? {}).length > 0 ? ( */}
      {customFieldsElements.length > 0 ? (
        <>
          <div className="col-12 mt-1 mb-2">
            <div className="taskDivider"> </div>
          </div>
          <div className="row m-0 mt-0 p-0 d-flex ">{customFieldsElements}</div>
        </>
      ) : null}
      <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
        <DefaultButton
          disabled={
            loading ||
            loadingResponse ||
            Object.values(customFieldsState ?? {}).some(
              (field) =>
                field.data.hasError ||
                (field.props.required === "yes" &&
                  !field.data.userSelectedValue)
            )
          }
          text={buttonText}
          onClick={submitForm}
        ></DefaultButton>
        {loadingResponse && <LoadingSpinner />}
      </div>
      <div className="col-12 mt-1 mb-3">
        <div className="taskDivider"> </div>
      </div>

      {/* Dialog */}
      <CloseButtonDialog
        dialogHidden={dialogHidden}
        dialogText={dialogText}
        hideDialog={hideDialog}
      />
    </>
  );
};

export default PromptEditor;
