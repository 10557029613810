// Create a functional component called Dropdown

import { React } from 'react';
import { Slider, IconButton } from '@fluentui/react';

const CustomSliderMockup = (props) => {

    return (
        <div className='d-flex'>
            <IconButton iconProps={{ iconName: 'ChevronLeft' }} title="Move it Left" ariaLabel="Move it Left" onClick={() => props.moveCustomField(props.index, "up")} />
            <IconButton iconProps={{ iconName: 'ChevronRight' }} title="Move it ight" ariaLabel="Move it ight" onClick={() => props.moveCustomField(props.index, "down")} />
            <IconButton className='me-2' iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => props.editCustomField(props.custom_field, props.index)} />
            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
            <div className='pe-3 text-size'>{props.custom_field.label_min}</div>
            <Slider
                name={props.custom_field.name}
                aria-label={props.custom_field.label}
                min={parseInt(props.custom_field.min)}
                max={parseInt(props.custom_field.max)}
                step={parseInt(props.custom_field.step)}
                defaultValue={parseInt(props.custom_field.default_value)}
                showValue={false}
                styles={{
                    root: { width: '100%' }
                }}
                className='mt-1'
                disabled={props.loadingState !== ''}
            />
            <div className='ps-3 text-size'>{props.custom_field.label_max}</div>
        </div>
    )
}

export default CustomSliderMockup;