class ApiUtils {
  constructor(baseUrl = process.env.REACT_APP_BASE_URL ?? "", apiPrefix = "api") {
    this.baseUrl = baseUrl;
    this.apiPrefix = apiPrefix;
  }

  async get(endpoint, queryString = "") {
    const response = await fetch(
      `${this.baseUrl}/${this.apiPrefix}/${endpoint}${queryString}`,
      {
        credentials: "include",
      }
    );
    return response;
  }

  async post(endpoint, body, abortSignal = null) {
    const request = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
    if (abortSignal) {
      request.signal = abortSignal;
    }
    const response = await fetch(
      `${this.baseUrl}/${this.apiPrefix}/${endpoint}`,
      request
    );
    return response;
  }

  async postMultiPartForm(endpoint, body, abortSignal = null, task) {
    let request = {
      task: task,
      method: 'POST',
      body: body,
      credentials: "include",
    };

    const response = await fetch(
      `${this.baseUrl}/${this.apiPrefix}/${endpoint}`,
      request
    );
    return response;
  }

  async put(endpoint, body) {
    const response = await fetch(
      `${this.baseUrl}/${this.apiPrefix}/${endpoint}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return response;
  }

  async delete(endpoint) {
    const response = await fetch(
      `${this.baseUrl}/${this.apiPrefix}/${endpoint}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
    return response;
  }

  redirect(endpoint, queryString = "") {
    window.location.href = `${this.baseUrl}/${this.apiPrefix}/${endpoint}${queryString}`;
  }
}

export default ApiUtils;
