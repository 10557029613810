// Template for Trademark Search task

// Import Dependencies
import { React, useState, useRef } from "react";
import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import CloseButtonDialog from "../Components/CloseButtonDialog";
import LoadingSpinner from "../Components/LoadingSpinner";
import StartOverButton from "../Components/StartOverButton";
import { postRunTask } from '../Api/Api'
import { convertFileToBase64 } from '../Utils/Utils'

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";

const AIGeneratedImagesClassifier = (props) => {

    // Constants to handle the form state
    const [fieldLabelOrFileName, setFieldLabelOrFileName] = useState(props.application.example_input_text);
    const [filename, setFilename] = useState('');
    const [file, setFile] = useState(null);
    const [imageSrc, setImageSrc] = useState('');
    const [fileURL, setFileURL] = useState('');
    const [predictionResult, setPredictionResult] = useState(null);
    const [browseButtonText, setBrowseButtonText] = useState('Browse');

    // Refs for the file input
    const inputFileRef = useRef(null);

    const handeFileSlection = () => {
        if (file) {
            clearFileSelection();
            inputFileRef.current.value = '';
        } else {
            inputFileRef.current.click();
        }
    };

    function clearFileSelection() {
        setFieldLabelOrFileName(props.application.example_input_text);
        setFilename('');
        setFile(null);
        setImageSrc('');
        setPredictionResult(null);
        setBrowseButtonText('Browse');
        inputFileRef.current.value = '';
    }

    // On any form item change, update the state of the corresponding variable
    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case 'fileInputValue':
                const file = event.target.files[0];
                if (file) {
                    if (file.size > props.fileMaxSize) {
                        showDialog('File size is too large. Please upload a file smaller than ' + props.fileMaxSize / 1024 / 1024 + ' MB.');
                        inputFileRef.current.value = '';
                    } else {
                        setFieldLabelOrFileName(file.name);
                        setFilename(file.name);
                        setFile(file);
                        setBrowseButtonText('Clear');
                        setFileURL('');
                        const reader = new FileReader();
                        reader.onload = () => {
                            const imageUrl = reader.result;
                            setImageSrc(imageUrl);

                        };
                        reader.readAsDataURL(file);
                    }

                } else {
                    setFieldLabelOrFileName(props.application.example_input_text);
                    setFilename('');
                    setFile(null);
                }
                break;
            case 'fileURL':
                setFileURL(event.target.value);
                setImageSrc(event.target.value);
                break;
            default:
                break;
        }
    }

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }


    // Props treatment
    const application = props.application;


    // Submit form functions
    const submitForm = async () => {
        if (file || fileURL) {
            props.setLoadingState('loading');
            try {
                var apiCallBody = {};
                if (file) {
                    const base64File = await convertFileToBase64(file);
                    apiCallBody = { "files": [{ filename, base64File }] };
                } else {
                    apiCallBody = { "url_path": fileURL };
                }
                const data = await postRunTask(application.endpoint, { task: application.task, parameters: apiCallBody })
                // Clear variables
                props.setLoadingState('');
                if (data.message && data.category) {
                    showDialog(props.application.api_error_message);
                } else {

                    // Round the values to 1 decimal
                    data.diffuser = Math.round(data.diffuser * 100 * 10) / 10;
                    data.gan = Math.round(data.gan * 100 * 10) / 10;
                    data.real = Math.round(data.real * 100 * 10) / 10;
                    
                    // Set the colors for the squares
                    data.diffuserColor = getColor(data.diffuser, "#999999", '#111111');
                    data.ganColor = getColor(data.gan, "#999999", '#111111');
                    data.realColor = getColor(data.real, "#999999", '#111111');

                    // Set the prediction text and color
                    if (data.diffuser > data.real || data.gan > data.real) {
                        data.predictionText = application.ai_image_text
                        data.predictionColor = "#db0000";
                    } else {
                        data.predictionText = application.real_image_text
                        data.predictionColor = "#20ab03";
                    }

                    // If the prediction is not conclusive, show a different message
                    if(data.diffuser < 30 && data.gan < 30 && data.real < 30){
                        data.predictionText = application.not_conclusive_image_text;
                        data.predictionColor = "#555555";
                    }

                    setPredictionResult(data);
                }
            } catch (error) {
                props.setLoadingState('');
                showDialog(application.api_error_message);
            }
        } else {
            showDialog("Please select an image to upload.");
        }
    }

    // Interpolates a color based in a value between 0 and 100
    function getColor(value, startHex, endHex) {
        function hexToRgb(hex) {
            return [
                parseInt(hex.substring(1, 3), 16),
                parseInt(hex.substring(3, 5), 16),
                parseInt(hex.substring(5, 7), 16)
            ];
        }

        function rgbToHex(r, g, b) {
            return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }

        const startRgb = hexToRgb(startHex);
        const endRgb = hexToRgb(endHex);

        const interpolatedRgb = startRgb.map((start, i) => {
            return Math.round(start + (endRgb[i] - start) * (value / 100));
        });

        return rgbToHex(...interpolatedRgb);
    }

    // Component Render
    return (
        <>
            { /* App Header */}
            <ApplicationHeader application={application} />

            <div className="col-12 mt-1 mb-3">
                <div className="taskDivider"> </div>
            </div>

            { /* Question Area */}
            <div className="row m-0 mt-0 p-0 d-flex ">
                <div className="col-12 d-lg-flex p-0 align-items-center">
                    <div className="w-100-responsive-ai-generated-images p-0 text-size">{fieldLabelOrFileName}</div>
                    <DefaultButton disabled={props.loadingState !== ''} className="w-100-responsive-ai-generated-images ms-0 ms-lg-2 mb-lg-0 lg-2 mb-2" text={browseButtonText} onClick={handeFileSlection} />
                    <input
                        ref={inputFileRef}
                        type="file"
                        onChange={(event) => fieldUpdateHandler(event, "fileInputValue")}
                        accept={application.accepted_file_types}
                        className='d-none'
                        data-testid="gro-file-input"
                    />

                    <div className={`w-100-responsive-ai-generated-images p-0 text-size ps-0 ps-lg-2 pe-2 ${filename !== '' ? 'd-none' : ''}`}>{application.example_paste_url_text}</div>
                    <TextField value={fileURL} className={`flex-grow-1 ${filename !== '' ? 'd-none' : ''}`} onChange={(event) => fieldUpdateHandler(event, "fileURL")} />


                    <div className={`w-100-responsive-ai-generated-images p-0 text-size ps-0 ps-lg-2 mt-2 mt-lg-0  ${filename !== '' ? 'd-none' : ''}`}>and then</div>
                    <PrimaryButton disabled={props.loadingState !== ''} className="w-100-responsive-ai-generated-images ms-0  ms-lg-2 " text="Submit" onClick={submitForm} />
                    {props.loadingState === "loading" && <LoadingSpinner />}
                </div>
            </div>

            <div className="col-12 mt-3 mb-3">
                <div className="taskDivider"> </div>
            </div>

            { /* Result Area */}
            <div className="row d-flex flex-grow-1 m-0 p-0">

                { /* Left Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3 pb-3">
                    <div id="leftPanel" className="col-12 flex-grow-1 bgCustomGray p-0 pb-3 mobileMinHeight" style={{ backgroundImage: `url(${imageSrc})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center' }}>
                    </div>
                </div>

                { /* Right Panel */}
                <div className="col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-3 mt-md-0">
                    <div className="col-12 flex-grow-1 bg-white p-3 pt-0 mobileMinHeight">
                        {
                            predictionResult != null ? (
                                <>
                                    <div className="col-12 p-0 pt-0">
                                        <h4>Prediction:
                                            {(predictionResult !== null ?
                                                <span style={{ color: predictionResult.predictionColor }}> {predictionResult.predictionText}</span>
                                                : '')}
                                        </h4>
                                    </div>

                                    <div className="col-12 mt-0 mb-3 mt-3">
                                        <div className="taskDivider"> </div>
                                    </div>

                                    <div className="col-12 p-0 pt-0">
                                        <h4>Explanation:</h4>
                                    </div>

                                    <div className="col-12 d-flex flex-grow-1 align-items-center text-center justify-content-center" style={{ height: '85%' }}>



                                        <div className="d-flex  flex-column">
                                            <div className="col-12 d-flex mb-2 align-items-center AI-generated-image-result-wrapper">
                                                <div className="d-flex me-3 AI-generated-image-result-square justify-content-center align-items-center text-center" style={{ backgroundColor: predictionResult.diffuserColor }}>{predictionResult.diffuser}%</div>
                                                Diffuser-based model
                                            </div>

                                            <div className="col-12 d-flex mb-2 align-items-center AI-generated-image-result-wrapper">
                                                <div className="d-flex me-3 AI-generated-image-result-square justify-content-center align-items-center text-center" style={{ backgroundColor: predictionResult.ganColor }}>{predictionResult.gan}%</div>
                                                Gan-based model
                                            </div>

                                            <div className="col-12 d-flex mb-2 align-items-center AI-generated-image-result-wrapper">
                                                <div className="d-flex me-3 AI-generated-image-result-square justify-content-center align-items-center text-center" style={{ backgroundColor: predictionResult.realColor }}>{predictionResult.real}%</div>
                                                Real Image
                                            </div>
                                        </div>



                                    </div>
                                </>

                            ) : null
                        }
                    </div>
                    <div className="col-12 pt-2 mb-2 panelFooterHeight">
                        <StartOverButton loadingState={props.loadingState} />
                    </div>
                </div>
            </div>

            { /* Dialog */}
            <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />
        </>
    )
};

export default AIGeneratedImagesClassifier;