/*
@param {File} file - The file to be converted to base64.
@returns {Promise} - A Promise that resolves with the base64 string of the file.
*/
export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const convertFilesToBase64 = (files) => {
  const promises = files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve({ filename: file.name, base64File: base64String });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  });
  return Promise.all(promises);
}

/*
@param {string} text - The text to be written to the clipboard.
*/
export const writeToClipboard = (text) => {
  navigator.clipboard.writeText(text.replace(//g, ""));
};

export const requireContextWrapper = (
  directory,
  useSubdirectories = false,
  regExp = /^\.\//
) => {
  return require.context(directory, useSubdirectories, regExp);
};

export const requestFullScreen = (element = document.documentElement) => {
  if (element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  }
};
