// Create a functional component called Dropdown

import { React } from 'react';
import { DefaultButton, Label, IconButton } from '@fluentui/react';

const CustomFileChooserMockup = (props) => {
    return (
        <div className='d-flex'>
            <IconButton iconProps={{ iconName: 'ChevronLeft' }} title="Move it Left" ariaLabel="Move it Left" onClick={() => props.moveCustomField(props.index, "up")} />
            <IconButton iconProps={{ iconName: 'ChevronRight' }} title="Move it ight" ariaLabel="Move it ight" onClick={() => props.moveCustomField(props.index, "down")} />
            <IconButton className='me-2' iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => props.editCustomField(props.custom_field, props.index)} />
            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
            <Label className='pe-3 text-size'>{props.custom_field.label}</Label>
            <DefaultButton
                name={props.custom_field.name}
                aria-label={props.custom_field.label}
                text="Browse"
            />
        </div>
    )
}

export default CustomFileChooserMockup;