// Import Dependencies
import { React, useState, useEffect } from "react";
import { DefaultButton, ComboBox, IconButton } from '@fluentui/react';
import { fetchAppConfig } from '../../Api/Api'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

// Import Components
import ApplicationHeader from "../../Components/ApplicationHeader";
import LoadingSpinner from "../../Components/LoadingSpinner";
import CloseButtonDialog from "../../Components/CloseButtonDialog";

import AppMaker from "./AppMaker";
import AppModifierCustomApps from "./AppModifierCustomApps";


const AppModifier = (props) => {
    const { paramTask } = useParams();

    // Constant to store the selected app from the combobox
    const [selectedApp, setSelectedApp] = useState(null);

    // Gets application data from dict. Is used to show app header info.
    const application = props.application;




    useEffect(() => {
        const fetchApps = async () => {
            try {
                // Call the to get Categories
                const data = await fetchAppConfig();
                const keys = Object.keys(data);
                keys.map(key => (
                    data[key].map(card => {
                        if (card.task === paramTask) {
                            setSelectedApp(card);
                        }
                    })
                ));
            } catch (error) {
                showDialog("An error occurred while trying to get the app list. Please try again.");
            };
            props.setLoadingState('');
        };

        fetchApps();
        props.setLoadingState('loading');


    }, []);



    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState('');

    const [twoButtonDialogHidden, setTwoButtonDialogHidden] = useState(true);

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

    const navigate = useNavigate();
    const navigateToAppManager = () => {

        navigate("/admin-app-manager/");
    };

    return (
        (selectedApp === null) ?
            <>
                { /* App Header */}
                <ApplicationHeader application={application} />

                <div className="col-12 mt-1 mb-5">
                    <div className="taskDivider"> </div>
                </div>

                <div className="row m-0 mb-5 p-0">
                    <div className="col-12 col-md-6 mb-4 d-flex align-items-end">
                        {props.loadingState === "loading" && <LoadingSpinner />}
                    </div>
                </div>

                { /* Close Button Dialog */}
                <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />

            </>
            :

            <>
                {selectedApp != null && selectedApp.taskType === "GPT" ?
                    <AppMaker key={"AppMaker"} loadingState={props.loadingState} setLoadingState={props.setLoadingState} application={application} App_dict={props.appConfig} PreSelectedApp={selectedApp} api_url={props.api_url} />
                    :   <></>
                }

                {selectedApp != null && selectedApp.taskType !== "GPT" ?
                    <AppModifierCustomApps key={"AppModifierCustomApps"} loadingState={props.loadingState} setLoadingState={props.setLoadingState} application={application} App_dict={props.appConfig} PreSelectedApp={selectedApp} api_url={props.api_url} />
                    :
                    <></>
                }

                <div className="col-12 mt-1 mt-5">
                    <div className="col-12 col-md-6 mb-4 d-flex">
                        <DefaultButton text={"Go back to App Manager"} onClick={navigateToAppManager} />
                    </div>
                </div>
            </>
    )
};

export default AppModifier;