// Import Dependencies
import { React, useContext, useEffect, useState } from "react";
import parser from "html-react-parser";
import { postRunTask } from "../../Api/Api";
import { convertFilesToBase64 } from "../../Utils/Utils";
import { LoadingContext } from "../../Utils/LoadingManager";

const GPT_OUTPUT_DEFAULT_STATE = {
  APIRESULTS: null,
  APIPARAMETERS: null,
  FETCHDATA: false,
  FIELDSTATELIST: null,
  FILESPROCESSING: null,
  INITCOMPONENT: false,
  RESETREQUEST: false,
};

const GptDefaultOutput = (props) => {
  const { setLoadingResponseState } = useContext(LoadingContext);
  // API Call
  const [apiResults, setApiResults] = useState(
    GPT_OUTPUT_DEFAULT_STATE.APIRESULTS
  );
  const [apiParameters, setApiParameters] = useState(
    GPT_OUTPUT_DEFAULT_STATE.APIPARAMETERS
  );
  const [fetchData, setFetchData] = useState(
    GPT_OUTPUT_DEFAULT_STATE.FETCHDATA
  );
  const [fieldStateList, setFieldStateList] = useState(
    GPT_OUTPUT_DEFAULT_STATE.FIELDSTATELIST
  );
  const [initComponent, setInitComponent] = useState(
    GPT_OUTPUT_DEFAULT_STATE.INITCOMPONENT
  );
  const [resetRequest, setResetRequest] = useState(
    GPT_OUTPUT_DEFAULT_STATE.RESETREQUEST
  );

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);
      setApiResults(GPT_OUTPUT_DEFAULT_STATE.APIRESULTS);
      setFieldStateList(props.fieldStateList);
      setResetRequest(GPT_OUTPUT_DEFAULT_STATE.RESETREQUEST);
    }
  }, [initComponent, props, resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (resetRequest) {
      setResetRequest(GPT_OUTPUT_DEFAULT_STATE.RESETREQUEST);
      setFetchData(GPT_OUTPUT_DEFAULT_STATE.FETCHDATA);
      setApiParameters(GPT_OUTPUT_DEFAULT_STATE.APIPARAMETERS);
      setLoadingResponseState("GPTOutput", false);
    }
  }, [resetRequest, setLoadingResponseState]);

  useEffect(() => {
    if (fieldStateList) {
      const _apiParameters = {};
      let fileFields = [];
      let errors = [];
      Object.values(fieldStateList).forEach((fieldState) => {
        const fieldType = fieldState?.props?.type;
        const userSelectedValue = fieldState?.data?.userSelectedValue;
        if (fieldType === "file") {
          if (userSelectedValue) {
            fileFields.push({
              props: fieldState.props,
              files:
                userSelectedValue instanceof FileList
                  ? Array.from(userSelectedValue)
                  : Array.isArray(userSelectedValue)
                    ? userSelectedValue
                    : [userSelectedValue],
            });
          }
          const fileCount =
            fileFields.find((file) => (file.props.name = fieldState.props.name))
              ?.files?.length || 0;
          const multifileDisallowed =
            fieldState.props.enable_multifile !== "true" && fileCount > 1;
          const filesMissing =
            fieldState.props.required === "yes" && fileCount === 0;
          if (multifileDisallowed || filesMissing) {
            errors.push(fieldState.props.name);
          }
        } else if (userSelectedValue) {
          _apiParameters[fieldState.props.name] = userSelectedValue;
        } else {
          _apiParameters[fieldState.props.name] = "";
          if (fieldState.props.required === "yes") {
            errors.push(fieldState.props.name);
          }
        }
      });
      if (props.leftPanelInputValue) {
          _apiParameters.prompt = props.leftPanelInputValue;
      } else if (fileFields.length === 0) {
        errors.push("prompt");
      }

      if (errors.length > 0) {
        props.setErrors(errors);
        props.showDialog("Please fill all the required fields.");
        setInitComponent(false);
      } else {
        if (fileFields.length > 0) {
          Promise.all(
            fileFields.map(async (field) => {
              const files = field.files;
              return await convertFilesToBase64(files);
            })
          ).then((convertedFiles) => {
            fileFields.forEach(async (field, i) => {
              const props = field.props;
              if (props.enable_multifile === "true") {
                _apiParameters.iterator = {
                  [props.name]: convertedFiles[i],
                };
              } else {
                _apiParameters[props.name] = convertedFiles[i];
              }
            });
            setApiParameters(_apiParameters);
            setFetchData(true);
          });
        } else {
          setApiParameters(_apiParameters);
          setFetchData(true);
        }
      }
    }
  }, [fieldStateList, props]);

  useEffect(() => {
    const formatText = (text) => {
      return text.replace(/\n/g, "<br>").replace(/<\|.+\|>/g, "");
    };
    if (fetchData) {
      setFetchData(false);
      // Create the body of the API call
      const apiCallBody = {
        task: props.application.task,
        parameters: apiParameters,
      };
      setLoadingResponseState("GPTOutput", true);

      // Call the API
      try {
        postRunTask(props.application.endpoint, apiCallBody)
          .then((response) => {
            let responses = [];
            response.data = response.data || [response];
            if (response.data && response.data.length > 0) {
              for (let i = 0; i < response.data.length; i++) {
                const data = response.data[i];
                const decision_text = formatText(data.generated_text);
                responses.push(decision_text);
              }
              // } else {
              //   const decision_text = formatText(response.generated_text);
              //   responses.push(decision_text);
            }
            setApiResults(responses);
            props.setHasResult(true);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          })
          .catch((error) => {
            props.showDialog(
              "A problem has come up. We will investigate it. Please try again in a few minutes"
            );
          })
          .finally(() => {
            setResetRequest(true);
          });
      } catch (error) {
        props.showDialog(
          "A problem has come up. We will investigate it. Please try again in a few minutes"
        );
        setInitComponent(false);
      }
    }
  }, [apiParameters, fetchData, props, setLoadingResponseState]);

  // Component Render
  return (apiResults ?? []).length > 0 ? (
    <>
      {/* Output */}
      <div className="row m-0 p-0 d-flex p-2 ps-0 pt-0">
        <div className="d-flex p-0">
          {apiResults.map((element, index) => {
            return <p key={index}>{parser(element)}</p>;
          })}
        </div>
      </div>
    </>
  ) : null;
};

export default GptDefaultOutput;
