// Create a functional component called Dropdown

import { React, useState } from 'react';
import { TextField, IconButton, Toggle, PrimaryButton } from '@fluentui/react';

const CustomFileChooserCreator = (props) => {

    const [name, setName] = useState(props.customField.name);
    const [label, setLabel] = useState(props.customField.label);
    const [file_types, setFileTypes] = useState(props.customField.file_types.join(","));
    const [required, setRequired] = useState(props.customField.required);

    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case "name":
                if (/^[A-Za-z0-9]+$/.test(event.target.value) || event.target.value === "") {
                    setName(event.target.value);
                }                
                break;
            case "label":
                setLabel(event.target.value);
                break;
            case "file_types":
                if (/^[A-Za-z0-9.,]+$/.test(event.target.value) || event.target.value === "") {
                    setFileTypes(event.target.value);
                }                
                break;
            case "required":
                if (required === "no") {
                    setRequired("no");
                } else {
                    setRequired("yes");
                }
                break;
            default:
                break;
        }
    }

    function fieldUpdateOnBlurHandler() {
        if (name !== "" && label !== "" && file_types !== "") {
            const tempItem = {
                name: name,
                type: "file",
                label: label,
                file_types: file_types.split(","),
                required: required
            };

            if (tempItem.file_types[0] === "") {
                tempItem.file_types[0] = [];
            }

            props.updateCustomField(props.index, tempItem);
            props.closeModal();
        } else {
            alert("Please fill all the fields");
        }
    }

    return (
        <div className='row'>
            <div className="col-12 col-md-6 mb-md-3 pe-0">
                <h6 className='tCustomBlue'>Create a File Uploader</h6>
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0 text-md-end mb-">
                {props.mode !== "creation" ?
                    <>
                        <IconButton data-testid="custom-dd-move-up-btn" iconProps={{ iconName: 'Up' }} title="Up" ariaLabel="Up" onClick={() => props.moveCustomField(props.index, "up")} />
                        <IconButton data-testid="custom-dd-move-down-btn" iconProps={{ iconName: 'Down' }} title="Down" ariaLabel="Down" onClick={() => props.moveCustomField(props.index, "down")} />
                        <IconButton data-testid="custom-dd-delete-btn" iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => props.removeCustomField(props.index)} />
                    </> : ""
                }
                <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => props.closeModal()} />
            </div>


            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={20} value={name} onChange={(event) => fieldUpdateHandler(event, "name")} label="Name:" placeholder={"Will be the identifier in API"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={40} value={label} onChange={(event) => fieldUpdateHandler(event, "label")} label="Label:" placeholder={""} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField value={file_types} onChange={(event) => fieldUpdateHandler(event, "file_types")} label="Supported File Types (comma separated)" placeholder={".doc, .docx, .pdf, .ppt"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0 pt-2">
                <Toggle label="Required" defaultChecked={required ? "yes" : undefined} onChange={(event) => fieldUpdateHandler(event, "required")} onText="Is Required" offText="Not Required" />
            </div>

            <div className="col-12 mb-3 pe-0 pt-2 text-end">
                <PrimaryButton text="Save" onClick={fieldUpdateOnBlurHandler} />
            </div>

        </div>
    )
}

export default CustomFileChooserCreator;