import { React, useState } from 'react';
import { Modal, DefaultButton, ContextualMenu } from '@fluentui/react';

import CustomDropdownCreator from './GptTasks/CustomDropdownCreator';
import CustomFileChooserCreator from './GptTasks/CustomFileChooserCreator';
import CustomSliderCreator from './GptTasks/CustomSliderCreator';
import CustomTextFieldCreator from './GptTasks/CustomTextFieldCreator';

import CustomFileChooserMockup from './GptTasks/CustomFileChooserMockup';
import CustomDropdownMockup from './GptTasks/CustomDropdownMockup';
import CustomSliderMockup from './GptTasks/CustomSliderMockup';
import CustomTextFieldMockup from './GptTasks/CustomTextFieldMockup';


const CustomFieldsCreator = ({ task, fieldUpdateHandler, fileMaxSize, loadingState, showDialog }) => {

    const [currentCustomField, setCurrentCustomField] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const customFieldValidityRules = {
        file: {
            name: { required: true },
            label: { required: true },
            file_types: { required: true }
        },
        dropdown: {
            name: { required: true },
            placeholder: { required: true },
            options: { required: true }
        },
        slider: {
            name: { required: true },
            label: { required: true },
            label_max: { required: true },
            min: { required: true },
            max: { required: true },
            label_min: { required: true },
            default_value: { required: true },
            step: { required: true },
        },
        text: {
            name: { required: true },
            placeholder: { required: true },
            label: { required: true }
        }
    };

    const isValidOutput = (value) => {
        switch (typeof value) {
            case 'object':
                return value[0].length;
            case 'number':
                return !isNaN(value);
            default:
                return value;
        }
    }
    const checkValidity = (field) => {
        let error = {};
        Object.entries(customFieldValidityRules[field.type]).forEach((rule) => {
            const prop = rule[0];
            const propRule = rule[1];
            if (prop) {
                const value = field[prop];

                if (propRule.required && !isValidOutput(value)) {
                    if (error.required)
                        error.required.push(`${prop}`)
                    else
                        error = { ...error, required: [`${prop}`] }
                }
            }
        });
        return error;
    }

    function openModal() {
        setIsModalOpen(!isModalOpen);
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    // Contextual menu for the add custom field button
    const menuProps = {
        items: [
            {
                key: 'CustomFileChooser',
                text: 'File Uploader',
                iconProps: { iconName: 'OpenFile' },
                onClick: () => addCustomField("CustomFileChooser")
            },
            {
                key: 'CustomSlider',
                text: 'Slider',
                iconProps: { iconName: 'Slider' },
                onClick: () => addCustomField("CustomSlider")
            },
            {
                key: 'CustomDropdown',
                text: 'Dropdown',
                iconProps: { iconName: 'Dropdown' },
                onClick: () => addCustomField("CustomDropdown")
            },
            {
                key: 'CustomTextField',
                text: 'TextField',
                iconProps: { iconName: 'TextField' },
                onClick: () => addCustomField("CustomTextField")
            }
        ],
        directionalHintFixed: true,
    };

    // Triggered when clicking on any custom field add button.
    function addCustomField(field) {
        var tempItem = {};
        switch (field) {
            case 'CustomFileChooser':
                tempItem = {
                    name: "",
                    type: "file",
                    label: "",
                    file_types: [],
                    required: false,
                    error: {}
                };
                setCurrentCustomField(<CustomFileChooserCreator mode="creation" closeModal={closeModal} updateCustomField={updateCustomField} key={"field" + task.custom_fields.value.length} index={task.custom_fields.value.length} customField={tempItem} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'CustomDropdown':
                tempItem = {
                    name: "",
                    type: "dropdown",
                    placeholder: "",
                    options: [],
                    required: false,
                    error: {}
                };
                setCurrentCustomField(<CustomDropdownCreator mode="creation" closeModal={closeModal} updateCustomField={updateCustomField} key={"field" + task.custom_fields.value.length} index={task.custom_fields.value.length} customField={tempItem} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'CustomSlider':
                tempItem = {
                    name: "",
                    type: "slider",
                    label: "",
                    label_min: "",
                    label_max: "",
                    min: 0,
                    max: 0,
                    default_value: 0,
                    step: 1,
                    required: false,
                    error: {}
                };
                setCurrentCustomField(<CustomSliderCreator mode="creation" closeModal={closeModal} updateCustomField={updateCustomField} key={"field" + task.custom_fields.value.length} index={task.custom_fields.value.length} customField={tempItem} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'CustomTextField':
                tempItem = {
                    name: "",
                    type: "text",
                    label: "",
                    placeholder: "",
                    required: false,
                    error: {}
                };
                setCurrentCustomField(<CustomTextFieldCreator mode="creation" closeModal={closeModal} updateCustomField={updateCustomField} key={"field" + task.custom_fields.value.length} index={task.custom_fields.value.length} customField={tempItem} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />)
                break;
            default:
                break;
        }
        openModal()
        //fieldUpdateHandler([...task.custom_fields.value, tempItem], 'custom_fields');
    }

    // Updates the custom field array with the new field. Called from the custom field components
    function updateCustomField(customFieldPos, field) {
        const arrayCopy = [...task.custom_fields.value];
        field.error = checkValidity(field);
        arrayCopy[customFieldPos] = field;
        fieldUpdateHandler(arrayCopy, 'custom_fields');
    }

    // Removes the custom field from the array. Called from the custom field components
    function removeCustomField(customFieldPos) {
        const arrayCopy = [...task.custom_fields.value];
        arrayCopy.splice(customFieldPos, 1);
        fieldUpdateHandler(arrayCopy, 'custom_fields');
        closeModal();
    }

    // Moves the custom field up or down in the array. Called from the custom field components
    const moveCustomField = (position, direction) => {
        const arrayCopy = [...task.custom_fields.value];

        if (direction === "up" && position > 0) {
            const currentItem = arrayCopy[position];
            arrayCopy.splice(position, 1);
            arrayCopy.splice(position - 1, 0, currentItem);
        } else if (direction === "down" && position < arrayCopy.length - 1) {
            const currentItem = arrayCopy[position];
            arrayCopy.splice(position, 1);
            arrayCopy.splice(position + 1, 0, currentItem);
        }

        fieldUpdateHandler(arrayCopy, 'custom_fields');
        closeModal();
    }

    function _getMenu(props) {
        return <ContextualMenu {...props} />;
    }

    function editCustomField(field, index) {
        
        switch (field.type) {
            case 'file':
                setCurrentCustomField(<CustomFileChooserCreator index={index} closeModal={closeModal} updateCustomField={updateCustomField} key={"field"} customField={field} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'dropdown':
                setCurrentCustomField(<CustomDropdownCreator index={index} closeModal={closeModal} updateCustomField={updateCustomField} key={"field"} customField={field} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'slider':
                setCurrentCustomField(<CustomSliderCreator index={index} closeModal={closeModal} updateCustomField={updateCustomField} key={"field"} customField={field} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            case 'text':
                setCurrentCustomField(<CustomTextFieldCreator index={index} closeModal={closeModal} updateCustomField={updateCustomField} key={"field"} customField={field} removeCustomField={removeCustomField} moveCustomField={moveCustomField} />);
                break;
            default:
                break;
        }
        openModal()
    }


    return (
        <>
            <div className='row'>
                {task.custom_fields.value.map((custom_field, index) => {
                    switch (custom_field.type) {
                        case 'file':
                            return <div className='col-12 col-md-6 mb-3' role='custom-file-upload'><CustomFileChooserMockup index={index} editCustomField={editCustomField} custom_field={custom_field} openModal={openModal} updateCustomField={updateCustomField} key={"field"} removeCustomField={removeCustomField} moveCustomField={moveCustomField}  /></div>
                        case 'dropdown':
                            return <div className='col-12 col-md-6 mb-3' role='custom-dropdown'><CustomDropdownMockup index={index} editCustomField={editCustomField} custom_field={custom_field} openModal={openModal} updateCustomField={updateCustomField} key={"field"} removeCustomField={removeCustomField} moveCustomField={moveCustomField}   /></div>
                        case 'slider':
                            return <div className='col-12 col-md-6 mb-3' role='custom-slider'><CustomSliderMockup index={index} editCustomField={editCustomField} custom_field={custom_field} openModal={openModal} updateCustomField={updateCustomField} key={"field"} removeCustomField={removeCustomField} moveCustomField={moveCustomField}  /></div>
                        case 'text':
                            return <div className='col-12 col-md-6 mb-3' role='custom-textfield'><CustomTextFieldMockup index={index} editCustomField={editCustomField} custom_field={custom_field} openModal={openModal} updateCustomField={updateCustomField} key={"field"} removeCustomField={removeCustomField} moveCustomField={moveCustomField}  /></div>
                        default:
                            return null;
                    }
                })}

                <div className='col-12 col-md-6 mb-3'> <DefaultButton
                    menuProps={menuProps}
                    text={task.custom_fields.value.length === 0 ? "Add optional field" : ""}
                    menuAs={_getMenu}
                    allowDisabledFocus={true}
                />
                </div>

            </div>



            <Modal
                key={"Icons"}
                titleAriaId={"Icons"}
                isOpen={isModalOpen}
                onDismiss={closeModal}
                isBlocking={false}
                className='p-0'
            >
                <div className="row m-0 p-0" style={{ maxWidth: '800px' }}>
                    <div className='col-12 p-3 p-md-5 d-flex '>
                        <div className="col-12 p-0">
                            <div className='col-12 mb-3 text-center text-md-start'>
                                {currentCustomField}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>



        </>
    )

};

export default CustomFieldsCreator;