// Import Dependencies  
import { React, useState, useEffect } from 'react';
import AdminCenterAppManagerCategory from './AdminCenterAppManagerCategory';
import { Modal, Stack, PrimaryButton, DefaultButton } from "@fluentui/react";
import { postRunTask } from '../../Api/Api'
import RoleSelector from './RoleSelector';

const AdminCenterAppManagerList = ({ showDialog, filterText, appOptions, disableApp, cloneApp, loadingState, setLoadingState, reorderApps, removeApp, navigateToPage, showTwoButtonDialog }) => {
    const [selectedRoleChangeApp, setSelectedRoleChangeApp] = useState(null);
    const [openRolesModal, setOpenRolesModal] = useState(null);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if (selectedRoleChangeApp) {
            setOpenRolesModal(true);
        } else if (loadingState === '') {
            setOpenRolesModal(false);
        }
    }, [loadingState, selectedRoleChangeApp]);

    const closeRolesModal = () => {
        setSelectedRoleChangeApp(null);
    }

    const fieldUpdateHandler = (value, _) => {
        setRoles(value);
    }

    async function updateRoles(task) {
        setLoadingState('loading');
        const apiCallBody = {
            "type": "forceupdate"
        };

        task.allowed_roles = roles;
        apiCallBody.parameters = task;

        try {
            const response = await postRunTask("generate_app", apiCallBody);
            if (!response.data)
                console.log("Error updating roles");
            showDialog("Roles updated successfully.");
        } catch (error) {
            showDialog("An error occured. Please try again.");
            console.error('Error:', error);
        } finally {
            setLoadingState('');
            setSelectedRoleChangeApp(null);
        }
    }

    useEffect(() => {
        // Filters the data by the filter text,   
        // If there's not filter text then shows all the data.   
        // If there's no data in a category then hides the category.  
        if (filterText !== '') {
            const filteredData = {};
            Object.entries(appOptions).forEach(([key, value]) => {
                const filteredItems = value.filter(item => item.taskTitle.toLowerCase().includes(filterText.toLowerCase()));
                if (filteredItems.length > 0) {
                    filteredData[key] = filteredItems;
                }
            });
            setData(filteredData);
        } else {
            setData(appOptions);
        }
    }, [appOptions, filterText]);

    const [data, setData] = useState(appOptions);

    const handleItemsOrderChange = (categoryKey, newItems) => {
        // Actualizar el estado con los ítems reordenados  
        const updatedData = {
            ...data,
            [categoryKey]: newItems.map((item, index) => ({
                ...item,
                order: (index + 1)
            }))
        };
        setData(updatedData);
    };

    return (
        <div>
            {Object.entries(data).length === 0 ? (
                <p className='text-center mt-3 mb-0'><em>No applications were found with the entered text.</em></p>
            ) : (
                Object.entries(data).map(([key, value]) => (
                    <AdminCenterAppManagerCategory
                        openRolesModal={setSelectedRoleChangeApp}
                        filterText={filterText}
                        categoryKey={key}
                        items={value}
                        onItemsOrderChange={newItems => handleItemsOrderChange(key, newItems)}
                        disableApp={disableApp}
                        loadingState={loadingState}
                        removeApp={removeApp}
                        reorderApps={reorderApps}
                        cloneApp={cloneApp}
                        navigateToPage={navigateToPage}
                        showTwoButtonDialog={showTwoButtonDialog}
                        key={key}
                    />
                ))
            )}

            {selectedRoleChangeApp && <Modal
                key={"Icons"}
                titleAriaId={"Icons"}
                isOpen={openRolesModal}
                onDismiss={closeRolesModal}
                isBlocking={false}
                className='p-5'
            >
                <div className="row m-0 p-0" style={{ maxWidth: '800px' }}>
                    <div className='col-12 p-3 p-md-5 d-flex'>
                        <div className='col-12 d-flex'>
                            <Stack tokens={{ childrenGap: 14 }}>
                                {selectedRoleChangeApp && <RoleSelector allowed_roles={selectedRoleChangeApp.allowed_roles} taskTitle={selectedRoleChangeApp.taskTitle} fieldUpdateHandler={fieldUpdateHandler} loadingState={loadingState} />}
                                <div className='col-12 d-flex mt-3 justify-content-end'>
                                    {loadingState === 'loading' && <div className='spinner-border text-primary me-2' role='status'/>}
                                    <PrimaryButton disabled={loadingState !== ''} text='Update' className='me-2' onClick={() => { updateRoles(selectedRoleChangeApp) }}></PrimaryButton>
                                    <DefaultButton disabled={loadingState !== ''} text='Cancel' onClick={closeRolesModal}></DefaultButton>
                                </div>
                            </Stack>
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    );
};

export default AdminCenterAppManagerList;  