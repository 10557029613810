import { React, useState } from "react";
import {
  Label,
  Modal,
  IconButton,
  ActionButton,
  TextField,
  DefaultButton,
} from "@fluentui/react";
import Pill from "./Pill";
import { checkDuplicateBeforeAddition } from "../assets/utils/arrayHelpers";

const TypeSelector = ({
  collection,
  collectionName,
  label,
  fieldUpdateHandler,
  field,
  fieldValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
    setErrorMessage("");
    setNewItem("");
  }

  // Find the first taskType that is selected by default

  // When the selectedOption changes, update the taskType.selected value
  const handleChange = (event) => {
    fieldUpdateHandler(event.target.value, field);
  };

  const handleAddNewItem = () => {
    if (newItem === "") {
      setErrorMessage("Please type a " + label + ".");
      return;
    } else {
      setErrorMessage("");

      if (checkDuplicateBeforeAddition(collection, newItem)) {
        setErrorMessage("This " + label + " already exists.");
        return;
      }

      fieldUpdateHandler(newItem, collectionName);
      fieldUpdateHandler(newItem, field);
      toggleModal();
    }
  };

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      handleAddNewItem();
    }
  }

  // On any form item change, update the state of the corresponding variable
  const typeSelectorFieldUpdateHandler = (newValue, field) => {
    switch (field) {
      case "newItem":
        setNewItem(newValue.target.value);
        break;
    }
  };

  return (
    <>
      <div className="align-items-center">
        <Label className="me-3">{label}:</Label>
        {collection.map((taskType, index) => (
          <Pill
            key={index}
            checked={fieldValue === taskType.value}
            onChange={handleChange}
            value={taskType.value}
            field={field}
            fieldValue={fieldValue}
          />
        ))}

        {collectionName && (
          <ActionButton
            key={"OpenModal"}
            text="New"
            ariaLabel="Open popup modal"
            onClick={toggleModal}
          />
        )}

        <Modal
          key={"Icons"}
          titleAriaId={"Icons"}
          isOpen={isModalOpen}
          onDismiss={toggleModal}
          isBlocking={false}
        >
          <div className="row m-0 p-0">
            <div className="col-12 p-3 p-md-5 d-flex ">
              <div className="col-12 p-0 d-block ">
                <div className="col-12 mb-3 text-center text-md-start">
                  <h4>New {label}</h4>
                </div>
                <div className="col-12 mb-3 d-flex text-center text-md-start">
                  <TextField
                    className="me-2"
                    maxLength={50}
                    errorMessage={errorMessage}
                    onKeyDown={handleKeyDown}
                    value={newItem}
                    onChange={(event) =>
                      typeSelectorFieldUpdateHandler(event, "newItem")
                    }
                  />
                  <DefaultButton text="Add" onClick={handleAddNewItem} />
                </div>
              </div>
              <div className="d-none d-md-block">
                <IconButton
                  key={"CloseModal"}
                  iconProps={{ iconName: "Cancel" }}
                  ariaLabel="Close popup modal"
                  onClick={toggleModal}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TypeSelector;
