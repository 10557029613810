// This component is used to display a button to start tasks over.

// Import Dependencies
import { React, useContext } from "react";
import { ActionButton } from "@fluentui/react";
import { LoadingContext } from "../Utils/LoadingManager";

const StartOverButton = ({ resetFunction, label, loadingState }) => {

  if(!label){
    label = "Start over";
  }
  
  const { loading, clearAll, loadingResponse } = useContext(LoadingContext);
  // Start over function, resets all fields
  const startOver = () => {
    // check if reset function is passed and is a function
    if (resetFunction && typeof resetFunction === "function") {
      resetFunction(true);
    } else {
      window.location.reload();
    }
    clearAll();
  };

  return (
    <ActionButton
      data-testid="start-over-button"
      ariaLabel="Starts over the app"
      disabled={loading || loadingResponse || loadingState}
      iconProps={{ iconName: "refresh" }}
      text={label}
      onClick={() => startOver()}
    />
  );
};

export default StartOverButton;
