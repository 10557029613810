// Import Dependencies
import {React, useEffect, useState} from 'react';

const AdminCenterFormLine = ({component, customClass}) => {

    const[defaultClass, setDefaultClass] = useState('col-12 mb-4');

    useEffect(() => {
        if(customClass){
            setDefaultClass(customClass);
        }
    }, [customClass]);

    return (
        <div className={defaultClass}>
            {component}
        </div>
    )
};

export default AdminCenterFormLine;