import { React, useEffect, useState } from "react";
import SingleCard from "../Components/SingleCard";
import HomeCarousel from "../Components/HomeCarousel";
import { Shimmer } from "@fluentui/react";

const Home = ({ data }) => {

  // Get the root URL. Used to pass it to the SingleCard component, to redirect to the correct page.
  let parsedUrl = new URL(window.location.href);
  const rootUrl = `${parsedUrl.protocol}//${parsedUrl.host}/`;
  

  const [appOptions, setAppOptions] = useState(data);

  useEffect(() => {
    if (data && typeof data === "object" && Object.keys(data).length > 0) {

      const new_dict = filterEnabledTasks(data);

      // Order items inside categories by order, if order is not present in the item, send it to the end of the list
      // If the category doesn't have any enabled item, delete the category
      Object.keys(new_dict).forEach(function (key) {
        let categoryEnabled = false;

        new_dict[key].sort(function (a, b) {
          if (a.order === undefined) {
            return 1;
          }
          if (b.order === undefined) {
            return -1;
          }
          return parseInt(a.order) - parseInt(b.order);
        });


        new_dict[key].forEach(function (item) {
          if (item.enabled === "true") {
            categoryEnabled = true;
          }
        });


        if (!categoryEnabled) {
          delete new_dict[key];
        }
      });

      setAppOptions(new_dict);
    }
  }, [data]);

  function filterEnabledTasks(data) {
    const filteredData = {};
    Object.keys(data).forEach(appKey => {
      const tasks = data[appKey].filter(task => task.enabled === "true");
      if (tasks.length > 0) {
        filteredData[appKey] = tasks;
      }
    });

    return filteredData;
  }

  const noData =
    appOptions && typeof appOptions === "object" && Object.keys(appOptions).length === 0;
  const cardData = noData
    ? {
      "": Array.from({ length: 4 }, () => ({
        image: "",
        task: "",
        cardTitle: "",
        cardText: "",
        enabled: "true",
        showInMenu: "true",
      })),
    }
    : appOptions;
  const keys = Object.keys(cardData);

  return (
    <div>
      {!noData ? (
        <div className="row m-0 p-0">
          <HomeCarousel />
        </div>
      ) : (
        <div className="d-none" data-testid="no-home-carousel"></div>
      )}

      {keys.map((key, index) => (
        <div key={index}>
          <div className="row mt-4 p-0 d-flex">
            <div className="col-12">
              {key && key.trim() ? (
                <h3>{key}</h3>
              ) : (
                <Shimmer
                  className="mb-4"
                  width={150}
                  styles={{
                    shimmerGradient: {
                      background:
                        "linear-gradient(to right, rgb(213, 212, 206) 0%, rgb(205, 203, 196) 50%, rgb(223, 222, 216) 100%) 0px 0px / 90% 100% no-repeat rgb(213, 212, 206)",
                    },
                  }}
                ></Shimmer>
              )}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
            {cardData[key].map((card, i) =>
              card.enabled === "true" && card.showInMenu !== "false" ? (
                <SingleCard key={`card_${i}`} card={card} rootUrl={rootUrl}></SingleCard>
              ) : null
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;
