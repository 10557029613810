// Import Dependencies
import { React, useEffect, useState, useRef, useContext } from "react";
import { TextField, DefaultButton } from "@fluentui/react";
import CloseButtonDialog from "../Components/CloseButtonDialog";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";

// Import Components
import ApplicationHeader from "../Components/ApplicationHeader";
import CustomDropdown from "../Components/GptTasks/CustomDropdown";
import CustomSlider from "../Components/GptTasks/CustomSlider";
import CustomTextField from "../Components/GptTasks/CustomTextField";
import CustomFileChooser from "../Components/GptTasks/CustomFileChooser";
import LoadingSpinner from "../Components/LoadingSpinner";
import CopyTextButton from "../Components/CopyTextButton";
import StartOverButton from "../Components/StartOverButton";
import FabricationLogger from "../Components/FabricationLogger";

// Import Output Components
import SocialMediaOutput from "../Components/GptOutput/SocialMediaOutput";
import GptDefaultOutput from "../Components/GptOutput/GptDefaultOutput";
import GptKeywordExtractionOutput from "../Components/GptOutput/GptKeywordExtractionOutput";
import { LoadingContext } from "../Utils/LoadingManager";

const GPT_DEFAULT_STATE = {
  RESETSTATE: false,
  TASKRESPONSE: null,
  DIALOGHIDDEN: true,
  DIALOGTEXT: true,
  APPLICATION: null,
  CUSTOMFIELDSSTATE: null,
  CUSTOMFIELDSELEMENTS: null,
  LEFTPANELINPUTVALUE: null,
  SUBMITFORM: false,
  HASRESULT: false,
  SHOWLEFTPANEL: false,
  INITCOMPONENT: false,
};

const GptTasks = (props) => {
  const { loading, loadingResponse, clearAll } = useContext(LoadingContext);

  const [resetState, setResetState] = useState(GPT_DEFAULT_STATE.RESETSTATE);
  // Constant that will contain dynamic component, based on api response
  const [taskResponse, setTaskResponse] = useState(
    GPT_DEFAULT_STATE.TASKRESPONSE
  );
  // Dialog Handle
  const [dialogHidden, setDiaglogHidden] = useState(
    GPT_DEFAULT_STATE.DIALOGHIDDEN
  );
  const [dialogText, setDialogText] = useState(GPT_DEFAULT_STATE.DIALOGTEXT);
  // Props treatment
  const [application, setApplication] = useState(GPT_DEFAULT_STATE.APPLICATION);

  const [customFieldsState, setCustomFieldsState] = useState(
    GPT_DEFAULT_STATE.CUSTOMFIELDSSTATE
  );
  const [customFieldsElements, setCustomFieldsElements] = useState(
    GPT_DEFAULT_STATE.CUSTOMFIELDSELEMENTS
  );
  const [leftPanelInputValue, setLeftPanelInputValue] = useState(
    GPT_DEFAULT_STATE.LEFTPANELINPUTVALUE
  );
  const [submitForm, setSubmitForm] = useState(GPT_DEFAULT_STATE.SUBMITFORM);
  const [hasResult, setHasResult] = useState(GPT_DEFAULT_STATE.HASRESULT);
  const [showLeftPanel, setShowLeftPanel] = useState(
    GPT_DEFAULT_STATE.SHOWLEFTPANEL
  );
  const [initComponent, setInitComponent] = useState(
    GPT_DEFAULT_STATE.INITCOMPONENT
  );

  function hideDialog() {
    setDiaglogHidden(true);
  }

  function showDialog(text) {
    setDialogText(text);
    setDiaglogHidden(false);
  }

  const handleLeftPanelInputChange = (event) => {
    setLeftPanelInputValue(event.target.value);
    leftPanelHeightCapture();
  };

  ///////////////////////////////////////////////////////
  //////             TEMPLATE FUNCTIONS            //////
  ///////////////////////////////////////////////////////

  // Copy right panel content to clipboard
  const rightPanelDiv = useRef(null);

  // Handles the click on the left panel and focuses white div in left panel TextField, if theres any text selected, then deselect it
  const leftPanelClickHandle = () => {
    document.getElementById("leftPanelInput").focus();
  };

  // Capture leftPanelInput content height and set it to leftPanelInput style height
  const leftPanelHeightCapture = () => {
    const leftPanelInput = document.getElementById("leftPanelInput");
    if (leftPanelInput) {
      const leftPanel = document.getElementById("leftPanel");
      leftPanelInput.style.height = "auto";
      leftPanelInput.style.height = leftPanelInput.scrollHeight + "px";
      leftPanel.style.height = leftPanelInput.height + "px";
    }
  };

  function getCustomFieldState(
    customField,
    hasError = false,
    userSelectedValue = null,
    errorMessage = null
  ) {
    const data = {
      hasError: hasError,
      errorMessage: errorMessage,
      userSelectedValue: userSelectedValue,
    };
    return {
      [customField.name]: {
        props: customField,
        data: data,
      },
    };
  }

  useEffect(() => {
    if (!initComponent) {
      setInitComponent(true);

      clearAll();
      setApplication(props.application);
      setShowLeftPanel(props.application.promptless !== "true");
      setCustomFieldsElements([]);
      setCustomFieldsState(
        (props.application.custom_fields || [])
          .map((customField) => getCustomFieldState(customField))
          .reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key];
            return acc;
          }, {})
      );
    }
  }, [clearAll, initComponent, props.application]);

  useEffect(() => {
    leftPanelHeightCapture();
  }, []);

  useEffect(() => {
    if (initComponent) {
      const clearError = (field) => {
        const fieldState = {
          ...customFieldsState,
          [field]: {
            ...customFieldsState[field],
            data: {
              ...customFieldsState[field].data,
              hasError: false,
            },
          },
        };
        setCustomFieldsState(fieldState);
      };
      const setValue = ({
        field,
        value,
        key,
        data,
        operation,
        refetch = false,
      }) => {
        if (field) {
          const newFieldData = {
            ...customFieldsState,
            [field]: {
              ...customFieldsState[field],
              data: {
                ...customFieldsState[field].data,
                userSelectedValue: value,
                userSelectedKey: key,
                userSelectedData: data,
                hasError: false,
                changed: !lodash.isEqual(
                  customFieldsState[field].data.userSelectedValue,
                  value
                ),
                dependencyChanged: false,
                operation: operation,
                refetch: refetch,
              },
            },
          };
          setCustomFieldsState(newFieldData);
        }
      };
      const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.ctrlKey) {
          setSubmitForm(true);
        }
      };
      const _showLeftPanel = (show) => {
        setShowLeftPanel(show && application.promptless !== "true");
      };

      document.addEventListener("keydown", handleKeyDown);
      var generalOptions = `col-12 d-flex mt-2 mb-2 align-items-center ${Object.keys(customFieldsState).length > 1 ? "col-md-6" : ""
        }`;
      var conditionalOptions = "";

      const _customFieldsElements = Object.values(customFieldsState).map(
        (fieldState, index) => {
          const fieldData = fieldState.data;
          const fieldProps = fieldState.props;
          if (index % 2 === 0) {
            conditionalOptions = "ps-0 pe-0 ps-md-0 pe-md-5";
          } else {
            conditionalOptions = "ps-0 pe-0  ps-md-2 pe-md-0";
          }

          const options = generalOptions + " " + conditionalOptions;

          let element = <></>;

          // Create component based on custom field type
          switch (fieldProps.type) {
            case "dropdown":
              element = (
                <div key={index} className={options}>
                  <CustomDropdown
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    endpoint={application.endpoint}
                    setValue={setValue}
                    clearError={clearError}
                  />
                </div>
              );
              break;
            case "slider":
              element = (
                <div key={index} className={options}>
                  <CustomSlider
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "text":
              element = (
                <div key={index} className={options}>
                  <CustomTextField
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                  />
                </div>
              );
              break;
            case "file":
              element = (
                <div key={index} className={options}>
                  <CustomFileChooser
                    showDialog={showDialog}
                    fileMaxSize={props.fileMaxSize}
                    fieldProps={fieldProps}
                    fieldData={fieldData}
                    setValue={setValue}
                    clearError={clearError}
                    showLeftPanel={_showLeftPanel}
                  />
                </div>
              );
              break;
            default:
              return <></>;
          }

          return element;
        }
      );

      setCustomFieldsElements(_customFieldsElements);
    }
  }, [
    application,
    customFieldsState,
    initComponent,
    submitForm,
    props.fileMaxSize,
  ]);

  useEffect(() => {
    if (resetState) {
      // Reset all states
      setResetState(GPT_DEFAULT_STATE.RESETSTATE);
      setTaskResponse(GPT_DEFAULT_STATE.TASKRESPONSE);
      setDiaglogHidden(GPT_DEFAULT_STATE.DIALOGHIDDEN);
      setDialogText(GPT_DEFAULT_STATE.DIALOGTEXT);
      setApplication(GPT_DEFAULT_STATE.APPLICATION);
      setCustomFieldsState(GPT_DEFAULT_STATE.CUSTOMFIELDSSTATE);
      setCustomFieldsElements(GPT_DEFAULT_STATE.CUSTOMFIELDSELEMENTS);
      setLeftPanelInputValue(GPT_DEFAULT_STATE.LEFTPANELINPUTVALUE);
      setHasResult(GPT_DEFAULT_STATE.HASRESULT);
      setSubmitForm(GPT_DEFAULT_STATE.SUBMITFORM);
      setInitComponent(GPT_DEFAULT_STATE.INITCOMPONENT);
    }
  }, [resetState]);

  useEffect(() => {
    // Submit form functions
    if (submitForm) {
      setSubmitForm(false);
      const setErrors = (fieldNames) => {
        const fieldState = {};
        Object.entries(customFieldsState).forEach(([key, value]) => {
          fieldState[key] = {
            ...value,
            data: {
              ...value.data,
              hasError: fieldNames.includes(key),
            },
          };
        });
        setCustomFieldsState(fieldState);
      };

      const componentId = uuidv4();
      setHasResult(false);

      if (`${application.task}`.startsWith("social_media")) {
        setTaskResponse(
          <SocialMediaOutput
            showDialog={showDialog}
            key={componentId}
            application={application}
            leftPanelInputValue={leftPanelInputValue}
            setHasResult={setHasResult}
          />
        );
      }
      else if (`${application.task}`.startsWith("entity_extraction")) {
        setTaskResponse(
          <GptKeywordExtractionOutput
            showDialog={showDialog}
            key={componentId}
            application={application}
            leftPanelInputValue={leftPanelInputValue}
            setErrors={setErrors}
            fieldStateList={customFieldsState}
            setHasResult={setHasResult}
          />
        );
      }
      else {
        setTaskResponse(
          <GptDefaultOutput
            showDialog={showDialog}
            key={componentId}
            application={application}
            leftPanelInputValue={leftPanelInputValue}
            setErrors={setErrors}
            fieldStateList={customFieldsState}
            setHasResult={setHasResult}
          />
        );
      }
    }
  }, [
    application,
    customFieldsState,
    initComponent,
    leftPanelInputValue,
    submitForm,
  ]);

  // Component Render
  return !initComponent ? null : (
    <>
      {/* App Header */}
      <ApplicationHeader application={application} />

      {/* Custom Controls Area */}
      {/* {custom_fields &&\'custom_fields.length > 0 ? ( */}
      {customFieldsElements.length > 0 ? (
        <>
          <div className="col-12 mt-1 mb-2">
            <div className="taskDivider"> </div>
          </div>
          <div className="row m-0 mt-0 p-0 d-flex ">{customFieldsElements}</div>
        </>
      ) : null}

      <div className="col-12 mt-1 mb-3">
        <div className="taskDivider"> </div>
      </div>
      <div
        data-testid="submit-container-top"
        className={`position-relative ${showLeftPanel ? "submit-button-hide" : "submit-button"}`}
      >
        <div className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center">
          <DefaultButton
            aria-hidden={showLeftPanel}
            disabled={loading || loadingResponse}
            text="Submit"
            onClick={() => setSubmitForm(true)}
          ></DefaultButton>
          {loadingResponse && <LoadingSpinner />}
        </div>
      </div>

      {/* Result Area */}
      <div className="row d-flex flex-grow-1 m-0 p-0 justify-content-end">
        <>
          {/* Left Panel */}
          <div
            className={`col-12 col-md-6 d-flex flex-column bg-white ps-3 pe-3 pe-md-2 pt-3 ${showLeftPanel ? "left-panel" : "left-panel-hide"
              }`}
            onClick={leftPanelClickHandle}
          >
            <div
              id="leftPanel"
              className="col-12 flex-grow-1 bg-white p-0 pb-3 mobileMinHeight"
            >
              <TextField
                ariaLabel="Left Panel"
                readOnly={loading || loadingResponse}
                className="p-0 transparent"
                value={leftPanelInputValue}
                name="leftPanelInput"
                id="leftPanelInput"
                multiline
                borderless={true}
                placeholder={application.example_input_text}
                onChange={handleLeftPanelInputChange}
              />
            </div>
            <div
              data-testid="submit-container-bottom"
              className="col-12 pt-2 mb-2 panelFooterHeight d-flex align-items-center"
            >
              <DefaultButton
                aria-hidden={!showLeftPanel}
                disabled={loading || loadingResponse}
                text="Submit"
                onClick={() => setSubmitForm(true)}
              ></DefaultButton>
              {loadingResponse && <LoadingSpinner />}
            </div>
          </div>
        </>

        {/* Right Panel */}
        <div
          className={`col-12 col-md-6 d-flex flex-column bg-white pe-3 ps-3 ps-md-2 pt-3 pe-md-3 mt-md-0 ${!showLeftPanel ? "right-panel-full" : "right-panel-half"
            }`}
        >
          <div
            id="rightPanelDiv"
            ref={rightPanelDiv}
            className="col-12 flex-grow-1 bgCustomGray p-3 mobileMinHeight"
          >
            {taskResponse}
          </div>
          {hasResult === true && (
            <FabricationLogger
              application={application}
              loadingState={props.loadingState}
              setLoadingState={props.setLoadingState}
              showDialog={showDialog}
            />
          )}
          <div className="col-12 pt-2 mb-2 panelFooterHeight">
            <StartOverButton resetFunction={setResetState} />
            <CopyTextButton
              text={rightPanelDiv}
              isShowDialog={true}
              message={"Text copied."}
              loadingState={props.loadingState}
            />
          </div>
        </div>
      </div>
      {/* Dialog */}
      <CloseButtonDialog
        dialogHidden={dialogHidden}
        dialogText={dialogText}
        hideDialog={hideDialog}
      />
    </>
  );
};

export default GptTasks;
