import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LoadingManager } from "./Utils/LoadingManager";
import { initializeIcons } from "@fluentui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 12,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});
initializeIcons();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LoadingManager>
        <App />
      </LoadingManager>
    </QueryClientProvider>
  </React.StrictMode>
);
