// Create a functional component called Dropdown

import { TextField, IconButton, Toggle, PrimaryButton } from '@fluentui/react';
import {React, useState} from 'react';

const CustomTextFieldCreator = (props) => {

    const [name, setName] = useState(props.customField.name);
    const [label, setLabel] = useState(props.customField.label);
    const [placeholder, setPlaceholder] = useState(props.customField.placeholder);
    const [required, setRequired] = useState(props.customField.required);

    const fieldUpdateHandler = (event, field) => {
        switch (field) {
            case "name":
                if (/^[A-Za-z0-9]+$/.test(event.target.value) || event.target.value === "") {
                    setName(event.target.value);
                }  
                break;
            case "label":
                setLabel(event.target.value);
                break;
            case "placeholder":
                setPlaceholder(event.target.value);
                break;
            case "required":
                if(required === "no"){
                    setRequired("no");
                }else{
                    setRequired("yes");
                }
                break;
            default:
                break;
        } 
    }

    function fieldUpdateOnBlurHandler(){
        if(name !== "" && placeholder !== "" && label !== ""){
            const tempItem = {
                name: name,
                type: "text",
                label: label,
                placeholder: placeholder,
                required: required
            };

            props.updateCustomField(props.index, tempItem);
            props.closeModal();
        }else{
            alert("Please fill all the fields");
        }
    }

    return (
        <div className='row'>
            <div className="col-6 mb-md-3 pe-0">
                <h6 className='tCustomBlue'>Create a TextField</h6>
            </div>
            <div className="col-12 col-md-6 mb-3 pe-0 text-md-end">
                <IconButton iconProps={{ iconName: 'Up' }} title="Emoji" ariaLabel="Emoji" onClick={() => props.moveCustomField(props.index, "up")} />
                <IconButton iconProps={{ iconName: 'Down' }} title="Emoji" ariaLabel="Emoji" onClick={() => props.moveCustomField(props.index, "down")} />
                <IconButton iconProps={{ iconName: 'Delete' }} title="Emoji" ariaLabel="Emoji" onClick={() => props.removeCustomField(props.index)} />
                <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => props.closeModal()} />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0">
                <TextField maxLength={20} value={name} onChange={(event) => fieldUpdateHandler(event, "name")} label="Name:" placeholder={"Will be the identifier in API"} className="" />
            </div>
            <div className="col-12 col-md-6 col-6 mb-3 pe-0">
                <TextField maxLength={40} value={label} onChange={(event) => fieldUpdateHandler(event, "label")} label="Label:" placeholder={""} className="" />
            </div>

            <div className="col-12 col-md-6 col-6 mb-3 pe-0">
                <TextField maxLength={40} value={placeholder} onChange={(event) => fieldUpdateHandler(event, "placeholder")} label="Placeholder" placeholder={"E.g.: Write something down here"} className="" />
            </div>

            <div className="col-12 col-md-6 mb-3 pe-0 pt-2">
                <Toggle label="Required" defaultChecked={required ? "yes" : undefined}  onChange={(event) => fieldUpdateHandler(event, "required")}  onText="Is Required" offText="Not Required" />
            </div>

            <div className="col-12 mb-3 pe-0 pt-2 text-end">
                <PrimaryButton text="Save" onClick={fieldUpdateOnBlurHandler} />
            </div>
        </div>
    )
}

export default CustomTextFieldCreator;