import { Link } from "react-router-dom";
import {React, useEffect, useState} from "react";
import { Shimmer } from "@fluentui/react";

const SingleCard = ({ card, rootUrl }) => {

  const image = "assets/images/v3/" + card.image;
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
     if(card.previewText !== '' && card.previewText !== null && card.previewText !== undefined) {
         setIsPreview(true);
     }
  }
  , [card]);

  return (
    <div className="col-12">
      <Link
        to={ rootUrl + card.task.replace(/_/g, "-")}
        className="card d-flex flex-row m-0 p-0 text-decoration-none"
      >
        {card.image ? (
          <img
            src={image}
            className="align-self-center cardImage"
            alt={card.taskTitle}
          />
        ) : (
          <Shimmer
            className="cardImage w-100"
            styles={{
              shimmerWrapper: {
                height: "100%",
              },
            }}
          />
        )}
        <div className="m-0 p-3 pb-3 ps-0">
          {card.taskTitle ? (
            <h5 className="cardTitle">{card.taskTitle}{isPreview && <sup style={{ marginLeft: '8px'}}>({card.previewText})</sup>} </h5>
          ) : (
            <Shimmer className="mb-3" width={150} />
          )}
          {card.cardText ? (
            <p className="cardText">{card.cardText}</p>
          ) : (
            <>
              <Shimmer className="mb-1" width={300} />
              <Shimmer width={300} />
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

export default SingleCard;
