import ApiUtils from "../Utils/ApiUtils";

const api = new ApiUtils();

// fetching identity url and push to auth location
export const fetchIdentity = async (queryString = "", queryClient = null) => {
  try {
    const response = await api.get("user", queryString);

    if (response.status !== 200) {
      throw new Error("Failed to fetch identity");
    }
    const data = await response.json();

    if (data.auth_url) {
      window.location.href = data.auth_url;
      return null;
    } else if (queryClient) {
      await queryClient.invalidateQueries({ queryKey: ["identity"] });
    } else if (data.logout && data.logout === "completed") {
      window.location.reload();
    }
    return data;
  } catch (error) {
    // if queryString doesn't contain logout=true, then logout
    if (
      !queryString.includes("logout=true") &&
      !window.location.pathname.startsWith("/api/logout")
    ) {
      await logout();
    }
  }
};

export const fetchAppConfig = async () => {
  const response = await api.get("get_app_config");
  if (response.status !== 200) {
    return {};
  }
  return await response.json();
};

export const fetchAdminCenterConfig = async () => {
  const response = await api.get("get_admin_center_config");
  if (response.status !== 200) {
    return {};
  }
  return await response.json();
};

export const postRunTask = async (endpoint, body, responseOnly=false, abortSignal=null) => {
  try {
    const response = await api.post(endpoint, body, abortSignal);

    return responseOnly ? response : await response.json();
  } catch (error) {
    throw error;
  }
};

export const postRunTaskMultiPartForm = async (endpoint, body, responseOnly=false, abortSignal=null, task) => {
  try {
    const response = await api.postMultiPartForm(endpoint, body, abortSignal, task);

    return responseOnly ? response : await response.json();
  } catch (error) {
    throw error;
  }
}
// logout to user if not authenticated
export async function logout() {
  api.redirect("logout");
}

export async function refreshToken(queryClient) {
  await fetchIdentity("?refresh=true", queryClient);
}
