// Create a functional component called Dropdown

import { React, useRef, useState, useContext } from "react";
import { LoadingContext } from "../../Utils/LoadingManager";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Label, DialogType, TooltipHost } from '@fluentui/react';

const CustomFileChooser = (props) => {

  const [dialogHidden, setDialogHidden] = useState(true);

  var dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Important',
    closeButtonAriaLabel: 'Close',
    subText: "Do you want to select a folder or files?",
  };

  const defaultErrorMessage =
    props.fieldProps.enable_multifile === "true"
      ? "Select at least one file"
      : "Please select a file";
  const defaultBrowseButtonText = "Browse";
  const clearBrowseButtonText = "Clear";
  const defaultFieldLabelOrFileName = props.fieldProps.label;

  const { loading, loadingResponse, setLoadingState } =
    useContext(LoadingContext);

  // Refs for the file input
  const inputFileRef = useRef(null);

  const [fieldLabelOrFileName, setFieldLabelOrFileName] = useState(
    defaultFieldLabelOrFileName
  );
  const [browseButtonText, setBrowseButtonText] = useState(
    defaultBrowseButtonText
  );



  const handleDialogTrigger = () => {
    if (browseButtonText === clearBrowseButtonText) {
      clearInputField();
      return;
    }

    if (props.fieldProps.enable_multifile === "true" && props.fieldProps.enable_folder_selection === "true") {
      setDialogHidden(false);
    } else {
      inputFileRef.current.removeAttribute("webkitdirectory");
      inputFileRef.current.click();
    }
  }

  const handleFileSelection = (selectionType) => {
    if (selectionType === 'folder') {
      inputFileRef.current.setAttribute("webkitdirectory", "true");
    } else {
      inputFileRef.current.removeAttribute("webkitdirectory");
    }

    setDialogHidden(true);
    clearInputField();
    if (props.showLeftPanel) props.showLeftPanel(true);
    inputFileRef.current.click();
  };

  const clearInputField = () => {
    setBrowseButtonText(defaultBrowseButtonText);
    setFieldLabelOrFileName(defaultFieldLabelOrFileName);
    inputFileRef.current.value = "";
    inputFileRef.current.files = null;
    props.setValue({
      field: props.fieldProps.name,
      data: null,
      key: null,
      value: null,
      operation: null,
      refetch: null,
    });
  };

  // On any form item change, update the state of the corresponding variable
  const fieldUpdateHandler = (event) => {

    const files = event.target.files;
    // iterate files and check that file type is correct for every file, if not show error message and clear input
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      if (!props.fieldProps.file_types.includes(fileType)) {
        props.showDialog("At least one of the selected files has an unsupported filetype. Please upload files with the following extensions: " + props.fieldProps.file_types.join(", "));
        clearInputField();
        return;
      }
    }

    if (files.length > 0) {
      const totalSize = Array.from(files).reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSize > props.fileMaxSize) {

        var fileSize = props.fileMaxSize / 1024 / 1024

        if (fileSize >= 1024) {
          fileSize = (fileSize / 1024).toFixed(2) + "GB."
        } else {
          fileSize = fileSize + "MB."
        }

        const message = `Total file size is too large. Please upload files with a total size smaller than ${fileSize}`;
        inputFileRef.current.value = "";
        inputFileRef.current.files = null;
        props.showDialog(message);
      } else {
        const fileNames = Array.from(files)
          .map((file) => file.name)
          .join(", ");
        setFieldLabelOrFileName(fileNames);
        props.setValue({
          field: props.fieldProps.name,
          value: files,
        });
        setBrowseButtonText(clearBrowseButtonText);
      }
    } else {
      setFieldLabelOrFileName(defaultFieldLabelOrFileName);
    }
    if (props.showLeftPanel) props.showLeftPanel(false);
    setLoadingState(props.fieldProps.name, false);
  };

  document.oncancel = (event) => {
    if (event.target.id === props.fieldProps.name) {
      setLoadingState(props.fieldProps.name, false);
    }
  };

  return (
    <>
      <div className="file-upload-container">
          <Label
            className={
              "file-upload-label " +
              (props.fieldProps.required === "yes" ? "required-field-marker" : "")
            }
          >
            {fieldLabelOrFileName}

          </Label>
        <DefaultButton
          className={`${props.fieldData.hasError ? "required-field-button" : ""
            }`}
          onClick={handleDialogTrigger}
          name={props.fieldProps.name}
          aria-label={defaultFieldLabelOrFileName}
          text={browseButtonText}
          disabled={loading || loadingResponse}
          style={{ flex: "1 1 auto", minWidth: "142px", maxWidth: "142px" }}
        />
        <div
          role="alert"
          id={props.fieldProps.name}
          className={
            props.fieldProps.required === "yes"
              ? "required-field-message" +
              (props.fieldData.hasError ? "" : " d-none")
              : "d-none"
          }
        >
          {props.fieldData.errorMessage ?? defaultErrorMessage}
        </div>
      </div>
      <input
        id={props.fieldProps.name}
        ref={inputFileRef}
        type="file"
        onChange={(event) => fieldUpdateHandler(event)}
        accept={props.fieldProps.file_types}
        className="d-none"
        data-testid="custom-file-chooser"
        multiple={props.fieldProps.enable_multifile === "true"}
      />

      { /* Two Button Dialog */}
      <Dialog
        hidden={dialogHidden}
        onDismiss={() => setDialogHidden(true)}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton
            disabled={false}
            onClick={() => handleFileSelection('folder')}
            text={'Folder'}
          />
          <PrimaryButton
            disabled={false}
            onClick={() => handleFileSelection('files')}
            text={'Files'}
          />
          <DefaultButton
            disabled={false}
            onClick={() => setDialogHidden(true)}
            text={'Cancel'}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CustomFileChooser;
