// Import Dependencies
import { React, useState, useEffect } from "react";
import { postRunTask, fetchAppConfig } from '../../Api/Api'
import { useNavigate } from "react-router-dom";
import { SearchBox } from "@fluentui/react";

// Import Components
import ApplicationHeader from "../../Components/ApplicationHeader";
import LoadingSpinner from "../../Components/LoadingSpinner";
import CloseButtonDialog from "../../Components/CloseButtonDialog";
import TwoButtonDialog from "../../Components/TwoButtonDialog";
import AdminCenterAppManagerList from "../../Components/AdminCenter/AdminCenterAppManagerList";

const AppManager = (props) => {

    // Constant to store the selected app from the combobox
    const [filterText, setFilterText] = useState('');

    // State variables for the form. They will contain the values to populate list
    const [appOptions, setAppOptions] = useState({});



    // Gets application data from dict. Is used to show app header info.
    const application = props.application;



    useEffect(() => {
        fetchApps();
    }, []);


    const fetchApps = async () => {
        try {
            props.setLoadingState('loading');
            // Call the to get Categories
            const data = await fetchAppConfig();

            //Order items inside categories by order, if order is not present in the item, send it to the end of the list
            Object.keys(data).forEach(function (key) {
                data[key].sort(function (a, b) {
                    if (a.order === undefined) {
                        return 1;
                    }
                    if (b.order === undefined) {
                        return -1;
                    }
                    return parseInt(a.order) - parseInt(b.order);
                });
            });


            setAppOptions(data);
            //setTotalPages(Math.ceil(data.length / itemsPerPage));
        } catch (error) {
            showDialog("An error occurred while trying to get the app list. Please try again.");
        };
        props.setLoadingState('');
    };

    const disableApp = async (task, category, enabled) => {

        props.setLoadingState('loading');
        const apiCallBody = {
            "type": "enabledisable"
        };

        var parameters = {
            "task": task,
            "category": category,
            "enabled": enabled
        }
        apiCallBody.parameters = parameters;
        try {
            const data = await postRunTask("generate_app", apiCallBody);
            props.setLoadingState('');
            showDialog("Your changes were saved.");
            setAppOptions(data.frontend);
        } catch (error) {
            props.setLoadingState('');
            showDialog("An error occured. Please try again.");
            console.error('Error:', error);
        };

    }

    const removeApp = async (task, category) => {
        const callBody = { "task": task, "category": category };
        props.setLoadingState('loading');
        try {
            const data = await postRunTask("remove_app", callBody);
            showDialog("The app was successfully removed.");
            setAppOptions(data.frontend);
        } catch (error) {
            showDialog("There was an error removing the app");
        };
        props.setLoadingState('');
    }

    const cloneApp = async (app) => {
        var tempTask = { ...app };
        tempTask.task = tempTask.task + "_cloned_app";
        tempTask.enabled = "false";

        const apiCallBody = {
            "type": "gpt_completion",
            "parameters": tempTask
        };

        props.setLoadingState('loading');

        try {
            console.log(apiCallBody);
            const data = await postRunTask("generate_app", apiCallBody);
            showDialog("App cloned successfully, you will be redirected to the app modifier page.");
            navigateToPage(tempTask.task);
        } catch (error) {
            showDialog("An error occured. Please try again.");
            console.error('Error:', error);
        };
        props.setLoadingState('');
    }

    const reorderApps = async (items, category) => {

        // Make a copy of the appOptions object
        var tempAppOptions = { ...appOptions };

        // Update the order of the items in the tempAppOptions object
        tempAppOptions[category].forEach(function (item) {
            var itemIndex = items.findIndex(x => x.task === item.task);
            item.order = itemIndex + 1;
        });


        try {
            // Call the API to reorder the apps
            props.setLoadingState('loading');
            const apiCallBody = {
                "type": "reorder"
            };
            apiCallBody.parameters = tempAppOptions;
            await postRunTask("generate_app", apiCallBody);
            fetchApps();
            showDialog("Apps reordered successfully.");

        } catch (error) {
            showDialog("An error occured. Please try again.");
            console.error('Error:', error);
            props.setLoadingState('');
        };
    }

    // Dialog Handle
    const [dialogHidden, setDiaglogHidden] = useState(true);
    const [dialogText, setDialogText] = useState('');

    const [twoButtonDialogHidden, setTwoButtonDialogHidden] = useState(true);
    const [twoButtonDialogText, setTwoButtonDialogText] = useState('');
    const [twoButtonDialogActionFunction, setTwoButtonDialogActionFunction] = useState(null);
    const [twoButtonDialogActionButtonText, setTwoButtonDialogActionButtonText] = useState('');
    const [twoButtonDialogCloseButtonText, setTwoButtonDialogCloseButtonText] = useState('');

    function hideDialog() {
        setDiaglogHidden(true);
    }

    function showDialog(text) {
        setDialogText(text);
        setDiaglogHidden(false);
    }

    function showTwoButtonDialog(text, actionFunction, actionButtonText, closeButtonText) {
        setTwoButtonDialogText(text);
        setTwoButtonDialogHidden(false);
        setTwoButtonDialogActionButtonText(actionButtonText);
        setTwoButtonDialogCloseButtonText(closeButtonText);
        setTwoButtonDialogActionFunction(() => actionFunction);
    }

    function hideTwoButtonDialog() {
        setTwoButtonDialogHidden(true);
    }

    const navigate = useNavigate();
    const navigateToPage = (id) => {

        navigate("/admin-app-modifier/" + id);
    };

    function updateFields(event) {
        if (event === undefined) {
            setFilterText('');
        } else {
            setFilterText(event.target.value);
        }
    }

    return (

        <>
            { /* App Header */}
            <ApplicationHeader application={application} />


            { /* Text filter and Loading Spinner */}
            <div className="row pt-2 pb-2 mt-5 pb-5">
                <div className="col-6 p-0 d-flex justify-content-start">
                    {props.loadingState === "loading" && <LoadingSpinner />}
                </div>
                <div className="col-6 p-0 d-flex justify-content-end">
                    <SearchBox
                        type="text"
                        placeholder="Filter by app title"
                        className="col-6"
                        disabled={props.loadingState !== ''}
                        value={filterText}
                        onChange={(e) => updateFields(e)}
                        onClear={(e) => updateFields(e)}
                    />
                </div>
            </div>

            {(Object.keys(appOptions).length > 0 ? (
                <div className='row'>
                    <div className="col-12 p-4 pt-2" style={{ backgroundColor: '#FFFFFF' }}>

                        <div className="row  pt-2 pb-2 align-items-center d-flex" key={"Apps"}>
                            <div className="col-6 detailListItem p-2 m-0">
                                Apps
                            </div>
                            <div className="col-3 detailListItem p-2 m-0">
                                Identifier
                            </div>

                            <div className="col-1 detailListItem p-2 m-0">
                                Enabled
                            </div>
                            <div className="col-1 detailListItem p-2 m-0">
                                Actions
                            </div>
                            <div className="col-1 detailListItem p-2 m-0 d-flex justify-content-end">
                                Reorder
                            </div>
                        </div>


                        <AdminCenterAppManagerList showDialog={showDialog} filterText={filterText} appOptions={appOptions} cloneApp={cloneApp} disableApp={disableApp} removeApp={removeApp} reorderApps={reorderApps} loadingState={props.loadingState} setLoadingState={props.setLoadingState} navigateToPage={navigateToPage} showTwoButtonDialog={showTwoButtonDialog} />

                    </div>
                </div>
            ) : (<></>))}

            { /* Two Button Dialog */}
            <TwoButtonDialog dialogHidden={twoButtonDialogHidden} dialogText={twoButtonDialogText} actionFunction={twoButtonDialogActionFunction} hideDialog={hideTwoButtonDialog} closeButtonText={twoButtonDialogCloseButtonText} actionButtonText={twoButtonDialogActionButtonText} />

            { /* Close Button Dialog */}
            <CloseButtonDialog dialogHidden={dialogHidden} dialogText={dialogText} hideDialog={hideDialog} />

        </>
    )
};

export default AppManager;